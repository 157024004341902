export const GETBLOGPOST ="GETBLOGPOST"
export const GETEVENTREVIEW ="GETEVENTREVIEW"
export const GETTOPCATEGORY="GETTOPCATEGORY"
export const GETTOPOPERATOR ="GETTOPOPERATOR"
export const GETTOPDESTINATION ="GETTOPDESTINATION"
export const GETFEATURED ="GETFEATURED"
export const GETTOPEVENT ="GETTOPEVENT"
export const GETSLIDER ="GETSLIDER"
export const Profile ="Profile"
export const GETALLOPERATORS ="GETALLOPERATORS"
export const GETCOVIDSTATUS ="GETCOVIDSTATUS"