import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class  ListYourTour extends Component{
    render(){
        return(
            <div>
                <NavBar />
                <div className='container footer-con'>
           <h2>Work With BookMyTrek</h2>
           <h2>About BookMyTrek</h2>
           <p>BookMyTrek is the world’s largest and most trusted online platform for customers to search, compare and book multi-day tours. BookMyTrek provides unrivalled support to our operators — we drive you real bookings, handle the payments and provide unique marketing solutions to help your brand and tours reach more customers.</p>
           
           <p>How does it work:</p>
           <p>BookMyTrek works with professional Tour Operators who operate multi-day group tours with ready-to-book itineraries. 
              BookMyTrek   provides you with real bookings. All bookings are handled within the BookMyTrek Booking Conversation Page (BCP), which connects you directly with the customers while you are supported 24/7 by the BookMyTrek  Travel Experts Team.
              There are no fees to list onBookMyTrek , however, there is a contract each Tour Operator has to agree to and a commission payable to BookMyTrek for all confirmed bookings.
 
              Listing your tours onBookMyTrek  can be done by either using an XML/API feed, one of our existing reservation system partners or manually using the BookMyTrek  Operator Dashboard. The BookMyTrek  Business Development, Tech and Content teams will be available to assist with ensuring your tours are displayed in the best possible way to maximise exposure and sales opportunities.</p>

           <p>Benefits of partnering with BookMyTrek :</p>
           <li>Exposure to 3 million website visits a month & 2 million email subscribers</li>
           <li>Unrivalled Technology Solutions </li>
           <li>Experienced Global Teams </li>
           <li>Entry to Tour and Guide of the Year Contests along with Travellers' Choice Awards</li>
           <li>Unbiased Whitelabel reviews platform, along with free review and award widgets</li>
           <li>Ability to participate in quarterly Online Travel Expos & BookMyTrek ’s very own World Touring Day marketing campaign</li>
           <li>Opportunity to work with our dedicated Marketing Solutions Team</li>
           <li>Free to use. Only pay commission on successful bookings</li>
           
            </div>
            <Footer />
            </div>

        )
    }
}
    export default ListYourTour;