
import React, { Component } from 'react';
import { Button,Row,Col,Card,Image,Form } from 'react-bootstrap';
import {
     Label
} from 'reactstrap';
import Api from '../Service/Api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import moment from "moment";
import BeautyStars from "beauty-stars";
import AdventureImage from '../../static/Images/adventure.jpg';
import AdventureImage1 from '../../static/Images/bg2.png';
import { FaAdn } from "react-icons/fa";
import NavBar from './NavBar';




class WriteReview extends Component {
  state={
    list:[],
    banner:'',
    endDate:'',
    startDate:'',
     limit: 10,
    skip: 0,
    id:'',
    value:0,
            review:'',
            name:'',
            vrev:false,
  
  }
 
  render() {
    const { image, place } = this.props;
    let {banner,endDate,startDate,review,name, vrev} =this.state
    let {list}=this.props;
  


    return (
      <div>

         <section className=''
        style={{
            // backgroundImage: `url(${AdventureImage1})`,
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // objectFit:'contain',
            // height:'80vh'
        
          }}
        
        >


      <div className='container'>
      <Row>
                <Col lg={8} style={{margin:'auto'}}>
               
                    <Card style={{height:'400px'}}>
                                    <Card.Header as="h5">Write review</Card.Header>
                                    <Card.Body>
                                       <Form>
                                        <Card.Text>
                                        <BeautyStars
                                            value={this.state.value}
                                            onChange={(value) => {
                                            this.setState({ value });
                                            this.setState({vrev: true})
                                              }
                                        }
                                            inactiveColor="grey"
                                        />
                                        </Card.Text>  

                                        {vrev && 
                                        <div>
                                         <Form.Group controlId='formGroup'>
                                            <Label for="exampleEmail">Full Name</Label>
                                            <Form.Control
                                              
                                                className='formcontrolE texta'
                                                type='text'
                                                placeholder='Full Name'
                                                value={name}
                                              
                                                // onChange={(event) =>
                                                // setDescription(event.target.value)
                                                // }
                                            />
                                            </Form.Group>
                                            <Form.Group controlId='formGroup'>
                                            <Label for="exampleEmail">Write Review</Label>
                                            <Form.Control row={3}
                                                as='textarea'
                                                className='formcontrolE texta'
                                                type='text'
                                                placeholder='comment'
                                                value={review}
                                              
                                                // onChange={(event) =>
                                                // setDescription(event.target.value)
                                                // }
                                            />

                                            </Form.Group>

                                            <Button className=" style-btn">Submit Review</Button>
                                            </div>
                                        }
                                        </Form>
                                    </Card.Body>
                      </Card>
            </Col>
            </Row>
      </div>
      </section>
      </div>
     
    );
  }
}
export default WriteReview;