import React, { useState, useEffect, Component } from "react";

import {

  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  Form,
  Row,
  Col,
  Badge,
  Container,
  Button,
  Label,
  Image,
} from "react-bootstrap";
import Api from "../Service/Api";
import ButtonCommon from '../Components/ButtonCommon';
import {useHistory} from 'react-router-dom';
import Loaders from "../Components/FullScreenLoader";
import NavBar from "../Components/NavBar";


const OpRegister = (props) => {
  const [email, setEmail] = useState("");
  const [name, setFirstname] = useState("");
  const [mobile, setMobile] = useState("");
  const [lastname, setLastname] = useState("");
   const [Cpassword, setCpassword] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [emailmessage, setEmailMessage] = useState("")
  const [error, seterror] = useState("");
  const [disable, setdisable] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  let history= useHistory()
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [active, setactive] = useState(false);
  const [edit, setEdit] = useState(false);
  const [err, seterr] = useState("");
  const [loaders, setLoader] = useState(false);
  const onSendmsg = (event) => {
    //   console.log(description, location, email);
  
    seterr(" ");
    seterror("");
    
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true  ) {
      if(password===Cpassword){
         if(mobile.length>=10 && mobile.length<=10){
      setLoader(true);
      setactive(true);
      setEdit(false)
      let data = {
        name: name,
        mobile: mobile,
        email: email,
        password: password,

      };
      event.preventDefault();
      Api.post("/auth/register-as-operator", data, {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: token,
        //   },
      })
        .then((res) => {
           if (res.status == 201) {
            setShow(true);
            setLoader(false)
            history.push("/operator-register")
           // alert("Thank you for showing interest in BookMyTrek! Your request to become an operator has been summited for review. Our team will update status by mail.");
            setMessage("Thank you for showing interest in BookMyTrek! Your request to become an operator has been summited for review. Our team will update status by mail.")
            seterror(" ");
            setFirstname(" ");
            setEmail(" ");
            setMobile(" ");
            setPassword(" ");
            setCpassword(" ");
            
            setValidated(false);

          }
          //else{
           // setEmailMessage("Email Already Used")
           // console.log("failed", res.data.message);

          //}
        })
        .catch((err) => {
         // console.log(err.response.data.message);
         //console.log(err.response)
        
          setactive(false);
          setLoader(false)
                   seterror(err.response.data.message)
          // seterror(err.response.data.message);
        });
    }

 else{
            event.preventDefault();
              setLoader(false)
            let m="Please enter valid mobile number"
            setMobile(m);
            setactive(false);
        }
}
    
     else{
      //seterror("Invalid email id ")
    event.preventDefault();
      let b="New and confirm  passwords do not match!"
      seterr(b);
    }
    }
  };
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    setLoader(false)
}
  useEffect(() => {
    // Api.get(`/auth/register-as-operator`, {
    //   // headers: {
    //   //   "Content-Type": "application/json",
    //   //   Authorization: token,
    //   // },
    // }).then((res) => {
    //   if (res.status === 200) {
    //     setEmail(res.data.email);
    //     setFirstname(res.data.name);

    //     setPassword(res.data.password);


    //   }
    // });
  }, []);

  return (
    <>
     <NavBar/>
    <section className="register-section">
      {loaders&&<Loaders/>}
    <div className="container register">
      <div>
      <Row >
      <Col
        lg="12"
       
      >
        <Card className="Card-style"  style={{ alignSelf: "center", margin: "auto", maxWidth:'500px' }}>
          <h1 style={{textAlign:'center'}}>Sign Up As Operator</h1>
          <CardBody>
          <Form noValidate 
          validated={validated} 
          onSubmit={onSendmsg}
          >
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Organization Name/Individual Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Organization Name/Individual Name"
            defaultValue="Mark"
            required
            value={name}
            onChange={(event) => {
              setFirstname(event.target.value)
              seterror("")
            }
          }
          />
          <Form.Control.Feedback type="invalid">Enter Organization Name/Individual Name</Form.Control.Feedback>
        </Form.Group>
       
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email"
           required
           value={email}
           onChange={(event) => {
            setEmail(event.target.value)
            seterror("")
          }
           }
          />
          <Form.Control.Feedback type="invalid">
            Enter Valid Email ID
          </Form.Control.Feedback>
        </Form.Group>
       
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Mobile</Form.Label>
          <Form.Control
            required
            type="number"
            placeholder="Mobile"
            defaultValue="Mark"
            required
            value={mobile}
            onChange={(event) => {
              setMobile(event.target.value)
              seterror("")

            }
          }
          />
          <Form.Control.Feedback type="invalid">Enter Valid Mobile Number </Form.Control.Feedback>
        </Form.Group>
        
       
      </Form.Row>
        <Form.Row>
        <Form.Group controlId="formGroupPassword"  as={Col} md="12" >
        <Form.Label>Password</Form.Label>
            <Form.Control type="password" 
            placeholder="Password"
            required
            isInvalid={validated&& password.length<8}
            value={password}
            onChange={(event) => {setPassword(event.target.value)
                seterror("")
            }}
            />
            <Form.Control.Feedback type="invalid" >
            Password must be longer than or equal to 8 characters!
          </Form.Control.Feedback>
        </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group controlId="formGroup" as={Col} lg={12}>
        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          className="formcontrolE"
                          placeholder="Confirm Password*"
                          required
                          type="password"
                          value={Cpassword}
                          onChange={event => {
                            setCpassword(event.target.value)
                            seterr("")
                            seterror("")
                          }
                        }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Re-Enter Valid password
                        </Form.Control.Feedback>
                      </Form.Group>
        </Form.Row>

    

                    <Form.Group>
                      <Form.Check
                        required
                        label="Agree to terms and conditions"
                        feedback="You must agree before submitting."
                      />
                    </Form.Group>

                    {/* <Button type="submit" className="btncommon btnrounded" style={{width:'100%'}}>Sign Up</Button> */}
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                      <ButtonCommon className="all-events-btn" label='Sign Up'
                         type="submit"
                      />
                    </div>
                    {error&&(
                    <Row style={{ textAlign: 'center', marginTop: '20px' }}>
                      <Col>
                    <label style={{color:"red",textAlign:'center', margin:'auto'}}>{error}</label>
                    </Col>
                    </Row>)}

                    {message&&(
                    <Row style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Col>
                      <label style={{ color: 'green', textAlign: 'center', margin: 'auto' }}>{message}</label>
                      </Col>
                    </Row>
                    )}
                    {emailmessage&& (
                    <Row>
                      <Col>
                      <label style={{ color: 'red', textAlign: 'center', margin: 'auto' }}>{emailmessage}</label>
                      </Col>
                    </Row>
                    )}    {err && (
                      <Row style={{  textAlign:'center', }}>
                      <Col>
                               <lable style={{color:'red'}}>
                              {err}
                               </lable>
                        </Col>
                       </Row>
                               )}

                  </Form>


                  <span className="d-inline-block mb-2 mr-2">
                    <Modal
                      open={modalOpen}
                      style={{ marginTop: "5%" }}
                    // onClose={this.handleClose}
                    >
                      <ModalBody></ModalBody>
                      <ModalFooter>
                        <Button
                          color="green"
                          onClick={() => {
                            window.location.reload();
                            setmodalOpen(false);
                          }}
                        //inverted
                        >
                          OK
                  </Button>
                      </ModalFooter>
                    </Modal>
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </section>
    </>
  );
};

export default OpRegister;
