import React, { Component } from 'react';


class CovidPolicy extends Component{
    render(){
        return(
            <div className="covidpolicy" >
               
             <div className='container' >
             <div className="text-center">
              
                <a style={{marginBottom:'0px'}} href="/covid-policy">
                  Book with no deposit, total flexibility and our Covid Refund Guarantee.
                </a>
              </div>
               
           </div>
           </div>
           
           

        )
    }
    }
    export default CovidPolicy;
