import React, { Component } from 'react';
import { Button , Spinner
} from 'react-bootstrap';

import {
  DropdownToggle, DropdownMenu,
  Nav,  NavItem, NavLink,
  UncontrolledTooltip, UncontrolledButtonDropdown

} from 'reactstrap';
import logo from '../../static/Images/logo.png';
import { FaUserAlt} from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



class Loader extends Component {
  render() {
    return (
    <div>
         <center>
                <Spinner
                  className=""
                  animation="border"
                  variant="dark"
                  role="status"
                  aria-hidden="true"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </center>

  </div>
    
    );
  }
}

export default Loader;
