import React, { Component, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { connect } from "react-redux";
import {
  getsliderimages,
  getFeatured,
  getDestination,
  getevents,
  getOperators,
  getReviews,
  getCategory,
  getallOperators,
  getCovidStatus,
} from "../Redux/actions/common";

class ComingSoon extends Component {
  render() {
    const {} = this.props;
    return (
      <div className="home_background">
        <NavBar />
        <div id="notfound" className="maintence">
          <div class="notfound">
            <div class="notfound-404">
              {/* <h1></h1> */}
              <h2>Coming Soon</h2>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  images: state.homeReducer.images,
  featured: state.homeReducer.featured,
  eventlist: state.homeReducer.eventlist,
  destination: state.homeReducer.destination,
  topoperator: state.homeReducer.topoperator,
  eventreviews: state.homeReducer.eventreviews,
  categorylist: state.homeReducer.categorylist,
  covidstatus: state.homeReducer.covidstatus,
});

const mapDispatchToProps = (dispatch) => ({
  getsliderimages: () => dispatch(getsliderimages()),
  getevents: () => dispatch(getevents()),
  getFeatured: () => dispatch(getFeatured()),
  getDestination: () => dispatch(getDestination()),
  getOperators: () => dispatch(getOperators()),
  getReviews: () => dispatch(getReviews()),
  getCategory: () => dispatch(getCategory()),
  getCovidStatus: () => dispatch(getCovidStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);
