import React, { Component } from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import banner4 from "../../static/Images/banner4.jpg"
import banner5 from "../../static/Images/banner5.jpg"
import banner6 from "../../static/Images/banner6.jpg"
import banner7 from "../../static/Images/banner7.jpg"
import banner8 from "../../static/Images/banner8.jpg"
import banner9 from "../../static/Images/banner9.jpg"
import banner10 from "../../static/Images/banner10.jpg"
import banner11 from "../../static/Images/banner11.jpg"

import rajasthan2 from "../../static/Images/rajasthan2.jpg"
import rafting from "../../static/Images/rafting.jpg"
import Row from "reactstrap/lib/Row";
import { Col } from "react-bootstrap";

class BannerCarousel extends Component {
  // constructor(props) {
  //   super(props);
  //   const { image } = this.props;
  // }
  render() {
    const { image , list} = this.props;

    return (
     <div className="bannerc">
  <Carousel showArrows={true} infiniteLoop={true} dynamicHeight={false} stopOnHover={false} showStatus={false} showThumbs={false} autoPlay>
  {list && list.map(item=> <div>
      <img alt="" src={item.original} />
    </div>
    
    )}
  </Carousel>
  </div>

    );
  }
}
export default BannerCarousel;
