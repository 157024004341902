import React, { useState, useEffect, Component } from "react";

import {

  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
    Form,
    Row,
    Col,
    Badge,
    Container,
    Button,
    Label,
    Image,
  } from "react-bootstrap";
import Api from "../Service/Api";
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
 import OperatorDetails from '../Components/OperatorDetails';

// 

const OperatorDetail = (props) => {
 
 

  return (
    <div>
      {/* <NavBar/> */}
      <section>
        {/* {console.log('props detail',props)} */}
          <OperatorDetails  history={props.history} props={props}/>
        </section>
     
      <Footer/>
    </div>
  );
};

export default OperatorDetail;
