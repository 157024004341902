import React, { Component } from "react";
import {Row, Col, Card } from "react-bootstrap";
import BeautyStars from "beauty-stars";
import moment from "moment";

class FeaturedCard extends Component {

  daydiff = (list) => {
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
   // return a.diff(b, "days");
   var c= a.diff(b, "days")+1;
    return c;
  };

  percen =(list ) => {
    var number = list && list.amount;
    var percentToGet = list && list.discount;
 //Calculate the percent.
var percent = (percentToGet / 100) * number;
 return percent;

  }

  render() {
    let { featuredlist } = this.props;
   
    return (
      <div className="" style={{ marginTop: "25px" }}>
        <Row>
          {featuredlist &&
            featuredlist.map((item, index) => (
              <Col lg={3} md={6} key={item.eventId}>
                <div
                    onClick={() => this.props.history.push(`/events/${item.eventId}`)}
                  >
                  
                <Card
                  className="featured-card"
                  style={{
                    height: "380px",
                    borderRadius: "10px",
                    backgroundImage: `url(${item && item.banner})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    border: "none",
                    cursor:"pointer",
                  }}
                >
                  {/* <div
                    onClick={() => this.props.history.push(`events/${item.eventId}`)}
                  ></div> */}
                  <Row className="discount_row">
                    <Col className="discount">
                    <h5 
                    className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}
                    ><div className="price"> 
                    {item.discount}% {' '}
                    {/* {this.percen(item)}%  */}
                     OFF
                    {/* 10% OFF */}
                    </div></h5>
                    </Col>
                  </Row>
                    <Card.Body className="card_body">
                   
                      <Card.Title
                        className="Title-all font-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        {item.title}
                      </Card.Title>
                      {/* <Card.Text> */}
                        <h5 className="Title-all"><div className="price" > 
                        ₹ {item.amount- this.percen(item)}
                        {/* ₹ {item.amount- item.discount} */}
                        
                        {/* {item.amount} */}
                        </div></h5>
                         <h5   className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}>
                         <div className="price-dis" style={{ textDecorationLine: 'line-through' }}> ₹ {item.amount}
                         </div>
                         </h5>
                        <Row className="beauty-stars_featured" >
                          <span className={item.rating=="null" ? "inactiveColor": "activeColor"}
                            style={{ height:"18px"}}
                          
                          >
                            <BeautyStars
                              //value={item.rating}
                              //maxStars="1"
                              size="12px"
                              inactiveColor="#c2c0bba1"
                              activeColor="#f4cc18"
                              value={item && item.rating}
                            />
                          </span>

                          <span
                            style={{ fontSize: "12px" }}
                            className="sub-title-all"
                          >
                            {item.totalRatings} REVIEWS
                          </span>
                        </Row>
                        <p className="sub-title-all">
                          {this.daydiff(item)} days
                        </p>
                      {/* </Card.Text> */}
                    </Card.Body>
                  
                  <div className="eventopacity"></div>
                </Card>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}
export default FeaturedCard;
