import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Destinations extends Component {
  render() {
    let { destinationslist } = this.props;
    return (
      <div className=" row-margin-home" style={{ position: "relative" }}>
        <Row >
          {destinationslist &&
            destinationslist.map((item, index) => (
              <Col lg={2} md={3} xs={6} style={{ padding: "10px" }} key={index}>
                <Link
                  to={{ pathname: `/destination-details/${item&& item.destination}` }}
                  style={{ textDecoration: "none" }}
                >
                  <div className="destdiv">
                    <h2 style={{textTransform:'capitalize'}}>{item && item.destination}</h2>
                    <div
                      className="Destinations"
                      style={{
                        backgroundImage: `url(${item && item.banner})`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "180px",
                        position: "relative",
                      }}
                    ></div>
                    <div className="eventopacity" style={{backgroundImage:"none",backgroundColor:"black",height:"100%",opacity:"0.12"}}></div>
                  </div>{" "}
                </Link>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}
export default Destinations;
