import React, { useState, Component } from "react";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import NavBar from "../Components/NavBar";
import Loaders from "../Components/FullScreenLoader";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Api from "../Service/Api";
import ButtonCommon from '../Components/ButtonCommon';
import {connect} from 'react-redux'
 
import  {getProfile} from '../Redux/actions/common'


const Login = (props) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState("");
  const [disable, setdisable] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let history = useHistory();
  const [loaders, setLoader] = useState(false);

  const OnSubmit = async () => {
    //  await this.setState({ active: true });
    // console.log("email", email);
    // console.log("password", password);
    setLoader(true);
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      if (password.length !== 0) {
        let data = {
          email:email.trim(),
          password,
        };

        Api.post("/auth/signin", data)
          .then((res) => {
             if (res.status === 201) {

              seterror("");
              setmodalOpen(true);
              setLoader(false);
              Api.get(`/users/my-profile`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${res.data.accessToken}`,
                },
              }).then((resp) => {
               
                if(resp.data.emailVerified!= true )
                  history.push("/user-verification")
                else

                if(history.length>4 && resp.data.emailVerified==true)

             history.goBack();
             else
              history.push("/");
            
                props.getProfile(resp.data);
                localStorage.setItem("Auth", "true");
                localStorage.setItem("accesstoken", res.data.accessToken);
              })
             
            
            
            } else {
              seterror("Try after Sometime");
            }
          })
          .catch((err) => {
             //  this.setState({
            //    active: false,
            //    err: err.response.data.error.message,
            //  });
            setErrorMessage("Invalid Credentials");
          });
      } else {
        seterror("Please Enter Password");
      }
    } else {
      seterror("Please Enter Valid Email");
    }
  
  };

  return (
    <>
    <NavBar/>
    <section className="register-section-login">
    <div className="container register">
      <div>
      <Row >
      <Col
        lg="12"
       
      >
        <Card className="Card-style"  style={{ alignSelf: "center", margin: "auto", maxWidth:'400px' }}>
        <h1 style={{ textAlign:'center'}}>Login</h1>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  value={email}
                  onChange={(event) =>{
                    setemail(event.target.value)
                    seterror("")
                  }
                }
                  name="email"
                  placeholder="Enter Email"
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type={"password"}
                  value={password}
                  onChange={(event) => {
                    setpassword(event.target.value)
                     setErrorMessage("")
                     seterror("")

                  }
                }
                  name="password"
                  placeholder="Enter the password"
                />
              </FormGroup>

              <Row className="text-center btnmargin" style={{display:'block'}}>
                <ButtonCommon
                  //  disabled={disable}
                  label="Login"
                  className="all-events-btn text-center"
                  handleClick={OnSubmit}
                >
                  {/* Login */}
                </ButtonCommon>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <label
                  style={{ color: "red", textAlign: "center", margin: "auto" }}
                >
                  {error}
                </label>
              </Row>
              <Row>
                <label
                  style={{ color: "red", textAlign: "center", margin: "auto" }}
                >
                  {errorMessage}
                </label>
              </Row>
              <Row style={{textAlign:'center', margin:'auto', display:'block'}}> 
              <Link to={{  pathname: "/forgot-password"}} style={{textDecoration:'none'}}>
                <Col style={{textAlign:'center', margin:'auto'}}>
                <a style={{color:'rgb(130, 134, 138)'}}>Forgot Password</a>
                </Col>
                </Link>
                </Row>

                <Row className="login_signup_pos" style={{textAlign:'center', margin:'auto', display:'block'}}> 
              <Link to={{  pathname: "/signup"}} style={{textDecoration:'none'}}>
                <Col style={{textAlign:'center', margin:'auto', fontSize:'13px'}}>
                Don't have an account yet? <a className="login-signup">Sign up here</a>
                </Col>
                </Link>
                </Row>
            </Form>
            <span className="d-inline-block mb-2 mr-2">
              <Modal
                open={modalOpen}
                style={{ marginTop: "5%" }}
                // onClose={this.hanred
              >
                <ModalBody></ModalBody>
                <ModalFooter>
                  <Button
                    color="green"
                    onClick={() => {
                      window.location.reload();
                      setmodalOpen(false);
                    }}
                    //inverted
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </span>
          </CardBody>
        </Card>
        </Col>
      </Row>
    </div>
    </div>
    </section>
    </>
  );
};

 

const mapDispatchToProps = (dispatch) => ({
  getProfile: (data) => dispatch(getProfile(data)),
  
});

export default connect(null, mapDispatchToProps)(Login);
 