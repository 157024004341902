import React, { Component } from "react";
import { Button, Row, Col, Card, Accordion } from "react-bootstrap";
import Api from "../Service/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonCommon from "./ButtonCommon";

import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import moment from "moment";
import BeautyStars from "beauty-stars";
import FD2 from "../../static/Images/FD2.jpg";
import FD3 from "../../static/Images/FD3.jpg";

class OperatorTours extends Component {
  state = {
    list: [],
    banner: "",
    endDate: "",
    startDate: "",
    limit: 10,
    skip: 0,
    id: "",
  };

  componentDidMount() {
    //this.getEvents();
    //this.daydiff();
  }

  //   "limit":"${this.state.limit}","skip":"${this.state.skip}"

  // getEvents(){

  //   Api.get(`/events?where={ "status": "active"}&relations=["eventImages"]&limit=${this.state.limit}&skip=${this.state.skip}`).then(res=>{
  //   console.log(this.state.limit);
  //   console.log(this.state.skip)
  //       if(res.status===200){
  //     this.setState({list:res.data})
  //     console.log('list',this.state.list);
  //     }
  //   })
  //  }

  //  setid = (id) => {
  //   localStorage.setItem("eventid", id);
  // };

  percen =(list ) => {
    var number = list && list.amount;
    var percentToGet = list && list.discount;
 //Calculate the percent.
var percent = (percentToGet / 100) * number;
 return percent;

  }

  daydiff = (list) => {
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    return a.diff(b, "days");
  };
  render() {
    const { image, place, eventlist } = this.props;
    let { banner, endDate, startDate } = this.state;
    let { list } = this.props;
    return (
      <div className="col-lg-12">
        <div className=" section-margin ">
          
            <h2 >Upcoming Adventures by {eventlist[0] && eventlist[0].operator_name}  </h2>
          
         

                <Row style={{marginTop:'25px'}}>
                {eventlist &&
              eventlist.map((item) => (
                <Col lg={3} md={6} className="margin-btm" >
                  <Card
                    className="card2"
                    style={{ height: "300px", borderRadius: "10px", cursor:"pointer" }}
                  >
                    <div
                      onClick={() =>
                        this.props.history.push(`/events/${item.bmtEventId}`)
                      }
                    >
                      <Card.Img
                        variant="top"
                        src={item.banner}
                        style={{
                          height: "200px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      />
                       <Row className="">
 
                    <Col className="">
                    <h5 
                    className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}
                    ><div className="price2"> 
                    {item.discount}% {' '}
                     OFF
                    </div></h5>
                    </Col>
                  </Row>
                    </div>

                    <div
                      onClick={() =>
                        this.props.history.push(`/events/${item.bmtEventId}`)
                      }
                    >
                      <Card.Body className="card_body">
                        <Card.Title className="Title-all font-style" style={{}}>
                          {" "}
                          
                           {item.title.length && (<div className="Textnooverflow">{item.title}</div>)}
                        </Card.Title>
                        {/* <Card.Text> */}
                        <span
                          style={{ fontSize: "12px" }}
                          className="sub-title-all"
                        >
                          {item && item.totalRatings} REVIEWS
                        </span>
                       
                        <h5 className="price">
                        ₹{item.amount- this.percen(item)}
                        {/* {item.amount} */}
                        </h5>
                         
                        <h5  
                        style={{float:'right',
                        alignContent: 'center',
                        marginTop: 10,
                        marginRight: 10
                         }}
                        
                        className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}>
                         <div className="price-dis" style={{ textDecorationLine: 'line-through' }}> ₹ {item.amount}
                         </div>
                         </h5>
                        <p className="sub-title-all">
                          {this.daydiff(item)} days
                        </p>
                        {/* </Card.Text> */}
                      </Card.Body>
                    </div>
                  </Card>
                </Col>
              ))}
                </Row>



          {/* <Row>
            <Accordion style={{ width: "100%" }}>
              <Accordion.Collapse eventKey="0">
                <Row style={{ marginLeft: "10px" }}>
                  <Col lg={3}>
                    <Card
                      className="card2"
                      style={{ marginBottom: 40, height: "340px" }}
                    >
                      <div
                      //   onClick={() => this.props.history.push(`events/${item.id}`)}
                      >
                        <Card.Img
                          variant="top"
                          src={FD2}
                          style={{ width: "100%", height: "180px" }}
                        />
                        <Card.Body>
                          <Card.Title
                            style={{ fontWeight: "bold", fontSize: "12px" }}
                          >
                            Title
                          </Card.Title>
                          <Card.Text style={{ marginBottom: "10px" }}>
                            <span style={{ fontSize: "12px" }}> 10 Days</span>
                            <Row>
                              <span
                                style={{ marginLeft: "15px", fontSize: "12px" }}
                              >
                                <BeautyStars
                                  // value={item.rating}
                                  maxStars="1"
                                  size="12px"
                                  inactiveColor="#f4cc18"
                                  activeColor="#f4cc18"
                                  // value={item && item.rating}
                                />
                              </span>

                              <span style={{ fontSize: "12px" }}>
                                100 REVIEWS
                              </span>
                            </Row>
                          </Card.Text>
                          <Card.Text style={{ fontSize: "12px" }}>
                            From Rs. 500
                          </Card.Text>
                        </Card.Body>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Accordion.Collapse>

              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                style={{ width: "100%" }}
              >
                <div className="adventure-show-more ">
                  {/* <Link to={{  pathname: "/viewEvents"}} style={{textDecoration:'none'}}>  
                  <ButtonCommon
                    className="btncommon btnrounded"
                    label="Load More"
                    handleClick=""
                  />
                  {/* </Link> 
                </div>
              </Accordion.Toggle>
            </Accordion>
          </Row> */}
        </div>
      </div>
    );
  }
}
export default OperatorTours;
