import React, { Component } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import arrow from "../../static/Images/right-arrow.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Background from "../../static/Images/destination.jpg"


import BeautyStars from "beauty-stars";

class TopOperators extends Component {
  // constructor(props) {
  //   super(props);
  //   const { image } = this.props;
  // }
  render() {
    const { operatorslist } = this.props;
   
    return (
      <div className="topoperator" style={{ marginTop: "25px" }}>
        <Row>
         
          {operatorslist &&
            operatorslist.map((item, index) => (
              <Col lg={3} md={6} key={index}>
                <Link
                  to={{ pathname: `/operator-details/${item.id}`, list: item }}
                  style={{ textDecoration: "none" }}
                >

                   
                      <Card
                    
                    className="featured-card"
                    style={{
                      height: "300px",
                      borderRadius: "10px",
                    backgroundImage: item && item.banner?`url(${item && item.banner})`:`url(${Background})`,

                      //background:'url(../../static/Images/register.jpg)',
                     //backgroundColor: 'url(../../static/Images/register.jpg)',
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Card.Body className="card_body">
                      <Card.Title
                        className="Title-all font-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        {item && item.name}
                      </Card.Title>

                      <div
                        className="avatar"
                        style={{
                          //border: "5px solid white",
                          width: "86px",
                          height: "86px",
                          background: "#fc9d2e",
                          borderRadius: "50%",
                          position: "absolute",
                          top: "-170px",
                          //left: "15x",
                          //backgroundSize: "100% 100%",
                         
                          //backgroundPosition: "center",
                        }}
                      >
                        <img 
                         style={{
                          
                          width: "86px",
                          height: "86px",
                          background: "#fc9d2e",
                          borderRadius: "50%",
                          objectFit:'cover',
                         
                         
                          //backgroundSize: "100% 100%",
                         
                          //backgroundPosition: "center",
                        }}
                            src={
                            item && item.mdProfileImage
                          }
                        />
                      </div>

                      <p>
                        <BeautyStars
                          value={item && item.rating}
                          size="16px"
                          inactiveColor="#e0e0d1"
                          activeColor="#f4cc18"
                          // value={item && item.rating}
                        />
                      </p>
                      <span
                        style={{ fontSize: "12px", paddingBottom: "20px" }}
                        className="sub-title-all"
                      >
                        {item && item.totalRatings} Reviews
                      </span>
                    </Card.Body>

                    <div className="eventopacity"></div>
                  </Card>{" "}
                </Link>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}
export default TopOperators;
