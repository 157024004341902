import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import arrow from '../../static/Images/right-arrow.svg';

class AdventureCard extends Component {
  // constructor(props) {
  //   super(props);
  //   const { image } = this.props;
  // }
  render() {
    const { image } = this.props;
    return (
      <div className='adventure-card-container right-grid-cards'>
        <div className='adventure-card '>
          <div className='square'></div>
      
          <img src={image} alt='Adventure' width='100%' height='auto' />
          <div className='adventure-card-content'>
            <div className='adventure-card-text'>
              <h1>Scuba Diving</h1>
            </div>
            <div className='arrow-right-adventure'>
              <img src={arrow} width='50px' height='auto' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdventureCard;
