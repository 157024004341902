import React, { Component, useEffect, useState } from "react";
import { Container} from "react-bootstrap";
import NavBar from "../Components/NavBar";
import SearchBox from "../Components/SearchBox";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DestinationCard from "../Components/DestinationCard";
import Destinations from "../Components/Destinations";
import AdventureCard from "../Components/AdventureCard";
import AdventureEvents from "../Components/AdventureEvents";
import FeaturedEvents from "../Components/FeaturedEvents";
import BannerCarousel from "../Components/BannerCarousel";
import CovidPolicy from "../Components/CovidPolicy";

import TopOperators from "../Components/TopOperators";
import Footer from "../Components/Footer";
import Api from "../Service/Api";
import ReviewCard from "../Components/Reviewcard";
import moment from 'moment';
import Loaders from "../Components/FullScreenLoader";
import Axios from "axios";
import BlogCards from "../Components/BlogCards";
import {connect} from 'react-redux'
import Category from "../Components/Category";
import  {getsliderimages,getFeatured,getDestination,getevents,getOperators,getReviews,getCategory, getallOperators,getCovidStatus} from '../Redux/actions/common'

class Homepage extends Component {
  componentDidMount(){
    const {blog,getsliderimages,getFeatured,getDestination,getevents,getOperators,getReviews,getCategory, getallOperators,getCovidStatus}= this.props
    setTimeout(()=>getsliderimages(),0)
    setTimeout(()=>  getCovidStatus(),100)
    setTimeout(()=>  getFeatured(),500)
    setTimeout(()=>  getevents(),500)
    setTimeout(()=> getDestination(),1500)
    setTimeout(()=>  getReviews(),2000)
    setTimeout(()=> getCategory(),2000)
    setTimeout(()=>  getOperators(),2500)
    setTimeout(()=>this.getBlogpost(),3000)
    
  
  }
  state={
    blog:[],
    loaders:false
  }
  getBlogpost = () => {
      //Axios.get(`http://bookmytrek.sparkrex.com/blog/wp-json/wp/v2/posts?page=1&per_page=6&order=desc&orderby=date`)
    Axios.get(`https://blog.bookmytrek.com/index.php/wp-json/wp/v2/posts?page=1&per_page=6&order=desc&orderby=date`)
      .then((res) => {
        this.setState({blog:res.data||[]});
      })
      .catch((err) => {});
  }
  render(){
  let {loaders,blog} = this.state
  const {history,images,featured,eventlist, destination,topoperator, eventreviews,categorylist,covidstatus}= this.props
  const {status} =covidstatus ||{}
  return (
    <div className="home_background">
      <NavBar />
    {/*<CovidPolicy/>*/}
      <div 
       style={{minHeight:'10vh'}}
      >

     <BannerCarousel list={images}/>
     </div>
     {loaders&&<Loaders/>}
     {status &&  <CovidPolicy/>}
       <div >
      
      {/* <section className="search-section">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SearchBox />
        </MuiPickersUtilsProvider>
      </section> */}
      <Container>
      <section className="adventure-events section-margin ">
        <h1 className="">Featured Adventures</h1>
        <div className="">
          <FeaturedEvents history={history} featuredlist={featured} />
        </div>
      </section>
      <section className="section-margin">
        <h1>Adventures</h1>
        <div >
          <AdventureEvents history={history} list={eventlist} />
        </div>
      </section>
      <section className="top-destinations section-margin">
        <h1>Top Destinations</h1>

        <Destinations
          history={history}
          destinationslist={destination}
        />
      </section>
      {eventreviews.length!=0 &&
      <section className="adventure-events section-margin">
        <h1>What Our Tourist Says</h1>
       
        <ReviewCard history={history} reviewlist={eventreviews} /> 
           
      </section>
   }
 
      <section className="adventure-events section-margin">
        <h1>Top Operators</h1>
        <div >
          <TopOperators history={history} operatorslist={topoperator} />
        </div>
      </section>
      <section className="adventure-events section-margin">
        <h1>Adventure Categories</h1>
        <div >
          <Category history={history}  list={categorylist} />
        </div>
      </section>

      <section className="adventure-events section-margin">
        <h1>Recent in Blog</h1>
        <div >
          <BlogCards history={history} list={blog||[]} />
        </div>
      </section>
    </Container>
    </div>
      <Footer />
    </div>
  );
};
}
const mapStateToProps = (state) => ({
  images: state.homeReducer.images,
  featured : state.homeReducer.featured,
  eventlist  : state.homeReducer.eventlist,
  destination : state.homeReducer.destination,
  topoperator : state.homeReducer.topoperator,
  eventreviews: state.homeReducer.eventreviews,
  categorylist: state.homeReducer.categorylist,
  covidstatus: state.homeReducer.covidstatus,
});

const mapDispatchToProps = (dispatch) => ({
  getsliderimages: () => dispatch(getsliderimages()),
  getevents: () => dispatch(getevents()),  
  getFeatured: () => dispatch(getFeatured()),  
  getDestination: () => dispatch(getDestination()),
  getOperators: () => dispatch(getOperators()), 
  getReviews: () => dispatch(getReviews()),  
  getCategory: () => dispatch(getCategory()), 
  getCovidStatus: () => dispatch(getCovidStatus()), 
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
