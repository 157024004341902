import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class TermsAndCondition extends Component{
    render(){
        return(
           <div>
              <NavBar />
              <div className='container footer-con'>
             <h1>Terms and Conditions</h1>
             

             <p>-PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY USING THIS SITE, YOU AGREE TO ABIDE BY THESE
TERMS AND CONDITIONS, WHICH WILL CONSTITUTE OUR AGREEMENT (“AGREEMENT”).</p>
             <p>
                Welcome to the BookMyTrek website. This Site is provided solely to assist individuals in gathering travel information, searching for and
connecting with travel operators offering travel services, posting opinions on travel-related experiences, for booking travel packages and trips, for
tracking customers’ travel history and for no other purpose. The following terms and conditions, together with any documents they expressly
incorporate by reference (collectively, this “Agreement"), govern your access to and use of the Site including any content, functionality, and
services offered on or through the Site whether as a guest or a registered member. The term “you” refers to the individual visiting the Site and/or
contributing content on this Site and/or making a booking on this Site.
             </p>

             <p>
             Please read this Agreement carefully before you start to use the Site. <strong>By using the Site or by clicking to accept or agree to this Agreement
when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our Privacy Policy,
incorporated herein by reference</strong>. If you do not want to agree to this Agreement or the Privacy Policy, you must not access or use the Site.
             </p>
             <p>
                Please read this Agreement carefully before you start to use the Site. By using the Site or by clicking to accept or agree to this Agreement
when this option is made available to you, you accept and agree to be bound and abide by this Agreement and our Privacy Policy,
incorporated herein by reference. If you do not want to agree to this Agreement or the Privacy Policy, you must not access or use the Site.
This Site is offered and available to users throughout the world. By using this Site, you represent and warrant that you are of legal age to form a
binding contract with BookMyTrek and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not
access or use the Site. Persons below the age of 18 can access/view the website but cannot open an account or create a profile. They hence
cannot add reviews; make bookings or contact suppliers or travel agents.
             </p>

            <h2>Changes to this Agreement</h2>
            <p>
                We may revise and update this Agreement from time to time in our sole discretion. All changes are effective immediately when we post them, and
apply to all access to and use of the Site thereafter. However, any changes to the dispute resolution provisions set forth in Dispute Resolution will
not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Site. Your continued use of
the Site following the posting of revised Agreement means that you accept and agree to the changes.
            </p>

<h2>Accessing the Site and Account Security</h2>
            <p>
            We reserve the right to withdraw or amend this Site, and any service or material we provide on the Site, in our sole discretion without notice. We
will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access
to some parts of the Site, or the entire Site, to users, including registered members.
            </p>
            <p>
            You are responsible for:
            </p>
            <p>
            Making all arrangements necessary for you to have access to the Site.
            </p>
            <p>
            Ensuring that all persons who access the Site through your internet connection are aware of this Agreement and comply with them.
            </p>
            <p>
            To access the Site or some of the resources it offers, you may be asked to provide certain registration details or other information. You may
register to utilize this Site by signing up and completing the registration page and providing us with current, complete and accurate information as
requested by the online registration form. You agree that all information you provide to register with this Site or otherwise, including but not
limited to through the use of any interactive features on the Site, is governed by our <strong>Privacy Policy</strong>, and you consent to all actions we take with
respect to your information consistent with our Privacy Policy.
            </p>
            <p>
                If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat
such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal
to you and agree not to provide any other person with access to this Site or portions of it using your user name, password or other security
information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of
security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing
your account from a public or shared computer so that others are not able to view or record your password or other personal information.
            </p>

            <p>
We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole
discretion for any or no reason, including if, in our opinion, you have violated any provision of this Agreement.
            </p>

            <h2>Intellectual Property Rights</h2>
            <p>
            The Site and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and
audio, and the design, selection and arrangement thereof), are owned by BookMyTrek, its licensors or other providers of such material and are
protected by Indian copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
            </p>
            <p>
                This Agreement permits you to use the Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create
derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Site, except as follows:
            </p>
            <p>
                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
            </p>

            <p>
            You may store files that are automatically cached by your Web browser for display enhancement purposes.
            </p>

            <p>
                You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further
reproduction, publication or distribution.
            </p>
            <p>
                You may share travel itineraries, travel plans and other travel related information that is available on the Site with your friends and families for
their own personal, non-commercial use.
            </p>

            <p>
                If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for
your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
            </p>
            <p>
            We provide social media features: <a className="alinks" href="https://www.facebook.com/bookmytrekapp">https://www.facebook.com/bookmytrekapp</a> and <a className="alinks" href="https://www.instagram.com/bookmytrek/">https://www.instagram.com/bookmytrek/</a> with certain content,
you may take such actions as are enabled by such features.
            </p>

            <p>You must not:</p>
            <p>
            Modify copies of any materials from this site.
            </p>
            
            <p>
            Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.
            </p>
            <p>
                Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.
            </p>

            <p>
                You must not access or use for any commercial purposes any part of the Site or any services or materials available through the Site.
            </p>
            <p>
                If you wish to make any use of material on the Site other than that set out in this section, please address your request to: <a className="alinks">hello@bookmytrek.com.</a>
            </p>
            <p>
                If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Site in breach of this Agreement,
your right to use the Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No
right, title or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by
BookMyTrek. Any use of the Site not expressly permitted by this Agreement is a breach of this Agreement and may violate copyright, trademark
and other laws.
            </p>


           <h2> Trademarks</h2>
           <p>
BookMyTrek’s name, the terms “BookMyTrek”, “BookMyTrek Travels”, BookMyTrek’s logo and all related names, logos, product and service
names, designs and slogans are trademarks of BookMyTrek or its affiliates, “Book My Trek”, “BookMyTrek”, <a className="alinks">“bookmytrek.com”</a>, “BMT”, or
licensors. You must not use such marks without the prior written permission of BookMyTrek. All other names, logos, product and service names,
designs and slogans on this Site are the trademarks of their respective owners.
</p>
     

<h2>Prohibited Uses</h2>
<p>
You may use the Site only for lawful purposes and in accordance with this Agreement. You agree not to use the Site:
</p>
<p>
In any way that violates any applicable, state, local or central law or regulation.
</p>
<p>
For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for
personally identifiable information or otherwise.
</p>
<p>
To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards as set out in this
Agreement.
</p>
<p>
To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter" or "spam" or any other
similar solicitation.
</p>
<p>
To impersonate or attempt to impersonate BookMyTrek, BookMyTrek employee, another user or any other person or entity (including, without
limitation, by using e-mail addresses or screen names associated with any of the foregoing).
</p>
<p>
To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm
BookMyTrek or users of the Site or expose them to liability.
</p>
<p>
Additionally, you agree not to:
</p>
<p>
Use the Site in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Site, including
their ability to engage in real time activities through the Site.
</p>
<p>
Use any robot, spider or other automatic device, process or means to access the Site for any purpose, including monitoring or copying any of the
material on the Site.
</p>
<p>
Use any manual process to monitor or copy any of the material on the Site or for any other unauthorized purpose without our prior written
consent.
</p>
<p>
Use any device, software or routine that interferes with the proper working of the Site.
</p>
<p>
Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.
</p>
<p>
Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Site, the server on which the Site is stored, or any
server, computer or database connected to the Site.
</p>
<p>
Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.
</p>
<p>
Otherwise attempt to interfere with the proper working of the Site.
</p>


<h2>Member Contributions</h2>
<p>
The Site may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards and other interactive features
(collectively, "Interactive Services") that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, "po
st") content or materials (collectively, "Member Contributions") on or through the Site.
</p>
<p>
All Member Contributions must comply with the Content Standards set out in this Agreement.
</p>
<p>
Any Member Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any Member Contribution on
the Site, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to
use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material according to your account settings.
</p>
<p>
You represent and warrant that:
</p>
<p>
You own or control all rights in and to the Member Contributions and have the right to grant the license granted above to us and our affiliates and
service providers, and each of their and our respective licensees, successors and assigns.
</p>
<p>
All of your Member Contributions do and will comply with this Agreement.
</p>
<p>
You understand and acknowledge that you are responsible for any Member Contributions you submit or contribute, and you, not BookMyTrek,
have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.
</p>
<p>
We are not responsible, or liable to any third party, for the content or accuracy of any Member Contributions posted by you or any other user of
the Site.
</p>



<h2>Monitoring and Enforcement; Termination</h2>
<p>
We have the right to:
</p>
<p>
Remove or refuse to post any Member Contributions for any or no reason in our sole discretion.
</p>
<p>
Take any action with respect to any Member Contribution that we deem necessary or appropriate in our sole discretion, including if we believe
that such Member Contribution violates this Agreement, including the Content Standards, infringes any intellectual property right or other right of
any person or entity, threatens the personal safety of users or Members of the Site or the public or could create liability for BookMyTrek.
</p>
<p>
Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their
intellectual property rights or their right to privacy.
</p>
<p>
Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site.
</p>
<p>
Terminate or suspend your access to all or part of the Site for any or no reason, including without limitation, any violation of this Agreement.
</p>
<p>
Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us
to disclose the identity or other information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS
BOOKMYTREK AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION
TAKEN BY BOOKMYTREK OR ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY
ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
</p>
<p>
However, we do not undertake to review all material before it is posted on the Site, and cannot ensure prompt removal of objectionable material
after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content
provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described
in this section.
</p>



<h2>Content Standards</h2>
<p>
These content standards apply to any and all Member Contributions and use of Interactive Services. Member Contributions must in their entirety
comply with all applicable, state, local and central laws and regulations. Without limiting the foregoing, Member Contributions must not:
</p>
<p>
Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise
objectionable.
</p>
<p>
Promote sexually explicit or pornographic material, violence, or discrimination based on race, caste, sex, religion, nationality, disability, sexual
orientation or age.
</p>
<p>
Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.
</p>
<p>
Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal
liability under applicable laws or regulations or that otherwise may be in conflict with this Agreement and our Privacy Policy.
</p>
<p>
Be likely to deceive any person.
</p>
<p>
Promote any illegal activity, or advocate, promote or assist any unlawful act.
</p>
<p>
Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.
</p>
<p>
Impersonate any person, or misrepresent your identity or affiliation with any person or organization.
</p>
<p>
Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.
</p>
<p>
Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
</p>

<h2>Copyright Infringement</h2>
<p>
If you believe that any Member Contributions violate your copyright, please see our Copyright Policy for instructions on sending us a notice of
copyright infringement. It is the policy of BookMyTrek to terminate the user accounts of repeat infringers.
</p>

<h2>Reliance on Information Posted</h2>
<p>
The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy,
completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and
responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any
of its contents.
</p>
<p>
This Site includes content provided by third parties, including materials provided by other members, users, and third-party licensors, third party
content applications, BookMyTrekians, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and
all articles and responses to questions and other content, other than the content provided by BookMyTrek, are solely the opinions and the
responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of BookMyTrek. We are not
responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
</p>

<h2>Changes to the Site</h2>
<p>
We may update the content on this Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site
may be out of date at any given time, and we are under no obligation to update such material.
</p>

<h2>Booking Policy and Other Terms and Conditions</h2>
<p>
All purchases through our site or other transactions for the booking of trips formed through the Site or as a result of visits made by you are
governed by our Booking Policy, which are hereby incorporated into this Agreement.
</p>
<p>
Additional terms and conditions may also apply to specific portions, services or features of the Site. All such additional terms and conditions are
hereby incorporated by this reference into this Agreement.
</p>

<h2>Travel Operator or Service Provider Terms and Conditions</h2>
<p>
You agree to abide by the terms and conditions of a purchase imposed by a travel operator or service provider with whom you elect to deal,
including payments of all amounts when due and compliance with the travel operator’s rules and restrictions regarding availability and use of
products and services.
</p>

<h2>Linking to the Site and Social Media Features</h2>
<p>
You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but
you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express
written consent.
</p>
<p>
This Site may provide certain social media features that enable you to:
</p>

<p>
Link from your own or certain third-party Sites to certain content on this Site.
</p>
<p>
Send e-mails or other communications with certain content, or links to certain content, on this Site.
</p>
<p>
Cause limited portions of content on this Site to be displayed or appear to be displayed on your own or certain third-party Sites.
</p>
<p>
You may use these features solely as they are provided by us and solely with respect to the content that is displayed with and otherwise in
accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:
</p>
<p>
Establish a link from any Site that is not owned by you.
</p>
<p>
Cause the Site or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other
site.
</p>
<p>
Link to any part of the Site other than the homepage.
</p>
<p>
Otherwise take any action with respect to the materials on this Site that is inconsistent with any other provision of this Agreement.
</p>
<p>
The Site from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set
out in this Agreement.
</p>
<p>
You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking
permission without notice.
</p>
<p>
We may disable all or any social media features and any links at any time without notice in our discretion.
</p>

<h2>Links from the Site</h2>
<p>
If the Site contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes
links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or
resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of
the third-party Sites linked to this Site, you do so entirely at your own risk and subject to the terms and conditions of use for such Sites.
        </p>

<h2>Geographic Restrictions</h2>
<p>
We make no claims that the Site or any of its content is accessible or appropriate outside of India. Access to the Site may not be legal by certain
persons or in certain countries. If you access the Site from outside India, you do so on your own initiative and are responsible for compliance with
local Indian laws.
</p>

<h2>Disclaimer of Warranties</h2>
<p>
You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Site will be free of
viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular
requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any
reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS
OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE LINKED TO IT.
</p>
<p>YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR SOLE RISK.</p>
<p>
THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS
AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER BOOKMYTREK NOR ANY
PERSON ASSOCIATED WITH BookMyTrek MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER
BOOKMYTREK NOR ANYONE ASSOCIATED WITH BOOKMYTREK REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT OR
ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED,
THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL
OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
</p>
<p>
TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, BOOKMYTREK AND ITS AFFILIATES HEREBY DISCLAIMS ALL
WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
WARRANTIES OF MERCHANTABILITY, WARRANTIES OF TITLE, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. NO
ORAL OR WRITTEN INFORMATION PROVIDED BY BOOKMYTREK OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
PROVIDERS, MERCHANTS, SPONSORS, LICENSORS OR THE LIKE SHALL CREATE A WARRANTY; NOR SHALL YOU RELY ON ANY
SUCH INFORMATION OR ADVICE.
</p>

<p>
YOU ACKNOWLEDGE THAT BOOKMYTREK DOES NOT CONTROL IN ANY RESPECT ANY INFORMATION, PRODUCTS OR SERVICES
OFFERED BY THIRD PARTIES ON OR THROUGH THIS SITE. EXCEPT AS OTHERWISE AGREED IN WRITING, BOOKMYTREK AND ITS
AFFILIATES ASSUME NO RESPONSIBILITY FOR AND MAKE NO WARRANTY OR REPRESENTATION AS TO THE ACCURACY,
CURRENCY, COMPLETENESS, RELIABILITY OR USEFULNESS OF CONTENT OR SERVICES DISTRIBUTED OR MADE AVAILABLE BY
THIRD PARTIES THROUGH THIS SITE. PRICES AND AVAILABILITY ARE SUBJECT TO CHANGE AT ANY TIME PRIOR TO PURCHASE.
BOOKMYTREK DISCLAIMS ALL LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES RELATING TO THE INFORMATION AND
DESCRIPTION OF THE TRAVEL SERVICES AND TRAVEL PRODUCTS DISPLAYED ON THIS SITE (INCLUDING, WITHOUT LIMITATION,
THE PRICING, PHOTOGRAPHS, LIST OF HOTEL AMENITIES, GENERAL PRODUCT DESCRIPTIONS, ETC.). IN ADDITION, BOOKMYTREK
EXPRESSLY RESERVES THE RIGHT TO UPDATE PRICES AT ANY TIME AND/OR CORRECT ANY PRICING ERRORS ON OUR SITE AND
/OR ON PENDING RESERVATIONS MADE UNDER AN INCORRECT PRICE.
</p>

<p>
BOOKMYTREK DOES NOT MAKE ANY WARRANTY THAT THIS SITE OR ITS CONTENT WILL MEET YOUR REQUIREMENTS, OR THAT
THE SITE OR CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT DEFECTS, IF ANY, WILL BE
CORRECTED. BOOKMYTREK DOES NOT REPRESENT OR WARRANT THAT MATERIALS IN THIS SITE OR INFORMATION PROVIDED BY
BOOKMYTREK VIA EMAIL OR OTHER MEANS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. NOR DOES
BOOKMYTREK MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF BOOKMYTREK OR ITS
CONTENT OR AS TO THE ACCURACY, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH USE OF THE
SITE.
</p>
<p>
BOOKMYTREK ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY A MEMBER OR USER, INCLUDING, BUT NOT
LIMITED TO, LOSS OF DATA FROM DELAYS, NONDELIVERIES OF CONTENT OR EMAIL, ERRORS, SYSTEM DOWN TIME,
MISDELIVERIES OF CONTENT OR EMAIL, NETWORK OR SYSTEM OUTAGES, FILE CORRUPTION OR SERVICE INTERRUPTIONS
CAUSED BY THE NEGLIGENCE OF BOOKMYTREK, ITS AFFILIATES, ITS LICENSORS OR A MEMBER OR USER’S OWN ERRORS AND
/OR OMISSIONS.
</p>
<p>
BOOKMYTREK ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY A USER OR MEMBER INCLUDING, BUT NOT
LIMITED TO, LOSS FROM NONDELIVERY OF SERVICES PURCHASED FROM TRAVEL OPERATORS OR COMPANIES LISTED ON
BookMyTrek SUCH AS (BUT NOT LIMITED TO) HOTELS, TOURS, TRANSFERS AND VOUCHERS. THE MEMBER OR USER IS AWARE
THAT THE TRAVEL OPERATORS OR COMPANIES AND THEIR AGENTS PROVIDING SUCH SERVICES ARE NOT IN ANY WAY RELATED
TO BOOKMYTREK AND BOOKMYTREK HAS NO RESPONSIBILITY TO ENSURE DELIVERY OF SUCH SERVICES.
</p>
<p>
BOOKMYTREK DISCLAIMS ANY WARRANTY OR REPRESENTATION THAT CONFIDENTIALITY OF INFORMATION TRANSMITTED
THROUGH THIS WEBSITE WILL BE MAINTAINED.
</p>

<h2>Limitation on Liability</h2>
<p>
IN NO CIRCUMSTANCE, INCLUDING WITHOUT LIMITATION NEGLIGENCE, SHALL BOOKMYTREK OR ITS PARENTS, SUBSIDIARIES OR
AFFILIATES OR THEIR LICENSORS, EMPLOYEES, AGENTS, OFFICERS, DIRECTORS OR ANY OTHER PARTY INVOLVED IN CREATING,
PRODUCING, TRANSMITTING, OR DISTRIBUTING BOOKMYTREK CONTENT (COLLECTIVELY THE “COVERED PARTIES”) BE LIABLE TO
ANY PERSON OR ENTITY WHATSOEVER FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, COMPENSATORY, PUNITIVE OR
CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
LOSS OF REVENUE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE ARISING FROM OR IN CONNECTION WITH THE USE OR INABILITY TO USE
THE SITE OR ANY CONTENT PROVIDED BY OR THROUGH THIS SITE OR ANY PRODUCTS OR SERVICES OBTAINED THORUGH THE
SITE OR RESULTING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR OTHER
INFORMATION THAT IS SENT OR RECEIVED, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, LOSS OF BUSINESS
OR ANTICIPATED SAVINGS, USE, DATA OR OTHER INTANGIBLES. THE LIMITATIONS OF LIABILITY SHALL APPLY REGARDLESS OF
THE FORM OF ACTION, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF A
COVERED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
</p>
<p>
FURTHER, THE TRAVEL OPERATORS OR SERVICE PROVIDER ON THE SITE ARE INDEPENDENT CONTRACTORS AND NOT AGENTS
OR EMPLOYEES OF THE COVERED PARTIES. TO THE EXTENT PERMITTED BY LAW, THE COVERED PARTIES DO NOT ASSUME
LIABILITY FOR ANY INJURY, DAMAGE, DEATH, PROPERTY DAMAGE, LOSS, ACCIDENT OR DELAY DUE TO AN ACT, ERROR OR
OMISSION OF A TRAVEL OPERATOR OR SERVICE PROVIDER, INCLUDING, WITHOUT LIMITATION, AN ACT OF NEGLIGENCE OR THE
DEFAULT OF A SERVICE PROVIDER, OR AN ACT OF GOD. THE COVERED PARTIES SHALL HAVE NO LIABILITY AND WILL MAKE NO
REFUND IN THE EVENT OF ANY DELAY, CHANGE IN ITINERARY, OTHER CHANGES TO THE TRAVEL PACKAGE, CANCELLATION,
OVERBOOKING, STRIKE, FORCE MAJEURE OR OTHER CAUSES BEYOND THEIR DIRECT CONTROL, AND THEY HAVE NO
RESPONSIBILITY FOR ANY ADDITIONAL EXPENSE, OMISSIONS, DELAYS, RE-ROUTING OR ACTS OF ANY GOVERNMENT OR
AUTHORITY.
</p>
<p>
IF, NOTWITHSTANDING THE ABOVE, A COVERED PARTY IS FOUND LIABLE FOR ANY LOSS OR DAMAGE RELATING TO THE USE OF
THIS SITE, YOU AGREE THE LIABILITY OF ANY SUCH PARTY SHALL IN NO EVENT EXCEED THE TRANSACTION FEES ASSESSED BY
BOOKMYTREK FOR YOUR TRANSACTION ON THIS SITE. THIS LIMITATION OF LIABILITY REFLECTS THE ALLOCATION OF RISK
BETWEEN THE PARTIES.
</p>
<p>
THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
</p>

<h2>Travel Destinations</h2>
<p>
You are responsible for ensuring that you meet Indian entry requirements and that your travel documents, such as passports, visas (transit,
business, tourist, and otherwise) and special permissions to restricted areas, are in order and any other Indian entry requirements are met.
Although most travel, in India, is completed without incident, travel to certain destinations in India may involve greater risk than others.
BookMyTrek shall not be liable for damages or losses that may result from travel to such destinations within India. BookMyTrek urges
passengers to review travel prohibitions, warnings, announcements and advisories issued by the Indian Government and local authorities. BY
OFFERING TRAVEL SERVICES TO PARTICULAR INDIAN DESTINATIONS, BOOKMYTREK DOES NOT REPRESENT OR WARRANT THAT
TRAVEL TO SUCH POINTS IS ADVISABLE OR WITHOUT RISK, AND IS NOT LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT
FROM TRAVEL TO SUCH DESTINATIONS.
</p>

<h2>Indemnification</h2>
<p>
You agree to defend, indemnify and hold harmless BookMyTrek and the Covered Parties (defined above) from and against any claims, liabilities,
damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of
this Agreement or your use of the Site, including, but not limited to, your Member Contributions, any use of the Site's content, services and
products other than as expressly authorized in this Agreement or your use of any information obtained from the Site.
</p>

<h2>Dispute Resolution and Governing Law</h2>
<p>
This Agreement is governed by Indian Laws. Any and all controversies, disputes, demands, counts, claims, or causes of action (including the
interpretation and scope of this clause), legal suit, action or proceeding arising out of, or related to, this Agreement or the Site between you and
BookMyTrek and our employees, agents, successors, or assigns, shall exclusively be settled through the courts of competent jurisdiction in
Mumbai, India.
</p>

<h2>Waiver and Severability</h2>
<p>
No waiver of by BookMyTrek of any term or condition set forth in this Agreement shall be deemed a further or continuing waiver of such term or
condition or a waiver of any other term or condition, and any failure of BookMyTrek to assert a right or provision under this Agreement shall not
constitute a waiver of such right or provision.
</p>
<p>
If any provision of this Agreement is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any
reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of this Agreement will continue in
full force and effect.
</p>

<h2>Entire Agreement</h2>
<p>
This Agreement and our Privacy Policy constitute the sole and entire agreement between you and BookMyTrek with respect to the Site and
supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the
Site.
</p>
<h2>
Your Comments and Concerns
</h2>
<p>
All notices of copyright infringement claims should be sent to the copyright agent designated in our Copyright Policy in the manner and by the
means set forth therein. All other feedback, comments, requests for technical support and other communications relating to the Site should be
directed to: <a className="alinks">hello@bookmytrek.com</a>
</p>

         </div>

         <Footer />
         </div>


        )
    }
    }
    export default TermsAndCondition;
