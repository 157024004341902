import React, { Component } from 'react';
import { Button ,Row, Col, Card
} from 'react-bootstrap';

import {
  DropdownToggle, DropdownMenu,
  Nav,  NavItem, NavLink,
  UncontrolledTooltip, UncontrolledButtonDropdown

} from 'reactstrap';
import logo from '../../static/Images/logo.png';
import { FaUserAlt} from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Oplogo  from '../../static/Images/mojo.png';

import DestinationImage from '../../static/Images/destination1.jpg';
import BeautyStars from "beauty-stars";
import FD1 from '../../static/Images/FD1.jpg';
import FD2 from '../../static/Images/FD2.jpg';
import FD3 from '../../static/Images/FD3.jpg';

import FD4 from '../../static/Images/FD4.jpg';
import ButtonCommon from './ButtonCommon';




class OperatorList extends Component {
  render() {
    return (
      <div className='section2'>
        <div>
            <Row className="operator-title">
                <h2 className="operator-titleh2">Travellers' Choice Platinum </h2>
            </Row>
            <Row>
                <p className="operator-titleh2">The very best tour operators
                </p>
            </Row>

            <Row style={{paddingTop:'30px'}}>
                <Col lg={4}>
                 <Link to={{  pathname: "/operator-details"}} style={{textDecoration:'none'}}>
                 <Card >
                        <img 
                    src={FD1} 
                   
                    alt='' width='100%' height='300px' />
                <Card.Body>
                    <Row>
                            <Col lg={4}>
                            <div className="op-logo col-lg4 col-md-3">
                            <img  src={Oplogo} alt='' width='100%' height='50%' />

                            </div>
                            </Col>
                        <Col lg={6} style={{marginLeft:'10px'}}>
                            <Row>
                            On The Go Tours
                            </Row>
                            <Row>
                            <BeautyStars
                          // value={item.rating}
                          size="16px"
                          inactiveColor="grey"
                          activeColor="#2579f7"
                         // value={item && item.rating}
                        />
                        <span className="review-total-font">2000 Reviews</span>
                            </Row>
                   </Col>
                   </Row>
                </Card.Body>
                </Card>
                </Link>
                </Col>


                 <Col lg={4}>
            <Card >
                        <img 
                    src={FD2} 
                   
                    alt='' width='100%' height='300px' />
                <Card.Body>
                    <Row>
                            <Col lg={4}>
                            <div className="op-logo">
                            <img  src={Oplogo} alt='' width='100%' height='50%' />

                            </div>
                            </Col>
                        <Col lg={6} style={{marginLeft:'10px'}}>
                            <Row>
                            On The Go Tours
                            </Row>
                            <Row>
                            <BeautyStars
                          // value={item.rating}
                          size="16px"
                          inactiveColor="grey"
                          activeColor="#2579f7"
                         // value={item && item.rating}
                        />
                        <span  className="review-total-font" >2000 Reviews</span>
                            </Row>
                   </Col>
                   </Row>
                </Card.Body>
                </Card>
                </Col>

                 <Col lg={4}>
            <Card >
                        <img 
                    src={FD3} 
                   
                    alt='' width='100%' height='300px' />
                <Card.Body>
                    <Row>
                            <Col lg={4}>
                            <div className="op-logo">
                            <img  src={Oplogo} alt='' width='100%' height='50%' />

                            </div>
                            </Col>
                        <Col lg={6} style={{marginLeft:'10px'}}>
                            <Row>
                            On The Go Tours
                            </Row>
                            <Row>
                                        <BeautyStars
                                    // value={item.rating}
                                    size="16px"
                                    inactiveColor="grey"
                                    activeColor="#2579f7"
                                    // value={item && item.rating}
                                    />
                                    <span  className="review-total-font">2000 Reviews</span>
                            </Row>
                   </Col>
                   </Row>
                  
                </Card.Body>
                </Card>
                </Col>
               
            </Row>
            <Row style={{marginTop:'20px', marginBottom:'50px'}}>
                   <div className="adventure-show-more">
      
                            <Link to={{  pathname: "/viewEvents"}} style={{textDecoration:'none'}}>
                            <ButtonCommon className="btncommon btnrounded" label='All Events' handleClick=''/>
                        </Link>
                     </div>
                 </Row>
        </div>
      </div>
    );
  }
}

export default OperatorList;
