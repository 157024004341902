import React, { useState, Component } from "react";
import {useHistory} from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import NavBar from "../Components/NavBar";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Api from "../Service/Api";
import ButtonCommon from '../Components/ButtonCommon';

const  ForgotPassword = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState("");
  const [disable, setdisable] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [errorMessage, setErrorMessage] =useState("")
  const [message, setmessage] = useState("");
  let history=useHistory();

  const OnSubmit = async () => {
 
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
     // if (password.length !== 0) {
        let data = {
          email,
         // password,
        };

        Api.post("/auth/request-password-reset", data)
        .then((res) => {
           if (res.status === 201) {
            setErrorMessage("");
            seterror("");
            setmessage("Email sent to reset your password");
          }
        })
        .catch((err) => {
         // console.log(err);
          setmessage(" ");
  setErrorMessage("User Not Found!");
   
        });
    ////  } else {
     //   seterror("Please enter Password");
     // }
    } else {
      seterror("Please Enter Email");
      setErrorMessage("");
    }
  };

  return (
    <>
          <NavBar/>
    <section className="register-section">
    <div className="container register">
      <div>
      <Row style={{paddingTop:'40px'}}>
      <Col
        lg="12"
       
      >
        <Card className="Card-style"  style={{ alignSelf: "center", margin: "auto", maxWidth:'500px' }}>
         
          <h1 style={{  textAlign:'center'}}>Forgot Password</h1>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  value={email}
                  onChange={(event) => setemail(event.target.value)}
                  name="email"
                  placeholder="Enter Email"
                />
              </FormGroup>
            
            
             

              <Row className="justify-content-center btnmargin">
              <ButtonCommon
                  //  disabled={disable}
                  label="Submit"
                  className="all-events-btn text-center"
                  handleClick={OnSubmit}
                >
                
                </ButtonCommon>
               
              </Row>
              <Row style={{paddingTop:'10px'}}>
              <label style={{color:'red', textAlign:'center', margin:'auto'}}>{error}</label>
              </Row>
              <Row>
              <label style={{color:'red', textAlign:'center', margin:'auto'}}>{errorMessage}</label>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                {message && (
                  <label
                    style={{
                      color: "green",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    {message}
                  </label>
                )}
              </Row>
              {/* <Row>
                {errorMessage && (
                  <label
                    style={{
                      color: "red",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    {errorMessage}
                  </label>
                )}
              </Row> */}
            </Form>
            <span className="d-inline-block mb-2 mr-2">
              <Modal
                open={modalOpen}
                style={{ marginTop: "5%" }}
                // onClose={this.hanred
              >
                <ModalBody></ModalBody>
                <ModalFooter>
                  <Button
                    color="green"
                    onClick={() => {
                      window.location.reload();
                      setmodalOpen(false);

                    }}
                    //inverted
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </span>
          </CardBody>
        </Card>
        </Col>
      </Row>
    </div>
    </div>
    </section>
    </>
  );
};

export default ForgotPassword;
