import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Form,
  Modal,
  Dropdown,
  Table,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { Label, Input } from "reactstrap";
import Api from "../Service/Api";
import profile from "../../static/Images/profile.png";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import ButtonCommon from "../Components/ButtonCommon";
import Loader from "../Components/Loader";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { getProfile } from "../Redux/actions/common";

const Verify = (props) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [validated, setValidated] = useState(false);
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState(new Date());
  const [error, seterror] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [country, setcountry] = useState("");
  const [about, setaboutus] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [profileImage, setProfile] = useState("");
  const [address, setaddress] = useState("");
  const [countrylist, setcountrylist] = useState(["India", "America"]);
  const [listnew, setnewlist] = useState([""]);
  const [edit, setEdit] = useState(false);
  const [loader, setloader] = useState(false);
  const [imagechange, setimagechange] = useState(false);
  const [banner, setbanner] = useState({ imagePreviewUrl: "", raw: "" });
  const [message, setmessage] = useState("");
  const [role, setRole] = useState("");
  const [approved, setApproved] = useState("");

  const [modalShow2, setModalshow2] = useState(false);

  const token = localStorage.getItem("accesstoken");
  useEffect(() => {
    const Auth = localStorage.getItem("Auth");
    if (Auth == "true") {
      Api.get(`/users/my-profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        props.getProfile(res.data);
      });
      const res = props.profile;
      const abc = props.profile.emailVerified;
      // const op = props.profile && props.profile.roles[0] &&props.profile.roles[0].role
      const op = props.profile && props.profile.roles && props.profile.roles[0];
      
      setemail(res.email);
      setname(res.name);
      setmobile(res.mobile);
      setaboutus(res.about);
      setcity(res.city);
      setcountry(res.country);
      setstate(res.state);
      setProfile(res.profileImage);
      setaddress(res.address);
      setdob(moment(res.dob).format("YYYY-MM-DD"));
      setgender(res.gender);
      setzipcode(res.zipcode);
      setRole(op && op.role);
      setApproved(op && op.approved);
    }
  }, []);
  const Save = (event) => {
    // console.log(event,"cccccc")
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      setloader(true);
      let data = {
        about,
        address,
        email,
        name,
        mobile,
        gender,
        dob: moment(dob).toISOString(),
        zipcode: Number(zipcode),
        country,
        state,
        city,
      };
      //   console.log(data)

      Api.patch("/users/profile", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setloader(false);
          setEdit(false);
          Api.get(`/users/my-profile`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }).then((res) => {
            if (res.status === 200) {
              setemail(res.data.email);
              props.getProfile(res.data);
              setname(res.data.name);
              setmobile(res.data.mobile);
              setaboutus(res.data.about);
              setcity(res.data.city);
              setcountry(res.data.country);
              setstate(res.data.state);
              setProfile(res.data.profileImage);
              setaddress(res.data.address);
              setdob(moment(res.data.dob).format("YYYY-MM-DD"));
              setgender(res.data.gender);
              setzipcode(res.data.zipcode);
            }
          });
          if (res.status === 200) {
            const formData = new FormData();
            if (banner.raw) {
              formData.append("image", banner.raw);

              Api.post(`users/profile-image`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => {
                  setProfile(res.data.profileImage);

                  Api.get(`/users/my-profile`, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }).then((res) => {
                    props.getProfile(res.data);
                  });
                })
                .catch((err) => {});
            }
          } else {
            seterror("Try after Sometime");
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  const handleSelect = (evt) => {
    setcountry(countrylist[evt]);
    // what am I suppose to write in there to get the value?
    setnewlist([""]);
    //  console.log(countrylist[evt]);
  };
  const fileChange = (e) => {
    if (e.target.files.length) {
      setimagechange(true);
      setbanner({
        imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const getUserlist = (word) => {
    setcountry(word);

    setnewlist(countrylist);
    // console.log("word", word);
    const lowercasedValue = word.toLowerCase();

    const filteredData = countrylist.filter((el) =>
      el.toLowerCase().includes(lowercasedValue)
    );
    //   console.log("filteredData", filteredData);
    setnewlist(filteredData);
  };

  const joinAsOperator = () => {
    // console.log("in join")
    const token = localStorage.getItem("accesstoken");
    Api.post(
      `users/operator/join`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setmessage("Your request has been sent to admin for approval!");
        seterror("");
        setModalshow2(false);
        // console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
        setmessage("");
        seterror("Approval on your current request is already pending.");
        setModalshow2(false);
      });
  };

  const resendVerificationmail = () => {
    // console.log("in join")
    const accesstoken = localStorage.getItem("accesstoken");
    const Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
     setloader(true);
    Api.get(`auth/resendverificationemail`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => {
        //console.log(res ,"Resend verification")
        //console.log(res.data);
        setloader(false);
        setmessage(
          "verification email is sent successfully. Please check you email."
        );
        seterror("");
      })
      .catch((err) => {
        // console.log(err.response.data.message);
         setloader(false);
        setmessage("");
        seterror("Email already verified");
      });
  };

  return (
    <>
   <NavBar/>
    <div className="footer-con" style={{ paddingTop: "180px" }}>
      {loader && <Loader />}
    
        <section>
          <div
            //className="Card-style "
            style={{ position: "relative", maxWidth: "750px", margin: "auto" }}
          >
            
            <Row>
              <Col lg={12}>
                <div
                  className="profile-tb"
                  // style={{ tableLayout: "fixed" }}
                >
                 
                   
               <h2>{name}</h2>
                  {props.profile.emailVerified != true && (
                    <>
                     
                    <h2>Your Email is not verified. Kindly click the link sent to your email ID to verify your account.</h2>

                    <a
                     className="verification_mail"
                      onClick={() => resendVerificationmail()}
                    >
                      resend verification mail
                    </a>
                      </>
                  )}
                



                  {props.profile.emailVerified == true && (
                    <>
                    
                    <h2>Your Email is verified successfully.</h2>
                    </>
                   
                  )}


                    <Row className="text-center btnmargin" style={{display:'block'}}>
                    
             {props.profile.emailVerified == true && (
                    <Link to={{  pathname: "/"}} style={{textDecoration:'none'}}>
                <ButtonCommon              
                  label="Login"
                  className="all-events-btn text-center"
                  
                >
                </ButtonCommon>
                 </Link>
                 )}
              </Row>
                  {props.profile.emailVerified != true && (
                    <Label style={{ fontWeight: "700" }}>
                      Kindly verify your email to activate your account.
                    </Label>
                  )}
                  {message && (
                    <label style={{ color: "green" }}>{message}</label>
                  )}
                  {error && <label style={{ color: "red" }}>{error}</label>}
                </div>
               

           

         </Col>
         </Row>
            
          </div>
        </section>
     

      
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.homeReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: (data) => dispatch(getProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
