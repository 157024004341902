import React, { Component, useState, useEffect} from 'react';
import { Button ,Navbar,NavDropdown, Nav, Form
} from 'react-bootstrap';
import Api from "../Service/Api";
import moment from 'moment';

import {
  DropdownToggle, DropdownMenu,

  UncontrolledTooltip, UncontrolledButtonDropdown

} from 'reactstrap';
import logo from '../../static/Images/logo.png';
import { FaUserAlt,FaUserCircle} from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {useHistory} from 'react-router-dom';


const UserBar = () => {
    let history= useHistory()
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [validated, setValidated] = useState(false);
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState(new Date());
  const [error, seterror] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [country, setcountry] = useState("");
  const [about, setaboutus] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [profileImage, setProfile] = useState("");
  const [address, setaddress] = useState("");
  const [countrylist, setcountrylist] = useState(["India", "America"]);
  const [listnew, setnewlist] = useState([""]);
  const [edit, setEdit] = useState(false);
  const [loader, setloader] = useState(true);
  const [imagechange, setimagechange] = useState(false);
  const [banner, setbanner] = useState({ imagePreviewUrl: "", raw: "" });
  const [message,setmessage]=useState("");
  const token = localStorage.getItem("accesstoken");
  useEffect(() => {
   
    Api.get(`/users/my-profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setloader(false)
      if (res.status === 200) {
        setemail(res.data.email);
        setname(res.data.name);
        setmobile(res.data.mobile);
        setaboutus(res.data.about);
        setcity(res.data.city);
        setcountry(res.data.country);
        setstate(res.data.state);
        setProfile(res.data.profileImage)
        setaddress(res.data.address)
        setdob(moment(res.data.dob).format('YYYY-MM-DD'))
        setgender(res.data.gender)
        setzipcode(res.data.zipcode)
      }
      
    });
  }, []);
  const Save = (event) => {
   // console.log(event,"cccccc")
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      setloader(true)
      let data = {
        about,
        address,
        email,
        name,
        mobile,
        gender,
        dob:moment(dob).toISOString(),
        zipcode:Number(zipcode),
        country,
        state,
        city,
      };
   //   console.log(data)

      Api.patch("/users/profile", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setloader(false)
          setEdit(false)
          
          if (res.status === 200) {
            const formData = new FormData();
            if (banner.raw) {
              formData.append("image", banner.raw);
             
              Api.post(`users/profile-image`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => {
                  
                })
                .catch((err) => {
                 
                });
            }
          } else {
            seterror("Try after Sometime");
          }
        })
        .catch((err) => {
         // console.log(err.response);
        });
    }
  };

  const handleSelect = (evt) => {
    setcountry(countrylist[evt]);
    // what am I suppose to write in there to get the value?
    setnewlist([""]);
  //  console.log(countrylist[evt]);
  };
  const fileChange = (e) => {
    if (e.target.files.length) {
    setimagechange(true)
      setbanner({
        imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const getUserlist = (word) => {
    setcountry(word);

    setnewlist(countrylist);
   // console.log("word", word);
    const lowercasedValue = word.toLowerCase();

    const filteredData = countrylist.filter((el) =>
      el.toLowerCase().includes(lowercasedValue)
    );
 //   console.log("filteredData", filteredData);
    setnewlist(filteredData);
  };


const joinAsOperator=()=>{
 // console.log("in join")
  const token = localStorage.getItem("accesstoken");
  Api.post(`users/operator/join`, {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      setmessage("Your request has been sent to admin for approval!");
      seterror("");
     // console.log(res.data);
    })
    .catch((err) => {
     // console.log(err);
     setmessage("")
     seterror("Approval on your current request is already pending.")
    });
}
const auth = localStorage.getItem("Auth");
    return (
      <div>



        <>
        <Navbar className=""  expand="lg">
        <Navbar.Brand href="/">
            <img src={logo} alt="logo"  className="logo" />
          </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
    <Nav.Link className="login-btn" href="/">Home</Nav.Link>
    <Nav.Link className="login-btn" href="/allEvents">
    Adventures
              </Nav.Link>
              <Nav.Link className="login-btn" eventKey={2} href="/user-bookings">
                Bookings
              </Nav.Link>
              {/* <Nav.Link className="login-btn" eventKey={2} href="/wishlist">
                Wishlist
              </Nav.Link> */}
               <Nav.Link className="login-btn" href="/Aboutus">About</Nav.Link>
                 <Nav.Link className="login-btn" href="/contact-us">Contact</Nav.Link>
      
    </Nav>
    <Form inline className="navb-padding" style={{    justifyContent: 'center'}}>
    {!auth && (
            <div className="bookBtn-wrapper1">
            <Nav>
         
              <Link className="login-btn"
                to={{ pathname: "/login" }}
                style={{ textDecoration: "none" }}
              >
                <a >Login</a>
              </Link>
           
          
              <Link className="login-btn"
                to={{ pathname: "/signup" }}
                style={{ textDecoration: "none" }}
              >
                <a >SignUp</a>
              </Link>
           
              </Nav>
            </div>
          )}

    <Nav.Link>

       {auth && (             
<UncontrolledButtonDropdown>
  <DropdownToggle
    color="link"
    className="p-0"
    style={{ color: "#000" }}
  >
    <h1>
      {" "}
      {/* <FaUserCircle /> */}
      <img
                className="img_border_hover"
                style={{   width: '40px',
                height: '40px',
                marginTop: "10px",
                borderRadius: "50%",
                objectFit: "cover", }}
                src={profileImage&&profileImage.md}
               
              />
    </h1>
    {/* <img  src={logo} alt='logo' height='50px' className="user_profile_icon" /> */}
  </DropdownToggle>
  <DropdownMenu right className="dropdown-menu-lg">
    <Nav.Link eventKey={2} className="login-btn" >
      <Link
        to={{ pathname: "/user-account" }}
        style={{ textDecoration: "none" }}
      >
         Profile
      </Link>
    </Nav.Link>
    <Nav.Link eventKey={2} className="login-btn" 
    style={{cursor:'pointer'}}
    onClick={() => {
     localStorage.clear()
      history.push('/')
                 window.location.reload()
   }}
    >
     logout
    </Nav.Link>
  </DropdownMenu>
</UncontrolledButtonDropdown>
       )}
</Nav.Link>

      
       
      
    </Form>
  </Navbar.Collapse>
</Navbar>
        </>












      </div>
    );
  }


export default UserBar;
