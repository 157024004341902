import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Form,
  Modal,
  Dropdown,
  Table,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { Label, Input } from "reactstrap";
import Api from "../Service/Api";
import profile from "../../static/Images/profile.png";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import ButtonCommon from "../Components/ButtonCommon";
import Loader from "../Components/Loader";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { getProfile } from "../Redux/actions/common";

const Profile = (props) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [validated, setValidated] = useState(false);
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState(new Date());
  const [error, seterror] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [country, setcountry] = useState("");
  const [about, setaboutus] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [profileImage, setProfile] = useState("");
  const [address, setaddress] = useState("");
  const [countrylist, setcountrylist] = useState(["India", "America"]);
  const [listnew, setnewlist] = useState([""]);
  const [edit, setEdit] = useState(false);
  const [loader, setloader] = useState(false);
  const [imagechange, setimagechange] = useState(false);
  const [banner, setbanner] = useState({ imagePreviewUrl: "", raw: "" });
  const [message, setmessage] = useState("");
  const [role, setRole] = useState("");
  const [approved, setApproved] = useState("");

  const [modalShow2, setModalshow2] = useState(false);

  const token = localStorage.getItem("accesstoken");
  useEffect(() => {
    const Auth = localStorage.getItem("Auth");
    if (Auth == "true") {
      Api.get(`/users/my-profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        props.getProfile(res.data);
      });
      const res = props.profile;
      const abc = props.profile.emailVerified;
      // const op = props.profile && props.profile.roles[0] &&props.profile.roles[0].role
      const op = props.profile && props.profile.roles && props.profile.roles[0];
     
      setemail(res.email);
      setname(res.name);
      setmobile(res.mobile);
      setaboutus(res.about);
      setcity(res.city);
      setcountry(res.country);
      setstate(res.state);
      setProfile(res.profileImage);
      setaddress(res.address);
      setdob(moment(res.dob).format("YYYY-MM-DD"));
      setgender(res.gender);
      setzipcode(res.zipcode);
      setRole(op && op.role);
      setApproved(op && op.approved);
    }
  }, []);
  const Save = (event) => {
     const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      setloader(true);
      let data = {
        about,
        address,
        email,
        name,
        mobile,
        gender,
        dob: moment(dob).toISOString(),
        zipcode: Number(zipcode),
        country,
        state,
        city,
      };
 
      Api.patch("/users/profile", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setloader(false);
          setEdit(false);
          Api.get(`/users/my-profile`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }).then((res) => {
            if (res.status === 200) {
              setemail(res.data.email);
              props.getProfile(res.data);
              setname(res.data.name);
              setmobile(res.data.mobile);
              setaboutus(res.data.about);
              setcity(res.data.city);
              setcountry(res.data.country);
              setstate(res.data.state);
              setProfile(res.data.profileImage);
              setaddress(res.data.address);
              setdob(moment(res.data.dob).format("YYYY-MM-DD"));
              setgender(res.data.gender);
              setzipcode(res.data.zipcode);
            }
          });
          if (res.status === 200) {
            const formData = new FormData();
            if (banner.raw) {
              formData.append("image", banner.raw);

              Api.post(`users/profile-image`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => {
                  setProfile(res.data.profileImage);

                  Api.get(`/users/my-profile`, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }).then((res) => {
                    props.getProfile(res.data);
                  });
                })
                .catch((err) => {});
            }
          } else {
            seterror("Try after Sometime");
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  const handleSelect = (evt) => {
    setcountry(countrylist[evt]);
    // what am I suppose to write in there to get the value?
    setnewlist([""]);
    //  console.log(countrylist[evt]);
  };
  const fileChange = (e) => {
    if (e.target.files.length) {
      setimagechange(true);
      setbanner({
        imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const getUserlist = (word) => {
    setcountry(word);

    setnewlist(countrylist);
    // console.log("word", word);
    const lowercasedValue = word.toLowerCase();

    const filteredData = countrylist.filter((el) =>
      el.toLowerCase().includes(lowercasedValue)
    );
     setnewlist(filteredData);
  };

  const joinAsOperator = () => {
     const token = localStorage.getItem("accesstoken");
    Api.post(
      `users/operator/join`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setmessage("Your request has been sent to admin for approval!");
        seterror("");
        setModalshow2(false);
       })
      .catch((err) => {
        // console.log(err);
        setmessage("");
        seterror("Approval on your current request is already pending.");
        setModalshow2(false);
      });
  };

  const resendVerificationmail = () => {
     const accesstoken = localStorage.getItem("accesstoken");
    const Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
     setloader(true);
    Api.get(`auth/resendverificationemail`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => {
        setloader(false);
        setmessage(
          "verification email is sent successfully. Please check you email."
        );
        seterror("");
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        setmessage("");
        seterror("Email already verified");
      });
  };

  return (
    <div className="footer-con" style={{ paddingTop: "180px" }}>
      {loader && <Loader />}
      {!edit && (
        <section>
          <div
            className="Card-style "
            style={{ position: "relative", maxWidth: "750px", margin: "auto" }}
          >
            <img
              className="profileImg"
              style={{
                width: 150,
                height: 150,
                // marginTop: "20px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={profileImage && profileImage.md}
              // alt="profile"
            />
            <Row>
              <Col lg={12}>
                <div
                  className="profile-tb"
                  // style={{ tableLayout: "fixed" }}
                >
                  <h2>{name}</h2>

                  <h4>Email: {email} </h4>
                  {props.profile.emailVerified != true && (
                    <a
                      className="verify_email"
                      onClick={() => resendVerificationmail()}
                    >
                      resend verification mail
                    </a>
                  )}

                  <h4>Mobile: {mobile}</h4>
                  <h4>
                    Location: {city}
                    {", "}
                    {state} {", "}
                    {country}
                  </h4>
                  {approved == true && (
                    <h4 style={{ textTransform: "capitalize" }}>
                      Role: {role}
                    </h4>
                  )}

                  {/* <tr>
                    <th>About</th>
                    <td>{about}</td>
                  </tr> */}

                  <Link
                    to={{ pathname: "/change-password" }}
                    style={{ textDecoration: "none" }}
                  >
                    <a className="" href="/">
                      <div className="">Change Password</div>
                    </a>
                  </Link>
                  { (!(role == "operator") || (approved == false) )&& (
                      <Label
                        onClick={() => setModalshow2(true)}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          marginBottom: "1.5rem",
                          display: "block",
                        }}
                      >
                        Join as an Operator
                      </Label>
                    )}

                  {props.profile.emailVerified != true && (
                    <Label style={{ fontWeight: "700" }}>
                      Kindly verify your email to activate your account.
                    </Label>
                  )}
                  {message && (
                    <label style={{ color: "green" }}>{message}</label>
                  )}
                  {error && <label style={{ color: "red" }}>{error}</label>}
                </div>
                {/* <a>Log in as Operator</a> */}
              </Col>
              {/* <Col classsName="" 
            style={{ position: 'absolute',
            right: '160px',}} lg={2}>
              <FaUserEdit className="profile-tb-icon" style={{cursor:"pointer"}} onClick={() => {
                setEdit(true);
              }}/>
            </Col> */}
            </Row>

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow2}
              onHide={() => setModalshow2(false)}
            >
              <Modal.Body>
                <Row className="mb-4 text-center">
                  <Col>
                    <h2>Are you sure you want to join as operator?</h2>
                  </Col>
                </Row>
                <Row style={{ margin: "auto", textAlign: "center" }}>
                  <Col>
                    <ButtonCommon
                      handleClick={() => joinAsOperator()}
                      className=" btn_small  margin-userbooking"
                      label="Yes"
                    />

                    <ButtonCommon
                      className=" btn_small"
                      label="No"
                      handleClick={() => setModalshow2(false)}
                    />
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>

            <Row></Row>
            <span style={{ position: "absolute", right: "10px", top: "10px" }}>
              <FaUserEdit
                className="profile-tb-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEdit(true);
                }}
              />
            </span>
          </div>
        </section>
      )}

      {edit && !loader && (
        <section style={{ marginTop: "30px" }}>
          <div
            className="Card-style"
            style={{ maxWidth: "950px", margin: "auto", padding: "30px" }}
          >
            <h1>Your Account Details </h1>
            <Form noValidate validated={validated} onSubmit={Save}>
              <Row>
                <Col lg="12">
                  <Form.Row>
                    <Form.Group
                      controlId="validationCustom01"
                      className=" col-lg-6"
                    >
                      <Label for="exampleEmail">Name</Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Name"
                        className="inputField"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="validationCustom02"
                      className=" col-lg-6"
                    >
                      <Label for="exampleEmail">Email</Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Email"
                        className="inputField"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group
                      controlId="validationCustom03"
                      className="col-lg-6"
                    >
                      <Label for="exampleEmail">Mobile</Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Mobile"
                        className="inputField"
                        value={mobile}
                        onChange={(e) => setmobile(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="validationCustom04"
                      className="col-lg-6"
                    >
                      <Label for="exampleEmail">Gender</Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={gender}
                        onChange={(event) => setgender(event.target.value)}
                      >
                        <option value="">--Select--</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Input>
                      <Form.Control.Feedback type="invalid">
                        Please Select Travel style
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Col lg={4}>
                      <Form.Group controlId="validationCustom05">
                        {/* <Label for="exampleEmail">Date of Birth</Label> */}
                        <TextField
                          id="date"
                          label="Date of Birth"
                          type="date"
                          name="dob"
                          defaultValue="YYYY-MM-DD"
                          value={dob}
                          onChange={(event) => {
                            setdob(event.target.value);
                          }}
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {/* <DateTimePicker
                              className="form-control"
                              disableClock="true"
                              onChange={setdob}
                              value={dob}
                            /> */}
                        <Form.Control.Feedback type="invalid">
                          Please Enter Date of Birth
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row
                    as={Col}
                    controlId="validationCustom06"
                    className="profile_padding_text"
                  >
                    <Label for="exampleEmail">Address</Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Address"
                      className="inputField"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                    />
                  </Form.Row>

                  <Form.Row>
                    <Form.Group
                      controlId="validationCustom06"
                      className="col-lg-6"
                    >
                      <Label for="exampleEmail">City</Label>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        className="inputField"
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="validationCustom07"
                      className="col-lg-6"
                    >
                      <Label for="exampleEmail">State</Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="State"
                        className="inputField"
                        value={state}
                        onChange={(e) => setstate(e.target.value)}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group
                      controlId="validationCustom08"
                      className="col-lg-6"
                    >
                      <Label for="exampleEmail">Country</Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Country"
                        className="inputField"
                        value={country}
                        onChange={(e) => setcountry(e.target.value)}

                        // onChange={(e) => setcountry(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="validationCustom03"
                      className="col-lg-6"
                    >
                      <Label for="exampleEmail">Zipcode</Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Zipcode"
                        className="inputField"
                        value={zipcode}
                        onChange={(e) => setzipcode(e.target.value)}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group
                      controlId="formGroup"
                      className="profile_padding_text col-12"
                    >
                      <Label style={{ marginRight: "20px" }}>
                        Profile Image
                      </Label>

                      <input
                        //ref={fileInputRef}
                        accept="image/*"
                        type="file"
                        // hidden

                        onChange={fileChange}
                      />

                      {!imagechange && (
                        <img
                          className="profileImage_mobile"
                          src={profileImage && profileImage.md}
                          style={{
                            width: 100,
                            height: 100,

                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      {imagechange && (
                        <img
                          src={banner.imagePreviewUrl}
                          style={{
                            width: 100,
                            height: 100,

                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Form.Group>
                  </Form.Row>

                  <Row className="text-center">
                    <Col style={{ textAlign: "centre" }}>
                      <span className="mr_15">
                        <ButtonCommon
                          type="submit"
                          //handleClick={()=>Save()}
                          className="all-events-btn text-center btn_mobile "
                          label="Save"
                        />
                      </span>

                      <span
                        onClick={() => {
                          setEdit(false);
                        }}
                      >
                        <ButtonCommon
                          className="all-events-btn text-center btn_mobile"
                          label="Cancel"
                        />
                      </span>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                      }}
                       
                    >

                      <ButtonCommon
                       type="submit"
                      
                        className="all-events-btn text-center"
                        label="Save"
                      />
                    </Col>
                    <Col
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setEdit(false);
                      }}
                    >
                      <ButtonCommon
                        className="all-events-btn text-center"
                        label="Cancel"
                      />
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            </Form>
          </div>
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.homeReducer.profile,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: (data) => dispatch(getProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
