import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Accordion,
  Spinner,
} from "react-bootstrap";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import logo from "../../static/Images/logo.png";
import { FaUserAlt, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Oplogo from "../../static/Images/mojo.png";

import DestinationImage from "../../static/Images/destination1.jpg";
import Background from "../../static/Images/destination.jpg";
import BeautyStars from "beauty-stars";
import FD5 from "../../static/Images/FD5.jpg";
import FD2 from "../../static/Images/FD2.jpg";
import FD3 from "../../static/Images/FD3.jpg";

import FD4 from "../../static/Images/FD4.jpg";
import ButtonCommon from "./ButtonCommon";
import OperatorReviews from "./OperatorReviews";
import Awards from "./Awards";
import OperatorTours from "./OperatorTours";
import AskOperatorQuestions from "./AskOperatorQuestion";
import Api from "../Service/Api";
import NavBar from "./NavBar";
import Loaders from "./FullScreenLoader";

class OperatorDetails extends Component {
  constructor() {
    super();
    this.state = {
      operatorInfo: {},
      active: true,
      reviewslist: [],
      eventlist: [],
      loader: false,
      operatorquelist: [],
      faqscalled: false,
    };
  }
  componentDidMount() {
    this.setState({
      loader: true,
    });

    Api.get(
      `/operators/${this.props.props.match.params.id}/user?relations=["user"]`
    )
      .then((res) => {
        //  console.log('operatorInfo',res)
        this.setState({ operatorInfo: res.data, active: false, loader: false });
      })
      .catch((err) => {});
    Api.get(`/operators/${this.props.props.match.params.id}/reviews/average`)
      .then((res) => {
        //  console.log("ratings",res)
        this.setState({ ratings: res.data.rating });
      })
      .catch((err) => {});
    Api.get(`/operators/${this.props.props.match.params.id}/reviews/count`)
      .then((res) => {
        //  console.log("reviewscount",res)
        this.setState({ reviewscount: res.data.count });
      })
      .catch((err) => {});
    Api.get(
      `/operators/${this.props.props.match.params.id}/reviews?relations=["user"]&order={"updatedOn":"DESC"}`
    )
      .then((res) => {
        //   console.log(res)
        this.setState({ reviewslist: res.data });
      })
      .catch((err) => {});
    Api.get(
      `/operators/${this.props.props.match.params.id}/events?skip=0&limit=10`
    )
      .then((res) => {
        //  console.log(res,"PPPPPPPPPPPPPP")
        this.setState({ eventlist: res.data });
      })
      .catch((err) => {});

    this.getoperatorfaqs();
  }

  getoperatorfaqs = () => {
    if (!this.state.faqscalled)
      Api.get(`/operators/${this.props.props.match.params.id}/faqs`)
        .then((res) => {
          console.log("operatorque", res.data);
          this.setState({
            operatorquelist: res.data,
            faqscalled: true,
          });
        })
        .catch((err) => {});
  };
  render() {
    {
      console.log("detail props", this.props);
    }
    const {
      operatorInfo,
      active,
      ratings,
      reviewscount,
      reviewslist,
      eventlist,
      loader,
    } = this.state;
    const { list } =
      this.props && this.props.history && this.props.history.location;
    console.log("bannerlist", list);
    return (
      <div className="">
        {loader && <Loaders />}
        {!active && (
          <div>
            <div
              class="banner"
              style={{
                backgroundImage:
                  list && list.banner
                    ? `url(${list && list.banner})`
                    : `url(${Background})`,
                width: "100%",
                backgroundSize: "cover",
                height: "75vh",
                position: "relative",
                backgroundPosition: "50% 50%",
                // zIndex: 75,
              }}
            >
              <NavBar />
              <div
                className="eventopacity"
                style={{
                  backgroundImage: "none",
                  backgroundColor: "black",
                  height: "100%",
                  opacity: "0.5",
                }}
              >
                {" "}
              </div>
              <div class="text-center">
                <div class="circle-border"></div>
                <div class="circle-image">
                  <img
                    className="circle_image"
                    src={
                      operatorInfo &&
                      operatorInfo.user &&
                      operatorInfo.user.profileImage &&
                      operatorInfo.user.profileImage.md
                    }
                    // style={{ width: "50%", height: "50%", marginTop: "40px", borderRadius:'50%' }}
                  />
                </div>
              </div>
            </div>

            <section className="">
              <Container>
                <Card style={{ marginTop: "50px" }} className="Card-style">
                  <Card.Body>
                    <Row>
                      <Col lg={12}>
                        <div
                          className="profile-tb_operator"
                          // style={{ tableLayout: "fixed" }}
                        >
                          <h2>{operatorInfo.user.name}</h2>
                          {(operatorInfo.user.city &&
                            operatorInfo.user.country) != null && (
                            <h4>
                              {operatorInfo.user.city},{" "}
                              {operatorInfo.user.country}{" "}
                            </h4>
                          )}

                          <h4>
                            <FaStar
                              style={{
                                color: "rgb(253, 215, 42)",
                                fontSize: "1rem",
                              }}
                            />{" "}
                            {ratings}/5
                          </h4>

                          <h4>Reviews ({reviewscount}) </h4>
                        </div>
                      </Col>
                    </Row>

                    {/*                 
                <Card.Title>
                  <h1>{operatorInfo.user.name}</h1>
                </Card.Title>
                <Card.Text>{operatorInfo.user.city}, {operatorInfo.user.country}</Card.Text>
                <Row>
                  


            <Col  lg={6}>
                    <Row>
                      <Col xs={8} sm={6} md={6}>Rating</Col>
                  
                        <Row>
                          <span style={{ marginTop: "3px" }}>
                            <BeautyStars
                             
                              maxStars="1"
                              size="16px"
                              inactiveColor="#f4cc18"
                              activeColor="#f4cc18"
                             
                            />
                          </span>
          <span>{ratings}/5</span>
                        </Row>
                    </Row>
                  </Col>


                  <Col  lg={6}>
                    <Row>
                      <Col xs={10} sm={7} md={6} >Number of Reviews</Col>
                        <Row>
                          <span>{reviewscount}</span>
                        </Row>
                    </Row>
                  </Col>


                </Row> */}

                    <Row style={{ marginTop: "15px" }}>
                      <Col lg={10}>
                        <h2>About</h2>
                        <p>{operatorInfo.user.about}</p>
                      </Col>
                      <Col lg={2}>
                        <AskOperatorQuestions
                          opid={this.props.props.match.params.id}
                        />
                      </Col>
                    </Row>
                    {/* <Row>
            <Col lg={9} style={{paddingLeft:'0px'}}>
              <p style={{paddingLeft:'20px'}}>
                {operatorInfo.user.about}
              
              </p>
              </Col>
             
            </Row> */}
                    {/* <AskOperatorQuestions opid={this.props.props.match.params.id}/> */}
                  </Card.Body>
                </Card>
                <Row>
                  <Col lg={12}>
                    <OperatorReviews reviewslist={reviewslist} />
                  </Col>
                </Row>
              
                {eventlist&&eventlist.length!=0&&(
                <Row>
                  <OperatorTours
                    history={this.props.history}
                    eventlist={eventlist}
                  />
                </Row>
                )}
                {this.state.operatorquelist.length!=0&&(
                <Row style={{ marginTop: "15px" }}>
                  <Col lg={12}>
                    <Accordion
                      // defaultActiveKey="0"
                      className="h2-text-event-details"
                    >
                      <Card className="toogle-card">
                        <h2 className="reviewTitle">FAQ</h2>
                        {this.state.operatorquelist &&
                          this.state.operatorquelist.map((item, index) => (
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey={index + 1}
                            >
                              <Row>
                                <Col lg={11}>
                                  <h2>{item && item.question}</h2>
                                  <Accordion.Collapse eventKey={index + 1}>
                                    <>
                                      <Card.Body
                                        style={{
                                          paddingTop: "10px",
                                          paddingBottom: "15px",
                                        }}
                                      >
                                        <p style={{ marginBottom: "0px" }}>
                                          {item && item.answer}
                                        </p>
                                      </Card.Body>
                                    </>
                                  </Accordion.Collapse>
                                </Col>
                                {/* <div className="chevronn">
                                  {down7 && (
                                    <FaChevronDown
                                      className="chevron faq"
                                      onClick={() => {
                                        this.setState({ up7: true });
                                        this.setState({ down7: false });
                                      }}
                                    />
                                  )}
                                  {up7 && (
                                    <FaChevronUp
                                      className="chevron faq"
                                      onClick={() => {
                                        this.setState({ up7: false });
                                        this.setState({ down7: true });
                                      }}
                                    />
                                  )}
                                </div> */}
                              </Row>
                            </Accordion.Toggle>
                          ))}
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
)}
                {/* <Row>
              <Awards />
            </Row> */}
              </Container>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default OperatorDetails;
