import React, { useState, useEffect, Component } from "react";

import { Container, Row, Col, Modal } from "react-bootstrap";

import logo from "../../static/Images/logo.png";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Help from "../Components/FooterContent/Help";
import Cookie from "../Components/FooterContent/Cookie";
import TermsAndCondition from "../Components/FooterContent/TermsAndCondition";
import Copyright from "../Components/FooterContent/Copyright";
import Contactus from "../Components/FooterContent/Contactus";
import Privacy from "../Components/FooterContent/Privacy";
import Aboutus from "../Components/FooterContent/Aboutus";
import OperatorHelp from "../Components/FooterContent/OperatorHelp";
import IAmAGuide from "../Components/FooterContent/IAmAGuide";
import WhyBookMyTrek from "./FooterContent/WhyBookMyTrek";
import Api from "../Service/Api";
import ButtonCommon from "./ButtonCommon";
import { useHistory } from "react-router-dom";
import Loaders from "./FullScreenLoader";
import { connect } from "react-redux";
import Loader from "./Loader";

// class Footer extends Component {
const Footer = (props) => {
  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");

  let history = useHistory();
  const [error, seterror] = useState("");
  const [error2, seterror2] = useState("");

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [active, setactive] = useState(false);
  const [edit, setEdit] = useState(false);
  const token = localStorage.getItem("accesstoken");
  const [verified, setVerified] = useState("");
  const [modalShow2, setModalshow2] = useState(false);

  const onSendmsg = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true && validateEmail(email)) {
      setLoader(true);
      let data = {
        email: email,
      };
      event.preventDefault();

      Api.post("/misc/emailsubscription", data, {})
        .then((res) => {
          setLoader(false);
          if (res.status === 201) {
            seterror("");
            setShow(true);
            setLoader(false);

            setMessage("Submitted Successfully");
          }
        })
        .catch((err) => {
          event.preventDefault();
          seterror("Email already subscribed");
          setLoader(false);
          //  seterror(err.response.data.message)
          console.log("err.response.message", err.response);
        });
    } else {
      seterror("Invalid email id ");
    }
  };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(() => {
    const Auth = localStorage.getItem("Auth");
    if (Auth == "true") {
      const res = props.profile;
    }
  }, []);

  const joinAsOperator = () => {
    // console.log("in join")
    setLoader(true);
    const token = localStorage.getItem("accesstoken");
    Api.post(
      `users/operator/join`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setMessage2("Your request has been sent to admin for approval!");
        seterror2("");
        //setModalshow2(false);
        setLoader(false);
     
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setLoader(false);
        setMessage2("");
        if (err.response.data.message == "Already have a operator role.") {
          setMessage2("Already joined as Operator");
        }
        if (
          err.response.data.message ==
          "Approval on your current request is already pending."
        ) {
          seterror2("Approval on your current request is already pending.");
        }
        //seterror2("Approval on your current request is already pending.");
        //setModalshow2(false);
      });
  };

  const auth = localStorage.getItem("Auth");

  // render() {
  return (
    <>
      <div className="container">
        <Row>
          <Col lg={12}>
            <section className="newsletter">
              <Row>
                <Col lg={5}>
                  <div className="newsletter-text">
                    <h1>Subscribe for newsletter</h1>
                  </div>
                </Col>
                <Col lg={7}>
                  <form className="email-subscribe">
                    <input
                      type="email"
                      name="email"
                      placeholder="something@example.com"
                      className="input"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    {/* <input type='submit' value='Submit' className='submit' /> */}
                    <ButtonCommon
                      value="Submit"
                      className="submit"
                      label="Submit"
                      handleClick={onSendmsg}
                      // handleClick={() => {
                      //   onSendmsg();
                      //   validateEmail(email);

                      // }}
                    />
                  </form>

                  {loader && <Loaders />}
                  {message && <p className="subscribe-message">{message}</p>}
                  {error && (
                    <p className="subscribe-message" style={{ color: "red" }}>
                      {error}
                    </p>
                  )}
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </div>
      <div
        style={{
          backgroundColor: "#fcac50",
          width: "100%",
          zIndex: "1",
          position: "relative",
        }}
      >
        <div className="container">
          <footer>
            <div className="copyright">
              <h3 className="footer-heading">BookMyTrek</h3>
              <ul className="footer-list">
                <li>Online Marketplace for Adventure Activities</li>
              </ul>
              <div className="copyright-logo">
                {/* <img src={logo} alt='logo' height='50px' />*/}
                {/* <ul className='footer-list'>
        <li>
          Copyright 2020 <br /> BookMyTrek
          </li>
          </ul>*/}
              </div>
            </div>

            <div className="navigation">
              <h3 className="footer-heading">About BookMyTrek</h3>
              <ul className="footer-list">
                <Link
                  to={{ pathname: "/Aboutus" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>About Us</li>
                </Link>
                {/* <Link to={{  pathname: "/"}} style={{textDecoration:'none'}}>
            <li>
              <a >Customer Reviews</a>
            </li>
            </Link> */}

                <Link
                  to={{ pathname: "/footer-Terms-and-condition" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>Terms And Conditions</li>
                </Link>
                <Link
                  to={{ pathname: "/contact-us" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>Contact Us</li>
                </Link>
                {/*
            <Link to={{  pathname: "/footer-Help"}} style={{textDecoration:'none'}}>
            <li >
              Help
            </li>
            </Link>
            */}
                <Link
                  to={{ pathname: "/footer-Privacy" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>Privacy</li>
                </Link>
                <Link
                  to={{ pathname: "/footer-Cookie" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>Cookie Policy</li>
                </Link>
                <Link
                  to={{ pathname: "/footer-Copyright" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>Copyright</li>
                </Link>

                <Link
                  to={{ pathname: "/covid-policy" }}
                  style={{ textDecoration: "none" }}
                >
                  <li className="mobile_covidpolicy">Covid Policy</li>
                </Link>
              </ul>
            </div>

            <div className="resources">
              <h3 className="footer-heading">Operator</h3>
              <ul className="footer-list">
                {auth && (
                  <Link
                    onClick={() => setModalshow2(true)}
                    //to={{  pathname: "/profile"}}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      {/* <a>Sign Up as Operator</a> */}
                      Sign Up as Operator
                    </li>
                  </Link>
                )}

                {!auth && (
                  <Link
                    to={{ pathname: "/operator-register" }}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      {/* <a>Sign Up as Operator</a> */}
                      Sign Up as Operator
                    </li>
                  </Link>
                )}
                <a
                  href="https://operator.bookmytrek.com/#/signin"
                  style={{ textDecoration: "none" }}
                >
                  <li>
                    {/* <a>Sign Up as Operator</a> */}
                    Sign In as Operator
                  </li>
                </a>
                {/* 
            <Link to={{  pathname: "/footer-List-your-tour"}} style={{textDecoration:'none'}}>
            <li >
              List Your tour
            </li>
            </Link>
           <Link to={{  pathname: "/footer-i-am-a-guide"}} style={{textDecoration:'none'}}>
            <li >
              I am a Guide
            </li>
            </Link>
            <Link to={{  pathname: "/footer-operator-help"}} style={{textDecoration:'none'}}>
            <li >
              Help
            </li>
            </Link>
            */}
              </ul>
            </div>

            <div className="resources">
              <h3 className="footer-heading">Travellers</h3>
              <ul className="footer-list">
                <Link
                  to={{ pathname: "/Aboutus" }}
                  style={{ textDecoration: "none" }}
                >
                  <li>Why BookMyTrek</li>
                </Link>
                {/*
            <Link to={{  pathname: "/footer-win-a-tour"}} style={{textDecoration:'none'}}>
            <li >
              Win a Tour
            </li>
            </Link>
            <Link to={{  pathname: "/footer-write-your-review"}} style={{textDecoration:'none'}}>
            <li>
              write Your Tour Review
            </li>
            </Link>
            */}
              </ul>
            </div>
            <div className="connect">
              <h3 className="footer-heading">Connect</h3>
              <ul className="footer-list">
                <li>
                  <a href="https://www.instagram.com/bookmytrek/">Instagram</a>
                </li>
                <li>
                  <a href="https://twitter.com/letsbookmytrek">Twitter</a>
                </li>
                <li>
                  <a href="https://www.facebook.com/BookMyTrekApp">Facebook</a>
                </li>
              </ul>
            </div>

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow2}
              onHide={() => setModalshow2(false)}
            >
              <a
                style={{
                  fontSize: "25px",
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onClick={() => setModalshow2(false)}
              >
                x
              </a>
              <Modal.Body style={{ marginTop: "30px" }}>
                <Row className="mb-4 text-center">
                  <Col>
                    <h2>Are you sure you want to join as operator?</h2>
                  </Col>
                </Row>
                <Row style={{ margin: "auto", textAlign: "center" }}>
                  <Col>
                    <ButtonCommon
                      handleClick={() => joinAsOperator()}
                      className=" btn_small  margin-userbooking"
                      label="Yes"
                    />

                    <ButtonCommon
                      className=" btn_small"
                      label="No"
                      handleClick={() => setModalshow2(false)}
                    />
                  </Col>
                </Row>
                {loader && <Loader />}
                <Row style={{ margin: "auto", textAlign: "center" }}>
                  <Col>
                    {message2 && (
                      <label style={{ color: "green" }}>{message2}</label>
                    )}
                    {error2 && <label style={{ color: "red" }}>{error2}</label>}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </footer>
          <Row>
            <Col>
              <p style={{ textAlign: "center" }}>
                {" "}
                © Copyright 2020 BookMyTrek
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {/* <..........New Footer...........> */}
    </>
  );
};
// }

//export default Footer;

const mapStateToProps = (state) => ({
  profile: state.homeReducer.profile,
});

export default connect(mapStateToProps, null)(Footer);
