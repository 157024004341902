
import React, { Component } from "react";
import Api from "../Service/Api";
import AdventureEvents from "../Components/AdventureEvents";


class SimilarAdventures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      category: "",
      similarlist: [],
    };
  }
  
  componentDidMount() {
    this.geteventlist();
  }

  geteventlist = () => {
    Api.get(`/events/all?limit=10&category=${this.props.category}`)
      .then((res) => {
      
        this.setState({ similarlist: res.data });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div>
        <h2>Similar Events</h2>
        <AdventureEvents
          history={this.props.history}
          list={this.state.similarlist}
        />
      </div>
    );
  }
}
export default SimilarAdventures;
