import React, { Component } from 'react';
import { Button ,
} from 'react-bootstrap';

import {
  DropdownToggle, DropdownMenu,
  Nav,  NavItem, NavLink,
  UncontrolledTooltip, UncontrolledButtonDropdown

} from 'reactstrap';
import logo from '../../static/Images/logo.png';
import { FaUserAlt} from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



class PaymentTerms extends Component {
  render() {
    return (
        <div className="">
            <div>
            for Travellers and Members

Last Updated: 23/05/2019

<h1 className="event-font">Introduction and Scope of Services</h1>
BookMyTrek GmbH and its subsidiaries (together "BookMyTrek") provide an online platform with technology for Travellers, Members and Operators to meet online, enter contests, write reviews, search, compare and book tours, cruises and other types of activities ("Tours"). Tours are booked directly from the respective independent tour operator offering the Tour (each an “Operator”). The “Travellers” are travellers interested in booking a Tour. The “Members” are persons who completed BookMyTrek's account registration process. Travellers and Members are jointly referred to as “you”.

The “Services” comprise listings for Tours independently created by Operators in their own discretion and responsibility (each a “Listing”) as well as Content (as defined below) which are accessible at www.tourradar.com including its subpages (collectively, the "Site") and any related applications (the "Application"). 

BookMyTrek is solely acting as a commercial agent for the Operators. BookMyTrek is not an operator of Tours, nor is it a provider of Tours, nor a representative or proxy of an Operator nor an insurer. BookMyTrek does not own, sell, resell, furnish, provide, manage or control any transportation, tour or travel services and does not act as an agent to the Travellers. BookMyTrek's responsibilities are limited to exclusively facilitating the availability of the Services and serving as the commercial agent of each Operator. 

BookMyTrek has no control over the conduct of Operators, Travellers, MEMBERS and other users of the Services. TOURRADAR CANNOT AND DOES NOT CONTROL THE CONTENT CONTAINED IN ANY LISTINGS OR THE CONDITION, LEGALITY OR SUITABILITY OF ANY TOURS. TOURRADAR IS NEITHER RESPONSIBLE NOR LIABLE FOR TO ANY LISTINGS AND TOURS. BOOKINGS WITH THE OPERATORS ARE MADE AT THE TRAVELLER'S OWN RISK.

<h1 className="event-font">Applicability</h1>
These terms and conditions ("Terms"), as amended from time to time, apply to all BookMyTrek services. By accessing, browsing or using the Site, Application and Services through whatever platform, you agree to comply with and be legally bound by these terms Irrespective of a registration or certain use or way of access. These Terms govern your access to and use of the Site, Application and Services and all Content (as defined below) vis-à-vis BookMyTrek and, where applicable, the Operators. Please carefully read these Terms and also the Privacy Policy and the Legal Notice, each of which is incorporated by reference into these Terms. If you do not fully agree to these Terms, you have no right to obtain information from or otherwise continue using the Site, Application and Services. If you act on behalf of a company or other legal entity, you guarantee that you have the authority to act on behalf and to bind that company or other legal entity to these Terms.

<h1 className="event-font">Eligibility</h1>
The Services are intended solely for persons who are aged 18 years or older. Any access to or use of the Services by anyone under 18 years is expressly prohibited. By accessing or using the Services you guarantee that you are 18 years or older.

<h1 className="event-font">Privacy</h1>
BookMyTrek's Privacy Policy applies. Please see them for information and notices concerning BookMyTrek's collection and use of your personal information.

<h1 className="event-font">Bookings and Payments</h1>

Bookings by Travellers with Operators
The respective Operator only, and not BookMyTrek, is solely responsible to the respective Traveller for honouring any confirmed bookings and making available any Tours reserved through the Services. The Operator is also responsible to provide all information legally required to the Traveller. If you, as a Traveller, choose to enter into a transaction with an Operator for the booking of a Tour, you agree and understand that you will be required to enter into an agreement (including releases) with the Operator only and you agree to accept any terms, conditions, rules and restrictions associated with such Tour imposed by the Operator and will receive necessary information from the Operator only. You acknowledge and agree that BookMyTrek is no party to the agreement but only acts as the commercial agent to the Operator and BookMyTrek accepts no liability arising from or related to any of your agreements with the Operator unless BookMyTrek is legally obliged to do so or to fulfil certain information and security obligations towards the Traveller. 

The Operators are requested to either confirm or reject a booking within the specified timeframe (which is generally within 24 to 48 hours of when the booking is requested, as determined by BookMyTrek in its sole discretion) or the requested booking will be automatically cancelled. 

Once your confirmed booking transaction is complete (including payment) you will receive a confirmation email summarizing your confirmed booking.

<h1 className="event-font">Payments by Travellers</h1>
You agree to pay for the “Total Cost” (including the price of the tour and, where applicable, taxes, levies and duties) for any booking requested, in accordance with the pricing terms set forth in the applicable booking page, if such requested bookings are confirmed by the applicable Operator. The availability and price of a Tour can change any time until you have received a booking confirmation.

In case only the deposit is payable at the time of booking or booking confirmation, the balance of payment (i.e., the Total Cost minus the deposit) is due and will be payable as stated in the Operator’s terms according to the booking page.

BookMyTrek in its own discretion collects (through third party payment processors) the Total Cost (as one payment or deposit plus balance payments) for and on behalf of the Operator as its commercial agent. You hereby authorize the collection of such amounts by charging the credit/debit card provided as part of requesting the booking, or by one of the other payment methods as described on the Site or Application. 

<h1 className="event-font">Traveller Cancellations and Refunds</h1>
If: 
- the applicable Operator does not confirm the requested booking, or, 
- as a Traveller, you cancel your requested booking before the requested booking is confirmed by an Operator, 
any amounts collected by BookMyTrek or its third party payment processor will be refunded to the Traveller within a commercially reasonable time, depending on the selections the Traveller made via the Site and Application, and any pre-authorization of such Traveller's credit/debit card will be released.

Once your booking is confirmed by the applicable Operator, the Total Cost of your Tour is subject to the cancellation and payment terms of the Operator. Please carefully read the fine print and important information in the Listing, the booking page and your reservation confirmation for policies as applied by the Operator. A cancellation must be done via your booking conversation page. 

If you are advised that your booking is on an 'on request' basis (which is typically the case for tours booked shortly prior to departure), the Total Cost of your Tour is subject to the cancellation and payment terms of the Operator from the moment when you have submitted the booking request. When a tour is 'on request' the Operator needs several business days to ensure that all of the components of the tour package can be confirmed for you. This process may not be interrupted by a request to cancel. So, if the request comes back approved you will be charged for the full amount, and if, as a Traveller, you cancel such a booking, this does not entitle you to any refund. If the Operator cannot confirm your request, you will receive a full refund. 
Operator Cancellations and Refunds
If an Operator cancels a confirmed booking made via the Services, a refund of the paid amount for such booking will be made to the applicable Traveller within a commercially reasonable time of the cancellation by the Operator and the Traveller may receive an email or other communication from BookMyTrek containing non-binding suggestions for alternative Listings and other related information. If an Operator cancelled a confirmed booking and you, as a Traveller, have not received an email or other communication from BookMyTrek, please contact BookMyTrek at info@tourradar.com.

<h1 className="event-font">BookMyTrek Rewards Programs</h1>

<h1 className="event-font">Friends With Benefits </h1>
If applicable, BookMyTrek's Friends with Benefits program allows you as a Traveller to earn BookMyTrek travel credits for friends who are not currently BookMyTrek members and who have never booked a tour with BookMyTrek (the “New User”) you refer that make a qualified booking with BookMyTrek. Members of TourRadar’s affiliate program may not simultaneously collect travel credits via your invite link. To accumulate and redeem travel credits you must maintain a BookMyTrek account in good standing. To earn travel credits, refer New Users by sending them your personal invite link, available in your member's area on the Site. You may also share your unique invite link via email and social media to friends and family. You may not share your personal link on public forums or coupon sites. If you do this, it is a violation of this Agreement and BookMyTrek may terminate your account. You will then forfeit all credits in your account. BookMyTrek reserves the right to cancel, revoke, or otherwise prevent the issuance of travel credits in any suspected case of fraudulence or misuse of this program and does not need to provide any details or explanation.

If the New User clicks your unique invite link (or enters your invite code) and books directly with BookMyTrek using your indicated discount (unless otherwise stated 5 %), BookMyTrek will deposit the indicated amount (unless otherwise stated 5 %) of your friends' tour value on the day your friend books the tour, to your BookMyTrek Account. You must be signed in to your BookMyTrek account to use your travel credits. These credits can be used to book any tour through BookMyTrek. Travel credits are non-refundable. Upon cancellation of a tour by the Traveller, any credits used for the booking will not be refunded.

BookMyTrek reserves the right at any time to modify or discontinue the Friends With Benefits program either temporarily or permanently (or any part thereof) with or without notice. BookMyTrek will not be liable to you in the event of any modification, suspension or discontinuance of this program. Travel credits are not redeemable for cash or equivalents. BookMyTrek's promo codes and travel credits cannot be applied on existing confirmed bookings.

<h1 className="event-font">Other Travel Credits </h1>
BookMyTrek may in its sole discretion provide travel credits as part of TourRadar’s rewards program. These travel credits will be based on the tour you book and they may be deposited into your BookMyTrek account. These credits can be used on the same basis as outlined above for the Friends with Benefits program.

<h1 className="event-font">Promo Codes </h1>
BookMyTrek may in its sole discretion offer promo codes from time to time and unless otherwise stated they have an expiry period of 30 days from implementation of the offer. Promo codes can only be used for bookings for tours and on the same basis as outlined above for the Friends with Benefits program. Only one promo code can be redeemed per booking and they may not be combined with travel credits. 

<h1 className="event-font">Content</h1>
BookMyTrek may, in its sole discretion, permit you to post, upload, publish, submit or transmit text, graphics, images, video or other materials (“Content”). By making available any Content on or through the Services, you agree that BookMyTrek may use the Content as BookMyTrek at its discretion sees fit in particular on its (mobile) website and app, and in (online/offline) promotional materials and publications. You hereby grant to BookMyTrek a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such Content on, through, or by means of the Services. BookMyTrek does not claim any ownership rights in any such Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit any such Content.

You acknowledge and agree that you are solely responsible for all Content that you make available through the Services. Accordingly, you represent and warrant that: (a) you either are the sole and exclusive owner of all Content that you make available through the Services or you have all rights, licenses, consents and releases that are necessary to grant to BookMyTrek the rights in such Content, as contemplated under these Terms; and (b) neither the Content nor your posting, uploading, publication, submission or transmittal of the Content or BookMyTrek's use of the Content (or any portion thereof) on, through or by means of the Services will infringe, misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation. The person who uploaded the Content warrants that the Content shall not contain any viruses, Trojan horses or infected files. You must not store illegal content on the Site and BookMyTrek will monitor the site regularly for such illegal content. Please be aware that if you upload illegal content you could be subject to a fine. Any Content that does not meet the aforesaid criteria will not be posted and can be removed at any time and without prior notice.

Also BookMyTrek may, in its discretion and without liability to you, with or without cause, with or without prior notice and at any time: (a) terminate your access to the Services, and (b) deactivate or terminate your BookMyTrek account. Upon termination BookMyTrek will within a commercially reasonable time pay you any amounts which BookMyTrek is legally obliged to pay you. BookMyTrek does not have an obligation to delete or return to you any Content you have posted to the Services, including, but not limited to, any reviews or feedback.

<h1 className="event-font">Disclaimer</h1>
If you choose to use the Services, you do so at your sole risk. BookMyTrek does not have an obligation to conduct checks on any Travellers, Operators, Members or other users of the Services or third parties and does not make any attempt to verify Listings or Content. The Services (including the Site and Application), Listings and Content are provided "as is", without warranty of any kind. In particular, BookMyTrek makes no warranty regarding the quality of any Listings, Tours, the Services or Content or the accuracy, timeliness, truthfulness, completeness or reliability of any content obtained through the Services or advice or information, whether oral or written, obtained from BookMyTrek.

Without limiting the foregoing, BookMyTrek is not responsible and disclaims any liability for the use, validity, quality, suitability, fitness and due disclosure of the Listings and the Tours and makes no representations, warranties or conditions of any kind in this respect, whether implied, statutory or otherwise, including any implied warranties of merchantability, title, non-infringement or fitness for a particular purpose or be available on an uninterrupted, secure, or error-free basis. You acknowledge and agree that the relevant Operator is solely responsible and assumes all responsibility and liability in respect of the Listings and the Tour. BookMyTrek is not a (re)seller of the Tour. Complaints or claims in respect of the Tour are to be dealt with by the Operator. BookMyTrek is not responsible for and disclaims any liability in respect of such complaints, claims and (product) liabilities. BookMyTrek explicitly disclaims all liability for any act or omission of any Traveller or other third party.

You acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the Services and Content, your booking of any Tours via the Services, and any contact you have with other users whether in person or online remains with you. 

BookMyTrek shall only be liable for direct damages actually suffered, paid or incurred by you due to an attributable shortcoming of TourRadar’s obligations in respect to the Services, up to the maximum amounts specified herein. Neither BookMyTrek nor any others involved in creating, producing, delivering or otherwise making available the Services or the Content will be liable for (a) any punitive, special, indirect or consequential loss or damages, including loss of profit, loss of or damage to goodwill or reputation, loss of claim, (b) any inaccuracy relating to the (descriptive) information of the Operator as made available, (c) the services rendered or the products offered by the Operators or other business partners, (d) any direct, indirect, consequential or punitive damages, losses or costs suffered, incurred or paid by you, pursuant to, arising out of or in connection with the use, inability to use or delay of the Services, or (e) any (personal) injury, death, property damage, or other (direct, indirect, special, consequential or punitive) damages, losses or costs suffered, incurred or paid by you, whether due to (legal) acts, errors, breaches, (gross) negligence, wilful misconduct, omissions, non-performance, misrepresentations, tort or strict liability by or (wholly or partly) attributable to the Operator or from any communications, interactions or meetings with other users of the Services or other persons with whom you communicate or interact as a result of your use of the Services.

In no event will BookMyTrek's aggregate liability arising out of or in connection with your use of or inability to use the Services or from any interactions with any other members, exceed the amounts you have paid or owe for bookings via the Services as a Traveller in the twelve (12) month period prior to the event giving rise to the liability, or one hundred US Dollars (USD 100), if no such payments have been made, as applicable. The limitations of damages set forth above are fundamental elements of the basis of the bargain between BookMyTrek and you.

BookMyTrek is committed to comply with sanctions imposed by the US Office of Foreign Assets Control (OFAC), the United Nations Act, the Regulations Establishing a List of Entities under the Criminal Code of Canada, the Special Economic Measures Act, Freezing Assets of Corrupt Foreign Officials Act, Proceeds of Crime (Money Laundering) and Terrorist Financing Act, Justice for Victims of Corrupt Foreign Officials Act and other rules and regulations which impose restrictions on travel companies, as applicable from time to time (jointly referred to as “Sanctions”).
You are not permitted to use the Services if you are an entity incorporated in or resident in a country subject to economic or trade sanctions imposed by the EU, Canada, or by the U.S. State Department or U.S. Treasury Department’s Office of Foreign Assets Control (OFAC) or any other competent body and authority or if you are listed as a “Specially Designated National,” a “Specially Designated Global Terrorist,” a “Blocked Person,” or similar designation under the OFAC or other sanctions regime (“Restricted Person”).
BookMyTrek reserves the right to cancel any booking made that is contrary to EU, UN, OFAC and other policies and/or applicable sanctions. BookMyTrek will not directly or indirectly use any funds, or lend, contribute or otherwise make available funds to any person or entity, for the purpose of financing the activities of any company, country and/or persons currently subject to any sanctions as administered by competent bodies.

Applicable Law, Jurisdiction and Dispute Resolution
These Terms and the provision of the Services shall be governed by and construed in accordance with Austrian law with the exclusion of it conflict-of-law rules. Notwithstanding the foregoing choice of law, a natural person using any of the Services for a purpose which can be regarded as being outside his trade or profession (a "Consumer") can rely on the mandatory provisions of the law of the country where he has his habitual residence.

Any dispute arising out of these Terms and the Services shall exclusively be submitted to the competent courts in Vienna Inner City, Austria. Notwithstanding the foregoing jurisdiction clause, a Consumer may also bring proceedings in the courts of the country in which he is domiciled, and proceedings against a Consumer may be brought only in the courts of the country in which he is domiciled.

For Consumers (in the European Economic Area): BookMyTrek advises you to first notify BookMyTrek of any complaints by contacting TourRadar’s Customer Service. If this does not resolve your complaint, you can upload your complaint via the European Commission's ODR platform. This platform for online dispute resolution can be found here: http://ec.europa.eu/odr.

<h1 className="event-font">Severability</h1>
If for any reason an arbitrator or a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect, and you will at least agree to accept a similar effect as the invalid, unenforceable or non-binding provision, given the contents and purpose of these Terms.

The commercial relationships that BookMyTrek has with the Operators are governed by separate agreements. Operators may have, declare applicable or require acceptance of their own terms and conditions for the use, access and consummation of the Tour (which may include disclaimers and limitations of liability).

<h1 className="event-font">Modifications</h1>
BookMyTrek reserves the right to modify the Terms at any time without prior notice. Any changes will become effective immediately upon use of the Site, Application and Services post change constituting an acceptance of the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using the Site, Application and Services.



  
          </div>
        </div>

    
    );
  }
}

export default PaymentTerms;
