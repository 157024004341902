import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col,Card} from "react-bootstrap";

import NavBar from "../Components/NavBar";
import SearchBox from "../Components/SearchBox";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DestinationCard from "../Components/DestinationCard";
import TopOperators from "../Components/TopOperators";
import AdventureCard from "../Components/AdventureCard";
import AdventureEvents from "../Components/AdventureEvents";
import FeaturedEvents from "../Components/FeaturedEvents";
import BeautyStars from "beauty-stars";
import Footer from "../Components/Footer";
import Api from "../Service/Api";
import ReviewCard from "../Components/Reviewcard";
import Loaders from "../Components/FullScreenLoader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Background from "../../static/Images/register.jpg"
class Destinations extends Component {
    constructor(){
    super()
  this.state = {
    list: [],
   prevY:10,
  skip:0,
count:0,
page:6,
 loader:false,

  }
 }

componentDidMount() {
  
  this.getDestinations(12);
this.setState({loader:true})

  var options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  };
  
  this.observer = new IntersectionObserver(
    this.handleObserver.bind(this),
    options
  );
  this.observer.observe(this.loadingRef);
}
handleObserver=(entities, observer)=> {
  const y = entities[0].boundingClientRect.y;
   if (this.state.prevY > y) {
    this.getDestinations(this.state.page+6);
    this.setState({ page: this.state.page+6 });
  }
  this.setState({ prevY: y });
}


 

   getDestinations = (value) => {
    
     this.setState({ loading:true });
      this.setState({loader:true})
    Api.get(
      `events/destinations?limit=${value}&skip=0`
    )
      .then((res) => {
         this.setState({destinationslist: res.data});
         this.setState({loader:false})
        this.setState({ loading: false });
      })
      .catch((err) => {});
       this.setState({ loading: false });
        this.setState({loader:false})
  };


  render(){
  let {loaders, value, loader} = this.state


  const {destinationslist} =this.state;
  const loadingTextCSS = { display: this.state.loading ? "block" : "none" };

  return (
    <div>
   
        <NavBar />
        
    
     
      <Container>
      
     
      <section className="top-destinations ">
  <Row className="footer-con">
    <Col>
        

        <h1>Destinations</h1>
       
            <div className=" row-margin-home" style={{ position: "relative" }}>
             {loader && <Loaders />}
             {!loader && (
        <Row >
        
          {destinationslist &&
            destinationslist.map((item, index) => (
              <Col lg={2} md={3} xs={6} style={{ padding: "10px" }} key={index}>
                <Link
                  to={{ pathname: `/destination-details/${item&& item.destination}` }}
                  style={{ textDecoration: "none" }}
                >
                  <div className="destdiv">
                    <h2 style={{textTransform:'capitalize'}}>{item && item.destination}</h2>
                    <div
                      className="Destinations"
                      style={{
                        backgroundImage: `url(${item && item.banner})`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "180px",
                        position: "relative",
                      }}
                    ></div>
                    <div className="eventopacity" style={{backgroundImage:"none",backgroundColor:"black",height:"100%",opacity:"0.12"}}></div>
                  </div>{" "}
                </Link>
              </Col>
            ))}
        
        </Row>
        )}
      </div>
            
         <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
          
        >
        <div className="loader loader-1" style={loadingTextCSS} >
        <div className="loader-outter" />
        <div className="loader-inner" />
      </div>
      </div>
     

         

      


</Col>
</Row>
      </section>
     
    </Container>
      <Footer />
    </div>
  );
}
}

export default Destinations;
