import React, { useState, Component, useEffect } from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import Api from "../Service/Api";
import Wishlist from "../Components/Wishlist";
import NavbarUser from "../Components/NavbarUser";

const WishList = (props) => {
  const [list, setList] = useState("");

  useEffect(() => {getwishlist()}, []);
  const getwishlist = () => {
     let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;

    Api.get(
      `/users/wishlist?orderBy=updatedOn&sort=DESC`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => {
         setList(res.data);
      })
      .catch((err) => {
       });
  };

  return (
    <div>
      <NavbarUser />
      <section className="containercommon-margin">
        <Wishlist history={props.history} list={list} />
      </section>
    </div>
  );
};

export default WishList;
