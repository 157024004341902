import React, { Component } from "react";
import { withRouter } from "react-router";
import Api from "../Service/Api";

class ScrollToTop extends Component {
  async componentDidMount() {
    Api.get("/admin/platformSettings").then((i) => {
      console.log(i?.data);
      if (i?.data?.comingSoon) {
        this.props.history.push(`/comingSoon`);
      } else if (i?.data?.underMaintainence) {
        this.props.history.push(`/underMaintenance`);
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
