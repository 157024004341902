import axios from "axios";
require('dotenv').config();

 
export default axios.create({
  //baseURL: "http://13.127.182.17:4000/api", 
  //baseURL: process.env.API_URL,
  //baseURL:"http://65.0.182.223:5000/api",
   baseURL:"https://api.bookmytrek.com/api",
  responseType: "json",
 
});
let accesstoken = localStorage.getItem("accesstoken")
const token = `Bearer ${accesstoken}`
export {token}