import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Api from "../Service/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonCommon from "./ButtonCommon";
import moment from "moment";

class AdventureEvent extends Component {
  daydiff = (list) => {
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    // return a.diff(b, "days");
    var c = a.diff(b, "days") + 1;
    return c;
  };
  percen =(list ) => {
    var number = list && list.amount;
    var percentToGet = list && list.discount;
 //Calculate the percent.
var percent = (percentToGet / 100) * number;
 return percent;

  }

  render() {
    const { image, place } = this.props;
    let { list } = this.props;
    return (
      <div>
        <div>
          <Row className="row-margin-home">
            {list &&
              list.map((item, index) => (
                <Col lg={3} md={6} className="margin-btm" key={index}>
                  <Card
                    className="card2"
                    style={{
                      height: "300px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      onClick={() =>
                        this.props.history.push(`/events/${item.eventId}`)
                      }
                    >
                      <Card.Img
                        variant="top"
                        src={item.banner}
                        style={{
                          height: "200px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      />
                      <Row className="">
                    <Col className="">
                    <h5 
                    className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}
                    ><div className="price2"> 
                    {item.discount}% {' '}
                    {/* {this.percen(item)}%  */}
                     OFF
                    {/* 10% OFF */}
                    </div></h5>
                    </Col>
                  </Row>

                    </div>
                    
                    <div
                      onClick={() =>
                        this.props.history.push(`/events/${item.eventId}`)
                      }
                    >
                      <Card.Body className="card_body">
                        <Card.Title className="Title-all font-style" style={{}}>
                          {" "}
                          {/* {item.title.length > 15 && (
                            <div style={{textTransform:'capitalize'}}>{item.title.substring(0, 15)} ...</div>
                          )} */}
                          {item.title.length && (<div className="Textnooverflow">{item.title}</div>)}
                        </Card.Title>
                        {/* <Card.Text> */}
                        <span
                          style={{ fontSize: "12px" }}
                          className="sub-title-all"
                        >
                          {item && item.totalRatings} REVIEWS
                        </span>
                       
                        <h5 className="price">
                        ₹{item.amount- this.percen(item)}
                        {/* {item.amount} */}
                        </h5>
                         
                        <h5  
                        style={{float:'right',
                        alignContent: 'center',
                        marginTop: 10,
                        marginRight: 10
                         }}
                        
                        className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}>
                         <div className="price-dis" style={{ textDecorationLine: 'line-through' }}> ₹ {item.amount}
                         </div>
                         </h5>
                        <p className="sub-title-all">
                          {this.daydiff(item)} days
                        </p>
                        {/* </Card.Text> */}
                      </Card.Body>
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
          <div className="adventure-show-more">
            <Link
              to={{ pathname: "/allEvents" }}
              style={{ textDecoration: "none" }}
            >
              <ButtonCommon className=" all-events-btn" label="View All" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default AdventureEvent;
