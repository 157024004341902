import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class Copyright extends Component{
    render(){
        return(
             <div>
                 <NavBar />
                 <div className='container footer-con'>
                <h1>Copyright Policy - updated: January 25th, 2021</h1>
               <p>
               BookMyTrek is a marketplace where mostly local suppliers/vendors upload the content and we have developed our in-house rules in order to
                prevent any misalignment of interests between the former and the later. Thereby also attempting to ensure that no material which is inconsistent
                with our terms and conditions set forth herein is present on the website, or in any physical form with the branding of BookMyTrek.
               </p>
               <p>
                A visitor to the website may not reproduce, perform, create derivative works from, republish or upload in any way whatsoever any content from
the website without the prior written consent of the company. You may, however, download any downloadable items displayed on the website, for
personal, non-commercial home use only, provided all copyright and other notices contained in the contents are left intact.
               </p>

               <p>
                Herein any mention of BookMyTrek, us or the company is a direct reference to the company BookMyTrek. Also any mention of content will
include content in any form including in the form of media based content such as images, videos and presentation slides.
               </p>

               <h2>For Local Guides/Vendors/Tour Suppliers/BookMyTrek agents/Partners:</h2>
              <ol>
                <li><p>
                Upload only 100% unique content. The content should not be copied from any other website or any digital or physical source of
information. If you have same the itinerary on your own website(in cases where required, you will have to provide substantial proof* of
being the owner) we would suggest that you rewrite the content which will be different in structure but the same as the meaning you are
trying to derive. In such a case, we may be able to assist you with content writers to undergo the task of rewriting the content.
                   </p>
                </li>
                <li>
                    <p>
                        The experience/tour needs to be conducted by you and not by any other party. You should have full rights of the any information
displayed on BookMyTrek website by you. The experience/tour cannot be outsourced by you to any other third party. Hence by
uploading an experience/tour onto our website, you agree to being the party which conducts the experience/tour in it’s entirety**.
                    </p>
                </li>

                 <li>
                    <p>
                        The uploader must own full rights to any media uploaded by him/her. All media including videos/pictures/slides/marketing material etc
need to be owned by you/your company and BookMyTrek holds the right to use it there onwards without the requirement for attribution of
credits.
                    </p>
                </li>

                 <li>
                    <p>
                        As per Rule 3, you should have sole and entire copyright for any form of information*** uploaded by you. Please do not upload anything
which does not belong to you or your company and for which you do not have sole ownership. We prohibit you to use anything under
Creative Commons licence. If any violation of this rule takes place, indicated to us by either the company officials or the general public or
the owner of the material itself, the infringing experience/tour will be immediately removed from the website and you will be held
responsible for any kind of infringement be it image/video/content or anything else. This will also result in the removal of all experiences
/tours uploaded by you onto our website and will end in a permanent ban from being a supplier for BookMyTrek .
                    </p>
                </li>

                 <li>
                    <p>
                        Mismatch in the media and content uploaded is a bannable offense. If the images of the experience/tour and the content describing it do
not match or are seen to be intentionally depicting incorrect information in order to misinform the viewer of the content, the experience
/tour will be removed and the supplier will be banned.
                    </p>
                </li>

                 <li>
                    <p>
                    In case of any true claims the company will pass on your details to the copyright claimer. This is subjected to BookMyTrek ’s decision on
that matter after it’s analysis on that issue.
                    </p>
                </li>


                 <li>
                    <p>
                        If a supplier reports any copyright violation, appropriate actions will be taken within three business days.
                    </p>
                </li>

                 <li>
                    <p>
                            By submitting listing on the website, you are consenting to its display in the marketplace, and/or on any of the other company websites.
You are also consenting to its use for promotional purposes by the company.
                    </p>
                </li>
                 <li>
                    <p>
                        Also please note that the textual content uploaded by you on the BookMyTrek website should not be loaded/copied anywhere on any
other website for 6 months after the tour is active on the website. We do not restrict you to use any other marketplace or website but the
content should not be duplicated on other websites for at least 6 months.
                    </p>
                </li>
                 <li>
                    <p>
                    By uploading or providing details of any tour/activity conducted by you/your company you take the entire responsibility of any copyright
infringement issues pertaining to that tour/activity. We do a few random checks at our end but the final responsibility will be yours on all
the materials provided.
                    </p>
                </li>
                </ol>

                <h2>For Copyright Claimants:</h2>
                <ol>
                 <li>
                    <p>
                        In case of any copyright infringement, please send us an email detailing as to which experience/tour is in violation of copyrights and
specifically what content on that page violates the copyright. Along with this, it is required that you submit proof of your copyright claim
which should include proof which shows you to be the owner of the violating material.
                    </p>
                </li>
                 <li>
                    <p>
                        In case of any reported copyright infringement, the copyright claim including all the related details will be sent to the vendor. Our team
will then do a follow up with the vendor on this which may take upto seven business days and may be extended to fourteen days. If the
vendor does not reply within this time period, it will be assumed that the claimant’s claim is valid and on the seventh day, the experience
/tour will be removed from our website. In case the vendor’s reply is inconclusive and he/she is unable to provide proper proof as to his
/her ownership of the material under question, it will be deemed to be a violation of copyright laws and the entire experience/tour
including the infringing material will be removed and the vendor will be banned from BookMyTrek . After this, the claimant will be
connected with the vendor to take the legal issue forward.
                    </p>
                </li>
                <li>
                    <p>
To enable us to address your concerns, please provide the following information:
                    </p>
                    <ul className="disc ul_font">
                        <li>For each allegedly infringing image or piece of content that you wish to have removed from our website, provide the exact
                            permanent URL for the page containing the material and details of which is the infringing piece of content.</li>
                            <li>
                            Provide information which is reasonably sufficient to permit us to contact you: an email address is preferred, as well as a
                            telephone contact number.
                            </li>
                            <li>
                            Provide the following information detailing your claim to ownership of the copyright in the allegedly infringing material:
                            </li>
                            <li>
                            Proof of copyright in the image or text concerned, namely proof of registration of the image under the DMCA or absent such
                            registration
                            </li>
                            <li>
                            A detailed description of where the photograph was taken, by whom, who or what the subject of the image is, and
                            </li>
                            <li>
                            Evidence to support your claim that you own the copyright in the image.
                            </li>
                    </ul>
                </li>
                <li>
                    <p>
                    We will not comply with requests to remove an image where the complainant cannot prove that they own the copyright in the image in
                    question.
                    </p>
                </li>
                <li>
                    <p>
                    All claims will be taken seriously by the company and any intentionally false claims**** and/or misrepresentation of facts in a claim will
                        result in the company proceeding to take legal action against the claimant which will include liability for damages (including costs and
                        attorneys' fees).
                    </p>
                </li>
                <li>
                    <p>
                        Any content submitted becomes the property of BookMyTrek to do with as we wish.
                    </p>
                </li>
                </ol>

                <h2>Images Terms of Use:</h2>
                <ol>
                <li>
                    <p>
                    The following types of images cannot be uploaded on the website:
                    </p>
                        <ul className="disc ul_font">
                            <li>
                                A work that uses free or purchased components from any other source unless you have an express license to do so.
                            </li>
                            <li>
                                A work that uses in whole or in part government or military property.
                            </li>
                            <li>
                                A work that includes a photograph or image of a private location unless you have express permission to do so, which you can
                                produce on demand.
                            </li>
                            <li>
                                A work that includes a person or recognizable human feature unless you have express permission to do so, which you can
                                produce on demand.
                            </li>
                        </ul>
                </li>
                 <li>
                    <p>
                        The only images allowed are those that are:
                    </p>
                    <ul className="ul_font">
                        <li style={{listStyleType: 'lower-alpha'}}>
                            created by the author;
                        </li>
                        <li style={{listStyleType: 'lower-alpha'}}>
                        created by the author;
                        </li>
                    </ul>
                </li>
                </ol>

                <h2>Please note:</h2>
                <ol>
                 <li>
                    <p>
                        BookMyTrek is a marketplaces for tours and activities and does not take any responsibility for any of the content uploaded on our
website in the tours, activities and experiences sections, including certain guest posts on the blog. The uploader is solely responsible for
the content which he/she has uploaded. As a precaution, in most cases we will take confirmation from the supplier that the content
provided is original and that the supplier is it’s sole owner.
                    </p>
                </li>
                 <li>
                    <p>
BookMyTrek holds the right to delete any content from the website at anytime and the company’s decision will be the final one.
                    </p>
                </li>

                <li>
                    <p>
                        The content provided will be watermarked to avoid any piracy issues.
                    </p>
                </li>

                <li>
                    <p>
                    Since the company is registered in Jaipur, legal issues, including issues pertaining to copyright violations, will be subject to the
jurisdiction of Jaipur (Rajasthan).
                    </p>
                    <ul className="ul_font">
                        <li style={{listStyleType: 'lower-alpha'}}>
                            The appropriate amount of proof considered to be substantial will be decided by the company.
                        </li>
                        <li style={{listStyleType: 'lower-alpha'}}>
                        This may be subject to the certain leniencies in the case of unforeseeable circumstances and if the company has been informed
with a substantial prior notice that the operator will be using a third party without which the experience/tour will not be able to
proceed.
                        </li>
                        <li style={{listStyleType: 'lower-alpha'}}>
                            Information here can be in any form including media, videos, pictures, content etc.
                        </li>
                        <li style={{listStyleType: 'lower-alpha'}}>
                            Here intentionally false claims will be defined by the company as those which are meant to mislead the company or claims
which are made in order to misuse the company’s resources. The company can be contacted to provide more information
regarding what falls under this category.
                        </li>
                    </ul>
                </li>

               
              </ol>
            </div>
            <Footer />
            </div>
        )
    }
}
export default Copyright;