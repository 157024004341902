import React, { useState, useEffect, Component } from "react";

import {

  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
    Form,
    Row,
    Col,
    Badge,
    Container,
    Button,
    Label,
    Image,
  } from "react-bootstrap";
import Api from "../Service/Api";
import ButtonCommon from '../Components/ButtonCommon';
import NavBar from "../Components/NavBar";

import {useHistory} from 'react-router-dom';

import Loaders from "../Components/FullScreenLoader";


const Register = (props) => {
    const [email, setEmail] = useState("");
    const [name, setFirstname] = useState("");
    const [mobile, setMobile] = useState("");
    const [lastname, setLastname] = useState("");
    const [password, setPassword] = useState("");
    const [Cpassword, setCpassword] = useState("");
    const [err, seterr] = useState("");
    const [message, setMessage] = useState("");
    const [emailmessage, setEmailMessage] = useState("")
let history= useHistory()
    const [error, seterror] = useState("");
    const [errormail, seterrormail] = useState("");

    const [disable, setdisable] = useState(false);
    const [modalOpen, setmodalOpen] = useState(false);
    
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [active, setactive] = useState(false);
    const [edit, setEdit] = useState(false);
     const [loaders, setLoader] = useState(false);
    
     const onSendmsg = (event) => {
      seterrormail("")
     seterror("");
     seterr("");
              
        const form = event.currentTarget;
         if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();

        }
        setValidated(true);

        // setLoader(true);
         if (form.checkValidity() === true  ) {

          if(password===Cpassword ){
            if(mobile.length>=10 && mobile.length<=10){
          setLoader(true);
          setactive(true);
          setEdit(false)
          seterror("");
          seterr("");
          seterr("");
          let data = {
            name: name,
            mobile: mobile,
            email: email,
            password: password,
          
          };
          event.preventDefault();
          Api.post("/auth/signup", data, {
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: token,
          //   },
          })
            .then((res) => {
               if (res.status === 201) {
                 setLoader(false)
                seterror("");

                setShow(true);
                alert("Thank you for showing interest in BookMyTrek!. Our team will update status by mail. Kindly verify your email.");
                history.replace("/login")
                setMessage("Registered Successfully")
               
                //setFirstname(" ");
                //setEmail(" ");
                //setMobile(" ");
                //setPassword(" ");
                //setCpassword(" ");
                setValidated(false);
              }
             else{
                setEmailMessage("Email Already Used")
     
              }
            })
            .catch((err) => {
              event.preventDefault();
              setLoader(false)
              //seterror("Enter Valid Details")
              //  seterror(err.response.data.message)
              let c="Email already used"
          seterrormail(err.response.data.message);
               setactive(false);
              
            });
         
        } 
         else{
            event.preventDefault();
              setLoader(false)
            let m="Please enter valid mobile number"
            setMobile(m);
        }
}
       
        else{
          //seterror("Invalid email id ")
           event.preventDefault();
              setLoader(false)
          let b="New and confirm  passwords do not match!"
          seterr(b);
        }}
      };
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        setLoader(false)
    }
      useEffect(() => {
        
      }, []);
  

   
  return (
    <>
    <NavBar/>
    {loaders&&<Loaders/>}
    <section className="register-section">
    <div className="container register">
      <div>
      <Row className="footer-con" >
      <Col
        lg="12"
       
      >
        <Card className="Card-style"  style={{ alignSelf: "center", margin: "auto", maxWidth:'500px' }}>
          <h1 style={{fontSize:'30px', FontWeight:'bold', textAlign:'center'}}>Sign Up</h1>
          <CardBody>
          <Form noValidate className="signup_form" 
          validated={validated} 
          onSubmit={onSendmsg}
          >
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Full name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter full name"
            defaultValue="Mark"
            required
            value={name}
            onChange={(event) => setFirstname(event.target.value)}
          />
          <Form.Control.Feedback type="invalid">Enter Full Name</Form.Control.Feedback>
        </Form.Group>
       
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Email</Form.Label>
          <Form.Control placeholder="Enter email"
           required
           type="email"
           value={email}
           onChange={(event) => setEmail(event.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Enter Valid Email ID
          </Form.Control.Feedback>
        </Form.Group>
       
      </Form.Row>
      <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Mobile</Form.Label>
          <Form.Control
            required
            type="number"
            
            placeholder="Mobile"
            defaultValue="Mark"
            required
            value={mobile}
            onChange={(event) => setMobile(event.target.value)}
          />
          
          <Form.Control.Feedback type="invalid">Enter Valid Mobile Number </Form.Control.Feedback>
        </Form.Group>
        
       
      </Form.Row>
        <Form.Row>
        <Form.Group controlId="formGroupPassword"  as={Col} md="12" >
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" 
            placeholder="Password"
            required
            isInvalid={validated&& password.length<8}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            />
            <Form.Control.Feedback type="invalid" >
            Password must be longer than or equal to 8 characters!
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroup" as={Col} lg={12}>
        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          className="formcontrolE"
                          placeholder="Confirm Password"
                          required
                          type="password"
                          value={Cpassword}
                          onChange={event => 
                            {
                              setCpassword(event.target.value)
                              seterr("")

                            }
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Re-Enter Valid password
                        </Form.Control.Feedback>
                      </Form.Group>
        </Form.Row>

      {/* <Form.Group>
        <Form.Check
          required
          label="Agree to terms and conditions"
          feedback="You must agree before submitting."
        />
      </Form.Group> */}

      {/* <Button type="submit" style={{width:'100%'}}>Sign Up</Button> */}
      <Row className="text-center btnmargin" style={{display:'block'}}>
      <ButtonCommon className="all-events-btn" label='Sign Up' 
        type="submit"
      />
      </Row>
      {error && (
      <Row style={{  textAlign:'center', margin:'auto',display:'block' }}>
             <Col>
                  <label
                    style={{
                      color: "red",
                      textAlign: "center",
                      //  margin: "10px",
                    }}
                  >
                    {error}
                  </label>
                  </Col>
              </Row>
               )}
               {errormail && (
      <Row style={{  textAlign:'center', margin:'auto',display:'block' }}>
             <Col>
                  <label
                    style={{
                      color: "red",
                      textAlign: "center",
                      //  margin: "10px",
                    }}
                  >
                    {errormail}
                  </label>
                  </Col>
              </Row>
               )}
                {emailmessage && (
                  <Row style={{  textAlign:'center', margin:'auto',display:'block' }}>
                        <Col>
                  <label
                    style={{
                      color: "red",
                      textAlign: "center",
                      // margin: "20px",
                    }}
                  >
                    {emailmessage}
                  </label>
                  </Col>
              </Row>
               )}
              {message && (
              <Row style={{  textAlign:'center', }}>
              <Col>
                <label style={{ color:'green', textAlign:'center', margin:'auto'}}>{message}</label>
                </Col>
              </Row>
           ) }
           {err && (
             <Row style={{  textAlign:'center', }}>
             <Col>
                      <lable style={{color:'red'}}>
                     {err}
                      </lable>
               </Col>
              </Row>
                      )}
    </Form>
    

          </CardBody>
        </Card>
      </Col>
      </Row>
    </div>
    </div>
    </section>
    </>
  );
};

export default Register;
