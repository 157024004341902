import {
  GETSLIDER,
  GETTOPEVENT,
  GETFEATURED,
  GETTOPDESTINATION,
  GETTOPOPERATOR,
  GETTOPCATEGORY,
  GETEVENTREVIEW,
  Profile,
  GETCOVIDSTATUS,
} from "../actions/actionType";
const initialState = {
  images: [],
  featured: [],
  eventlist: [],
  destination: [],
  topoperator: [],
  eventreviews: [],
  profile:{},
  categorylist: [],
  covidstatus:{},
};
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case Profile: {
      return { ...state, profile: action.payload };
    }
    case GETSLIDER: {
      return { ...state, images: action.payload };
    }
    case GETTOPEVENT: {
      return { ...state, eventlist: action.payload };
    }
    case GETFEATURED: {
      return { ...state, featured: action.payload };
    }
    case GETTOPDESTINATION: {
      return { ...state, destination: action.payload };
    }
    case GETTOPOPERATOR: {
      return { ...state, topoperator: action.payload };
    }
    case GETEVENTREVIEW: {
      return { ...state, eventreviews: action.payload };
    }
    case GETTOPCATEGORY: {
      return { ...state, categorylist: action.payload };
    }
    case GETCOVIDSTATUS: {
      return { ...state, covidstatus: action.payload };
    }
    default:
      return state;
  }
}
