import React from "react";
import NavbarUser from '../Components/NavbarUser';
import UserBooking from '../Components/UserBooking'

const UserBookings = (props) => {
  return (
    <div>
      <NavbarUser/>
      <section className="container">
            <UserBooking/>
        </section>
    </div>
  );
};

export default UserBookings;
