import React, { Component, useState,useEffect } from 'react';
import NavBar from '../Components/NavBar';
import SearchBox from '../Components/SearchBox';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Form,Row,Col,Badge , Container,
  Carousel,Accordion,Card,Button,Label,Table,
 Image} from 'react-bootstrap';

import BookDetails from '../Components/ViewAllAdventureEvents';
import Footer from '../Components/Footer';
import ViewEvent from '../Components/ViewEvent';
import Bookevent from '../Components/Bookevent';


import ArticleCard from '../Components/ArticleCard';
import Loaders from '../Components/FullScreenLoader'
import Api from '../Service/Api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CarouselEvent from '../Components/CarouselEvent';
import UserDetailsCancellation from '../Components/UserDetailsCancellation';
import PaymentAndTravellerDetails from '../Components/PaymentAndTravellerDetails'


const UserBookingDetails = (props) => {
   const [list, setList] = useState("");
  const [bookinglist, setBooking] = useState("");
  const [loader, setLoader] = useState(true);
  const [eventid, seteventid] = useState(null);
  const [id, setid] = useState(null);
  useEffect(() => {
      geteventlist()
    }, [ ]);
  const geteventlist = () => {
       let accesstoken = localStorage.getItem("accesstoken");
      const token = `Bearer ${accesstoken}`;
    
        Api.get(`/users/bookings/${props.match.params.id}`, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
            setBooking(res.data)
               Api.get(`/events/${res.data.bmtEventId}?&relations=["eventImages","includes","itineraries","kitlist","recommendation","additionals", "user"]`, {
                headers: {
                  "content-type": "application/json",
                  Authorization: token,
                },
              })
                .then((res) => {
                    
                  setLoader(false)
                   setList(
                  res.data
                  );
                  
                })
                .catch((err) => {
                   
                });
            
            
          })
          .catch((err) => {
             
          });
    };
  

 
// render() {
 
    return (
      <div>
        <NavBar />
       
          
      
        <div className='container footer-con'>
        {loader && <Loaders />}
        <section >        
         <Row>
           <Col>
           <h1 style={{paddingBottom:"30px"}}>Your Booking Details</h1>
           </Col>
           </Row>
           <Row >
             <Col lg={6}>
             <CarouselEvent 
            //  history={props.history} list={list} 
            history={props.history}
            id={props.match.params.id}
            list={list}
             />
             </Col>
             <Col lg={6}>
             <UserDetailsCancellation history={props.history}book={bookinglist}  list={list}/>
             </Col>
           </Row>
        
        <Row>
          <Col>
         
     <PaymentAndTravellerDetails history={props.history} list={bookinglist}/>
     </Col>

        </Row>
        
                </section>
       
        </div>
        <Footer/>
        </div>
    );
  }


export default UserBookingDetails;
