import React, { useState, Component } from "react";
import {
  Form,
  Row,
  Col,
  Badge,
  Container,
  Carousel,
  Accordion,
  Card,
  Button,
  Image,
  Modal,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import ButtonCommon from "./ButtonCommon";
import BeautyStars from "beauty-stars";
import NavBar from "./NavBar";
import SearchBox from "../Components/SearchBox";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateTimePicker from "react-datetime-picker";
import { Label, Input } from "reactstrap";
import Loader from "./Loader";
import "react-day-picker/lib/style.css";

import Api from "../Service/Api";

class Review extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      list: [],

      modalShow: false,

      review: "",
      name: "",
      vrev: false,
      reviewop: "",
      error: "",
      loader: false,
      operatorreview: [],
      eventreview: [],
      value:"",
      value1:"",
      value2:"",
      reviewbmt:"",
      bmtreview: [],
      reviewbmtexp:""
    };
  }

  componentDidMount() {
    this.setState({ loader: true });
    const token = localStorage.getItem("accesstoken");
    const { userId, eventid, operadtorid } = this.props;
 
    Api.get(`/events/${eventid}/reviews?where={"userId": ${userId}}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
 
          
          this.setState({ eventreview: res.data,loader: false });
        }
      })
      .catch((err) => {
        // console.log(err.response);
      });
    Api.get(`/users/operator/${operadtorid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        
        if (res.status === 200) {
      
          this.setState({ operatorreview: res.data,loader: false });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });

Api.get(`/bmt-reviews/myreview/public?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
         
        if (res.status === 200) {
        
          this.setState({ bmtreview: res.data,loader: false });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });

      
  }

  callevent = () => {
    const token = localStorage.getItem("accesstoken");
    const { value, review } = this.state;
    this.setState({ loader: true });
    Api.post(
      `/events/${this.props.eventid}/reviews`,
      {
        rating: value,
        description: review,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
       
        if (res.status === 201) {
          this.setState({ loader: false });
        } else {
          this.setState({ loader: false });
          this.setState({ error: "Try after Sometime" });
         }
      })
      .catch((err) => {
      
      });
  };

  calloperator = () => {
    const token = localStorage.getItem("accesstoken");
    const { value1, reviewop } = this.state;
    this.setState({ loader: true });
    Api.post(
      `/operators/${this.props.operadtorid}/reviews`,
      {
        rating: value1,
        description: reviewop,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 201) {
          
          this.setState({ loader: false });
        } else {
          this.setState({ error: "Try after Sometime" });
          this.setState({ loader: false });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };


   callbmt = () => {
    const token = localStorage.getItem("accesstoken");
    const { value2, reviewbmt,reviewbmtexp } = this.state;
    this.setState({ loader: true });
    Api.post(
      `/bmt-reviews/`,
      {
        rating: value2,
        description: reviewbmt,
        review:reviewbmtexp,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
      
        if (res.status === 201) {
          
          this.setState({ loader: false });
        } else {
          this.setState({ error: "Try after Sometime" });
          this.setState({ loader: false });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  render() {
    let {
      banner,
      endDate,
      startDate,
      review,
      reviewop,
      name,
      vrev,
      eventreview,
      operatorreview,
      loader,value1,
      bmtreview, value2, reviewbmt,reviewbmtexp

    } = this.state;
 
    return (
      <div>
        <Tabs defaultActiveKey="event" id="uncontrolled-tab-example">
          <Tab eventKey="home" title="Event Review">
            {loader && <Loader />}
            {!loader && eventreview.length != 0 && (
              <Card style={{ height: "400px" }}>
                <Card.Header as="h5">Review</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <BeautyStars
                      value={eventreview[0].rating}
                      inactiveColor="grey"
                    />
                  </Card.Text>
                  <p>{eventreview[0].description}</p>
                </Card.Body>
              </Card>
            )}
            {!loader && eventreview.length == 0 && (
              <Card style={{ height: "400px" }}>
                <Card.Header as="h5">Write Review</Card.Header>
                <Card.Body>
                  <Form>
                    <Card.Text>
                      <BeautyStars
                        value={this.state.value}
                        onChange={(value) => {
                          this.setState({ value });
                          this.setState({ vrev: true });
                        }}
                        inactiveColor="grey"
                      />
                    </Card.Text>

                    {vrev && (
                      <div>
                        {/* <Form.Group controlId="formGroup">
                        <Label for="exampleEmail">Full Name</Label>
                        <Form.Control
                          className="formcontrolE texta"
                          type="text"
                          placeholder="Full Name"
                          value={name}

                          // onChange={(event) =>
                          // setDescription(event.target.value)
                          // }
                        />
                      </Form.Group> */}
                        <Form.Group controlId="formGroup">
                          <Label for="exampleEmail">Write Review</Label>
                          <Form.Control
                            row={3}
                            as="textarea"
                            className="formcontrolE texta"
                            type="text"
                            placeholder="comment"
                            value={review}
                            onChange={(event) => {
                              this.setState({ review: event.target.value });
                            }}
                          />
                        </Form.Group>

                        <ButtonCommon
                          className="btncommon btnrounded"
                          label=" Submit Review"
                          handleClick={() => {
                            this.callevent();
                          }}
                        />
                      </div>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            )}
          </Tab>
          <Tab eventKey="event" title="Operator Review">
            {loader && <Loader />}
            {!loader && operatorreview.length == 0 && (
              <Card style={{ height: "400px" }}>
                <Card.Header as="h5">Write review</Card.Header>
                <Card.Body>
                  <Form>
                    <Card.Text>
                      <BeautyStars
                        value={this.state.value1}
                        onChange={(value1) => {
                          this.setState({ value1 });
                          this.setState({ vrev: true });
                        }}
                        inactiveColor="grey"
                      />
                    </Card.Text>

                    {vrev && (
                      <div>
                        {/* <Form.Group controlId="formGroup">
                        <Label for="exampleEmail">Full Name</Label>
                        <Form.Control
                          className="formcontrolE texta"
                          type="text"
                          placeholder="Full Name"
                          value={name}

                          // onChange={(event) =>
                          // setDescription(event.target.value)
                          // }
                        />
                      </Form.Group> */}
                        <Form.Group controlId="formGroup">
                          <Label for="exampleEmail">Write Review</Label>
                          <Form.Control
                            row={3}
                            as="textarea"
                            className="formcontrolE texta"
                            type="text"
                            placeholder="comment"
                            value={reviewop}
                            onChange={(event) => {
                              this.setState({ reviewop: event.target.value });
                            }}
                            // onChange={(event) =>
                            // setDescription(event.target.value)
                            // }
                          />
                        </Form.Group>

                        <ButtonCommon
                          className="btncommon btnrounded"
                          label=" Submit Review"
                          handleClick={() => {
                            this.calloperator();
                          }}
                        />
                      </div>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            )}
            {!loader && operatorreview.length != 0 && (
              <Card style={{ height: "400px" }}>
                <Card.Header as="h5">Review</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <BeautyStars
                      value={operatorreview.rating}
                      inactiveColor="grey"
                    />
                  </Card.Text>
                  <p>{operatorreview.description}</p>
                </Card.Body>
              </Card>
            )}
          </Tab>

{/*bmt review*/}

<Tab eventKey="bookmytrek" title="Bookmytrek Review">
            {loader && <Loader />}
            {!loader && bmtreview.length == 0 && (
              <Card style={{ height: "400px" }}>
                <Card.Header as="h5">Write review</Card.Header>
                <Card.Body>
                  <Form>
                    <Card.Text>
                      <BeautyStars
                        value={this.state.value2}
                        onChange={(value2) => {
                          this.setState({ value2 });
                          this.setState({ vrev: true });
                        }}
                        inactiveColor="grey"
                      />
                    </Card.Text>

                    {vrev && (
                      <div>
                       
                        <Form.Group controlId="formGroup">
                          <Label for="exampleEmail">Write Review</Label>
                          <Form.Control
                            row={3}
                            as="textarea"
                            className="formcontrolE texta"
                            type="text"
                            placeholder="comment"
                            value={reviewbmt}
                            onChange={(event) => {
                              this.setState({ reviewbmt: event.target.value });
                            }}
                            // onChange={(event) =>
                            // setDescription(event.target.value)
                            // }
                          />
                        </Form.Group>
                        <Form.Group controlId="formGroup2">
                          <Label for="exampleEmail">Your exprience in three words</Label>
                          <Form.Control
                            row={3}
                            as="textarea"
                            className="formcontrolE texta"
                            type="text"
                            maxLength="20"
                            placeholder="Your exprience in three words"
                            value={reviewbmtexp}
                            onChange={(event) => {
                              this.setState({ reviewbmtexp: event.target.value });
                            }}
                           
                          />
                        </Form.Group>

                        <ButtonCommon
                          className="btncommon btnrounded"
                          label=" Submit Review"
                          handleClick={() => {
                            this.callbmt();
                          }}
                        />
                      </div>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            )}
            {!loader && bmtreview.length != 0 && (
              <Card style={{ height: "400px" }}>
                <Card.Header as="h5">Bookmytrek Review</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <BeautyStars
                      value={bmtreview[0].rating}
                      inactiveColor="grey"
                    />
                  </Card.Text>
                  <p>{bmtreview[0].description}</p>
                  <Label><b>Tour Exprience</b></Label>
                  <p>{bmtreview[0].review}</p>
                </Card.Body>
              </Card>
            )}
          </Tab>







        </Tabs>
      </div>
    );
  }
}

export default Review;
