import React, { Component } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import Api from "../Service/Api";
import moment from "moment";
import BeautyStars from "beauty-stars";

class AdventureEvent extends Component {


 

  


  daydiff = (list) => {
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    var c= a.diff(b, "days")+1;
    
    return c;
  };
  percen =(list ) => {
    var number = list && list.amount;
    var percentToGet = list && list.discount;
 //Calculate the percent.
var percent = (percentToGet / 100) * number;
 return percent;

  }

  render() {
    const { image, place,item } = this.props;
    
const loadingCSS = {
      // height: "100px",
      margin: "30px"
    };
    
   // const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
                <Col lg={4} md={6} className="margin-btm">
                  <Card
                    className="card2"
                    style={{ height: "300px", borderRadius: "10px", cursor:"pointer" }}
                  >
                    <div
                      onClick={() =>
                        this.props.history.push(`/events/${item.bmtEventId}`)
                      }
                    >
                      <Card.Img
                        variant="top"
                        src={item.banner}
                        style={{
                          height: "200px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      />
                       <Row className="">
                    <Col className="">
                    <h5 
                    className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}
                    ><div className="price2"> 
                    {item.discount}% {' '}
                    {/* {this.percen(item)}%  */}
                     OFF
                    {/* 10% OFF */}
                    </div></h5>
                    </Col>
                  </Row>

                    </div>
                    <div
                      onClick={() =>
                        this.props.history.push(`events/${item.bmtEventId}`)
                      }
                    >
                      <Card.Body className="card_body">
                        <Card.Title className="Title-all font-style" style={{}}>
                          {" "}
                           {item.title.length && (<div className="Textnooverflow">{item.title}</div>)}
                        </Card.Title>
                        {/* <Card.Text> */}
                          <span
                            style={{ fontSize: "12px" }}
                            className="sub-title-all"
                          >
                            {item.totalRatings} REVIEWS
                          </span>
                          <h5 className="price">
                        ₹{item.amount- this.percen(item)}
                        {/* {item.amount} */}
                        </h5>
                        <h5  
                        style={{float:'right',
                        alignContent: 'center',
                        marginTop: 10,
                        marginRight: 10
                         }}
                        
                        className={item.discount == "0"? "discount_price_off" : "discount_price_off_value Title-all "}>
                         <div className="price-dis" style={{ textDecorationLine: 'line-through' }}> ₹ {item.amount}
                         </div>
                         </h5>

                          <p className="sub-title-all">
                            {this.daydiff(item)} days
                          </p>
                        {/* </Card.Text> */}
                      </Card.Body>
                    </div>
                  </Card>
                </Col>
             
    );
  }
}
export default AdventureEvent;
