import React, { Component } from "react";
import { Button, Row, Col, Card, Image, Accordion } from "react-bootstrap";
import Api from "../Service/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import moment from "moment";
import BeautyStars from "beauty-stars";
import AdventureImage from "../../static/Images/adventure.jpg";
import AdventureImage1 from "../../static/Images/bg2.png";
import { FaAdn } from "react-icons/fa";
class OperatorReviews extends Component {
  state = {
    list: [],
    banner: "",
    endDate: "",
    startDate: "",
    limit: 10,
    skip: 0,
    id: "",
  };

  render() {
    const { image, place, reviewslist } = this.props;
    let { banner, endDate, startDate } = this.state;
    let { list } = this.props;
    console.log("reviewlist", reviewslist)

    return (
      // <div>
      <section
        className=""
        style={
          {
            // backgroundImage: `url(${AdventureImage1})`,
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // objectFit:'contain',
            // height:'80vh'
          }
        }
      >
        {/* <div> */}
        <Row style={{ marginTop: "30px" }}>
          <Col lg={12}>
            <Card style={{paddingTop:'25px'}}
              className="text-center Card-style"
            // style={{ marginRight: "25px", marginLeft: "25px" }}
            >
              <h2>Customer Reviews</h2>
              <Card.Body>

               {reviewslist && reviewslist.length == 0 && (
                                        <label>No Reviews!</label>
                                      )}
                {reviewslist.map((item) => (
                  <Card.Text>


                    <Row>
                      <Col xs={12} md={3}>
                      <h2 style={{ margin: "auto" }}>
                         {/* <FaAdn
                            style={{ fontSize: "40px", margin: "auto" }}
                          />*/}
                    <img src={item &&item.user&&item.user.profileImage && item.user.profileImage.original}  style={{width:'60px', height:'60px',margin:'auto', borderRadius:'50px'}} />

                          {/* <Image src={AdventureImage} roundedCircle style={{width:'30px', height:'30px'}} /> */}
                        </h2>
                      </Col>
                      <Col xs={12} md={9} >
                      <h3 style={{flexDirection: 'row',display: 'flex'}}>
                      {item.name}
                      <p style={{position:'absolute', right:'0'}}>written on {moment(item.createdOn).format("ll")} </p>{" "}
                      </h3>

                      <h3 style={{display:'flex'}}><BeautyStars

                        Style={{ alignItems: "center" }}
                        value={item.rating}
                        size="16px"
                        inactiveColor="grey"
                        activeColor="#fdd72a"
                        // value={item && item.rating}
                        />
                        <p style={{ paddingLeft: "10px", marginBottom:'0px' }}>
                        ({item.rating})
                        </p>
                        </h3>
                        <p
                            className=""
                            style={{ textAlign: "left" }}
                          >
                            {item.description}
                          </p>
                      </Col>
                      
                      </Row>
                      <hr/>




                    {/* <Row >
                     
                      <Col xs={12} md={3}>
                        <Row style={{ margin: "auto" }}>
                          <FaAdn
                            style={{ fontSize: "40px", margin: "auto" }}
                          />
                        </Row>
                       

                        
                      </Col>
                     
                      <Col xs={12} md={9} >
                        <Row>
                          
                          <Col lg={4} className="review_date">
                            <div className="">
                              <p>written on 8 June, 2020 </p>{" "}
                            </div>
                          </Col>
                        </Row >



                         
                        <Row >
                          <Col>
                        <h3>{item.name}</h3>
                        </Col>
                        </Row>
                        <Row >
                          <Col>
                          <p><BeautyStars

                            Style={{ alignItems: "center" }}
                            value={item.rating}
                            size="16px"
                            inactiveColor="grey"
                            activeColor="#fdd72a"
                         
                          /></p>
                          <p style={{ paddingLeft: "10px" }}>
                            ({item.rating})
                          </p>
                          </Col>
                        </Row>

                      
                         
                          <p
                            className=""
                            style={{ textAlign: "left" }}
                          >
                            {item.description}
                          </p>
                         

                      </Col>

                    </Row> */}

                   
                  </Card.Text>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* </div> */}
      </section>
      // </div>
    );
  }
}
export default OperatorReviews;
