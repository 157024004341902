import React, { Component } from "react";
import NavBar from "../Components/NavBar";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Api from "../Service/Api";
import WhyUs from "../Components/WhyUs";
import SuggestTrip from "../Components/SuggestTrip";
import ViewAllAdventureEvents from "../Components/ViewAllAdventureEvents";
import Filters from "../Components/Filters";
import Footer from "../Components/Footer";
import { Form, DropdownButton, Dropdown, Col, Row } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ButtonCommon from "../Components/ButtonCommon";
import { Label, Input } from "reactstrap";
import Price from "../Components/Price";
import InputRange from "react-input-range";
import BannerCarousel from "../Components/BannerCarousel";
import { GrFormClose } from "react-icons/gr";
import Background from "../../static/Images/register.jpg";

import Loaders from "../Components/FullScreenLoader";

class EventsByCategory extends Component {
  state = {
    list: [],
    banner: "",
    endDate: new Date(),
    startDate: new Date(),

    limit: 10,
    skip: 0,

    page: 6,
       destination:"",
       id:"",
       category:[],
        loader:false,

  };

  componentDidMount() {
    this.onSearch(6);
     this.setData();
    this.getCount();
    this.getfs()
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }
  handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    //console.log("y value", y);
    if (this.state.prevY > y && this.state.count > this.state.page) {
      this.onSearch(this.state.page + 6);
      this.setState({ page: this.state.page + 6 });
    }
    this.setState({ prevY: y });
  };


 


  getCount = () => {
    let category = this.props.match.params.name || "";
    Api.get(`/events/count?where={ "status": "active"}&category=${category}`)
      .then((res) => {
        //console.log("res.data", res);
        this.setState({ count: res.data.count });
        this.setState({ loading: false });
      })
      .catch((err) => {});
  };

  onSearch = (v) => {
    let category = this.props.match.params.name || "";
    const { skip } = this.state;
    let link = `/events/all?where={ "status": "active"}&category=${category}&limit=${v}&skip=${skip}`;
    Api.get(link)
      .then((res) => {
         this.setState({ list: res.data, loading: false });
      })
      .catch((err) => {
       });
  };

  getfs = () => {
    Api.get(`/misc/sliderimages/all`)
      .then((res) => {
       // console.log("list", res.data);
        this.setState({ ImageS: res.data });
      })
      .catch((err) => {});
  };


      setData = (id) => {
      this.setState({loader:true})
    Api.get(
      `admin/event-categories?where={"id":${this.props.match.params.id}}&limit=1`
    )
      .then((res) => {
        this.setState({ category: res.data, loader:false});
       
      }).catch((err) => {});

   

  };





  render() {
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    let {
      ImageS,
      category,
      list, loader
    } = this.state;
     return (
      <div>
      {loader && <Loaders />}
        <section>
         
               <div
              className="banner"
              style={{
                backgroundImage: 
                category[0] &&
                 category[0].icon?
                 `url(${category[0] &&category[0].icon})`:`url(${Background})`,
               
                width: "100%",
                backgroundSize: "cover",
                height: "75vh",
                position: "relative",
                backgroundPosition: "50% 50%",
                zIndex: 75,
              }}
            >
              <NavBar />

                 <div className="hero-text-wrapper">
                  <div className="hero-text view-event-title image_border title-sweep-to-right">
                  <div className="eventopacity" style={{ backgroundImage: 'none',
                      backgroundColor: 'black',
                      height: '100%',
                      opacity: '0.5'}}> </div>
                    <h1 className="banner_color" style={{textTransform:'capitalize'}}>{
                  category[0] &&
                  category[0].title}</h1>
                 
                </div>
                </div>
              
                
            
            </div>  


        
        </section>

        <section
          className="adventure-events container mt-5"
          style={{ minHeight: "100vh" }}
        >
          <Col lg={12} >
            <div className="row">
              {list.map((item) => (
                <ViewAllAdventureEvents
                  item={item}
                  history={this.props.history} // list={list}
                />
              ))}
              <div ref={(loadingRef) => (this.loadingRef = loadingRef)}>
                <div className="loader loader-1" style={loadingTextCSS}>
                  <div className="loader-outter" />
                  <div className="loader-inner" />
                </div>
              </div>
            </div>
          </Col>
        </section>

        <Footer />
      </div>
    );
  }
}

export default EventsByCategory;


