import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class Help extends Component{
    render(){
        return(
         <div>
             <NavBar />
             <div className='container footer-con'>
          <h2>Help Center</h2>
          <h2>Profile And Account</h2>
          <h2>Manage Your Account</h2>

          <p>How do I log in or sign up to my BookMyTrek  Account?</p>
          <p>Simply log in using the icon in the top right corner of BookMyTrek.com, then use the dropdown menu to navigate through your enquiries, bookings and account settings.</p>
         
          <p>If you do not have an account yet, simply click on the 'Sign Up' button to create one!</p>

          <p>How do I unsubscribe from BookMyTrek?</p>
          <p>You can unsubscribe from BookMyTrek's emails by clicking the 'unsubscribe' link at the bottom of every email.</p>

          <p>How do I delete my BookMyTrek account?</p>
          <p>To delete your BookMyTrek account please email privacy@BookMyTrek.com to have your details removed from our database.</p>

          <p>I have a confirmed booking, how do I add my travel insurance details?</p>
          <p>Please click the 'Add details' button on your booking page to add the travel insurance details for each passen</p>

          <p>How do I book & pay for optional activities while on tour?</p>
          <p>The majority of optional excursions on your tour will be booked and paid for while in destination directly to your guide. All optional excursions that are able to be pre-booked will have been displayed on the Book Now page when you checked out. These excursions are sometimes payable by card but the majority of excursions will be paid for in the local currency in cash directly to your tour guide whilst on the tour</p>

          <p>Can I book optional excursions before or after my tour begins/ends?</p>
          <p>Unfortunately the tour operator can only offer excursions during the scheduled departure dates of your tour. We recommend looking online for some amazing local activities if you are coming early or staying later after your tour. If you are looking for an excursion longer than 3 days, you can find some options on www.BookMyTrek.com</p>

          <p>What do I do for meals that are not included on the tour?</p>
          <p>For all the meals that are not included on your tour your guide or local contact will be able to suggest plenty of local delicious places to eat so you can sample the best of the local cuisine</p>

          <p>Can I upgrade to a private single room?</p>
          <p>Some tours offer the chance to secure a single room to yourself on the trip for an additional cost. If you did not book a single room at time of booking please request a single room by writing a message. Our team will come back to you with availability and pricing as soon as they are able.</p>

          <p>When is the final payment for my tour due?</p>
          <p>Please check the "Payment Details" on your booking page to see exactly when the final payment will be automatically charged for your tou</p>

          <p>How will the final balance on my tour be paid?</p>
          <p>After paying your intial payment, the final balance of payment will be automatically charged to your Credit Card or Paypal used to make the deposit If your tour operator provides vouchers or travel documents they will be sent to you approximately 2-3 weeks before your tour departs. Please ensure that all of your passenger details are complete so that the operator can send you your voucher or travel documents on time</p>

          <p>Can my travel voucher be sent by mail?</p>
          <p>For suistanable and environmental reasons, all travel vouchers are sent electronically by email It is recommended to print your vouchers as well as having an electronic copy so that you have a backup copy when travelling. However if you do not have the facility to print the voucher, an electronic copy to show your tour guide will be fine</p>

          <h2>How to Use BooKMyTrek</h2>

          <p>What is BookMyTrek</p>
          <p>How can I find a perfect tour for me?</p>
          <p>How can I book a tour?</p>

          <h2>What is BookMyTrek</h2>
          <p>What is BookMyTrek?</p>
          <p>BookMyTrek serves a broad purpose to make bookings tours easy. As such BookMyTrek is a number of things; A trusted platform for booking multi-day group tours with a huge variety of tour operators worldwide. We verify all Tour Operators, keep your payments secure and provide premium customer service at the best possible prices. A community of tour-loving travellers who connect using our apps and site features to discuss and enjoy the many benefits of travelling in a group. Our number one aim is to bridge the gap for travellers to experience the world on a fun filled group trip. Your 24/7 customer experience dedicated to tours. We focus on our specialty - booking group tours. Unlike traditional travel agents, we don’t book your cheap flights or hotels. Our Customer Support Team are experts in the tour business because they live and love this style of travel. You can book with confidence knowing we have first-hand understanding about group tours. Best of all, we’re always available. An actual human being will answer your call anytime, from anywhere in the world, 24/7.</p>

          <p>Why book a tour with BookMyTrek</p>
          <p>There are many reasons to book a tour with BookMyTrek, including:
           Over 40,000 tours to choose from.
           No booking or credit card fees.
           Best Price Guarantee 
           Earn Unlimited Travel Credits
           To see more details, please see the following link - https://wwwBookMyTrek.com/why</p>

          <p>I would like more information about a tour, how do I contact BookMyTrek?</p>
          <p>BookMyTrek is open 24/7 and you can contact us at any time when it is suitable to you.  We have numerous ways to contact us such as; our online chat functions, email or by phone. To see a full list of our contact information please see the following link - www.BookMyTrek.com/contact.</p>

          <p>How do I book airport transfers?</p>
          <p>To receive a quote for airport transfers, please send a detailed request, including your flight details, on your booking conversation page by selecting the 'Request Airport Transfer' button. Please note that if your tour is departing within 7 days it will be unlikely that the operator will be able to arrange an airport transfer</p>

          <p>How do I book pre/post-tour accommodation?</p>
          <p>Please send a detailed request with your dates on your booking conversation page by clicking the 'Request Pre/Post accommodation' button</p>

          <h2>How can I find a perfect tour for me?</h2>
          <p>How can I search for tours?</p>
          <p>BookMyTrek lists over 20,000 multi-day group tours. Simply go to tourradar.com, type your destination into the search box to begin discovering all your options. Once the page loads on the left-hand side you can filter your search to narrow down your options, by doing this it will make sure you find the perfect match for you!</p>

          <p>How do I see tours that depart during my desired month?</p>
          <p>On the left of your screen, simply select the month(s) in which you’d like to depart on tour by clicking each month. You will see that they will be highlighted blue and accompanied by a blue checkmark. You can highlight multiple months to keep your search as broad or specific as you wish.</p>

          <p>What tools can I use to filter my search results?</p>
          <p>You can filter your search of tours by price, length of tour, must-see cities and tour style to find your perfect trip. We display tour itineraries, customer reviews & ratings, photos and also list what’s included in the price of the tour to simplify your decision making. So, if you’re dying to see Amsterdam, Barcelona and Prague, you can simply enter the city names under the ‘Must See cities’ filter and you’ll find all tours that go through there</p>

          <p>How do I find tours that are leaving when I want to?</p>
          <p>Simply click the “Select a specific date” box underneath the “Departure date” heading on the left hand side of your screen. A calendar will appear, and you can select your preferred start date.
             Be aware that the chances of a tour departing on a specific date are very low, we always recommend user ‘+/-3 days’ option to see the largest selection</p>

          <p>What if I’m only looking to travel a certain amount of days?</p>
          <p>On your left hand side, you will see a section called “Duration” in which there is a blue bar that begins at 3 days and ends at 100+ days. Simply click and drag each white circle to adjust your minimum days and maximum days to suit your needs.</p>

          <p>How do I find a tour that fits my age?</p>
          <p>On the left hand side, scroll down to the section that says “Age Range.” Click each age range to view tours that are designed for that specific age group. You can select multiple age groups.</p>

          <p>I’m interested in certain experiences like sailing or biking, how do I see those kinds of tours?</p>
          <p>On the left hand side you will see a section called “Travel Styles” that has a coloured line on its side. Click each style to view tours that fit that specific type of travel. You can select multiple styles</p>

          <p>Where are the tours that visit all my must-see countries?</p>
          <p>On the left hand side you will see a section called “Must-see countries.” Click on each country to view tours that visit those places. Select “Show more” at the bottom of the list to reveal more choices.</p>

          <p>Where are the tours that travel to the cities I want to see most?</p>
          <p>On the left hand side you will see a section called “Must-see cities.” Click on the grey box that says “Type to search a city” and a pop-up will appear with a list of cities. If you don’t see the city you’re looking for simply type it in manually and click the enter key.</p>

          <p>I know exactly where I want my tour to start and end, how do I find tours that fit my plans?</p>
          <p>On the left hand side you will see a section called “Start & ending city.” Click on the grey box that says “Type to search a city” and a pop-up will appear with a list of cities. If you don’t see the city you’re looking for simply type it in manually and click the enter key. Repeat this step for searching your desired ending city. </p>

          <p>I only want to spend so much, how I do set a price limit so I don’t go over my budget?</p>
          <p>On the left hand side, scroll down to the section that says “Budget.” Click each age range to view tours that are designed for that specific age group. Simply click and drag the circle on the right to adjust your maximum budget</p>

          <p>How do I see all the best deals on BookMyTrek right now?</p>
          <p>On the left hand side you will see a section called “Deals” which you can click on to reveal a selection of current deals being offered by operators. You can select more than one</p>

          <p>I know exactly what operator(s) I want to travel with, how do I find them?</p>
          <p>On the left hand side, you will see that the last section to choose from is called “Operators.” Click to reveal a drop down menu in which you can scroll through operators on BookMyTrek. For even quicker access simply type the operator’s name into the field that says “Type to search for an operator.</p>

          <h2>How can I book a tour?</h2>
          <p>How can I book a tour?</p>
          <p>When you have found your perfect tour, there’s a couple of ways you can book your spot. If you are already on the tour page, click the blue coloured 'Book Now' button on the right-hand side.The 'Book Now' button will create a booking request to secure your seat. Your credit card or Paypal account will be pre-authorised for the payment amount, and only once your seat on the tour is 100% confirmed by the tour operator will we charge your card. On our 'Instant Book' tours, your seat will be immediately confirmed and payment will automatically be taken. For all booking requests, you can use the BookMyTrek booking conversation to speak online directly with the tour operator, or with one of our customer support team members about the progress of your booking.
             email@email.com</p>

          <p>What do I do once I have booked my tour?</p>
          <p>Once your place on a tour is booked all you need to do is start the countdown to the time of your life! Our customer support team will be there to answer any questions you have after booking. For more information on touring please see the help section on ‘Tours’</p>  
 
          <p>How far in advance should I book?</p> 
          <p>We always recommend you book your tour as far in advance as possible, when you know you are able to travel on the tour departure date. The reason being is, all tours have limited spaces so they can book out quickly and we would hate to see you miss out on a space on the tour.</p>
 
          <h2>Tours</h2>
          <p>Touring Terms Explained</p>
          <p>How & Why</p>
 
          <h2>Touring Terms Explained</h2>
          <p>What is a Food Fund?</p>
          <p>A food fund is a cost additional to your base tour price that covers the price of included meals on your tour. Some food funds will need to be paid for at time of booking, while others can be paid locally.</p>
 
          <p>What is a trip kitty?</p>
          <p>A trip kitty covers the costs of local inclusions for your tour. On some tours and in different countries it is not possible to pre-purchase meals, accommodation or extras. For this reason a trip kitty is required, so that the tour guide can make purchases on the road in local currencies. A trip kitty will need to be paid directly to your guide on the first day of your tour. It is an additional cost to the price of your tour and will be listed on your booking page</p>
 
          <p>What is a single supplement?</p>
          <p>A single supplement is an additional fee which you may be required to pay on top of your tour cost if you are solo and would you like your own private room. For the majority of tours, it is optional however some tours do charge a compulsory single supplement for solo travellers which will be stated either in the 'What's Included' section or the main booking page.</p>
 
          <p>What does it mean if a tour is 'On Request'?</p>
          <p>If a tour is almost fully booked or very close to the departure date, the status of the tour becomes ‘On Request’. This means that in order to request a confirmed space on this tour, BookMyTrek will need to process your full payment up front. Then, a request for your place is sent to the local tour operators office to try and add you to this tour departure. It generally takes 2 to 3 business days to receive a response to this request as each hotel & supplier needs to be contacted and confirm availability. If space is available, you'll be booked and confirmed on the tour, if space is not available, then a full refund will be issued. When a tour is On Request full cancellation terms and conditions apply as soon as you make your full payment and the request is sent to the tour operator</p>
          
          <h2>How & Why</h2>
          <p>Can I travel solo?</p>
          <p>Many solo travellers choose to take group tours, in most cases, you will not be charged any extra fees and will be paired up in a twin share room with a traveller of the same gender however this can vary depending on the tour operator. Most tours will have a few solo travellers who easily make friends with the other travellers within the first days of the trip!</p>
 
          <p>Why book a tour with BookMyTrek</p>
          <p>There are many reasons to book a tour with BookMyTrek, including:
            Over 40,000 tours to choose from.
            No booking or credit card fees.
            Best Price Guarantee 
            Earn Unlimited Travel Credits
            To see more details, please see the following link - https://www.BookMyTrek.com/why </p>

          <p> What is in my travel documents?</p>  
          <p>The contents of your travel documents can vary depending on the tour operator you are travelling with however generally they will include: Tour voucher, itinerary, a voucher for transfers and additional accommodation if required</p>
 
          <p>Do I need to print out the travel vouchers?</p>
          <p>It is recommended to print your vouchers as well as having an electronic copy so that you have a backup copy when travelling. However if you do not have the facility to print the voucher, an electronic copy to show your tour guide will be fine</p>
 
          <h2>Payments</h2>
          <p>Making Payments</p>
          <p>Refunds and Cancellations</p>
 
          <h2>Making Payments</h2>
          <p>How can I pay for my tour?</p>
          <p>BookMyTrek accepts payment by PayPal, debit cards and credit cards including Visa, Mastercard and Amex.</p>
  
          <p>How will my final balance of payment be taken?</p>
          <p>After paying your deposit, the final balance will be automatically charged to your credit card, debit card or paypal account on the due date listed on your booking conversation page in the "Payment Details" section. You can also find this information on the invoice that was sent to you when your booking was confirmed.</p>
  
          <p>Can I pay in instalments?</p>
          <p>Yes, you can! BookMyTrekr provides a 0% interest instalment plan to help you budget for your trip. Choose to pay in instalments every two weeks or monthly. After you make your initial payment and your booking is confirmed, payments are split over time and automatically deducted from your designated payment method until the final payment due date. Note that even if you don't choose to pay in instalments, you can manually make payments to your booking whenever it suits you via your BookMyTrek Tour Management section. You can make these payments after you've made your initial payment and before your final payment due date.</p>

          <h2>Refunds and Cancellations</h2>
          <p>For info about cancellations related to Coronavirus go here</p>
          <p>If you have an upcoming tour you booked on BookMyTrek, here's what you need to know about payments and cancellation policies.</p>
          <p>If you're planning to book a tour learn more about our flexible booking policy.</p>
  
          <p>What happens if I want to postpone my trip?</p>
          <p>In response to COVID-19, you can request to convert the amount you paid to Credit for Future Tours. We convert the money you paid into credit. You can use the credit for the same tour or a different tour offered by the same tour operator. Learn more here: www.BookMyTrek.com/credit
          This is only available for some tours, but we’re actively working to enable this for more tours. If you don’t want the credit, you can also change your tour dates to a currently available departure under ‘Modify Booking</p>
 
          <h2>Travel Advice</h2>
          <p>Visas</p>
          <p>Safety</p>

          <h2>Visas</h2>
          <p>Will I need a visa for this tour?</p>
          <p>As a tour booking website, BookMyTrek cannot give visa advice as the visa requirements are regularly being updated and vary for the nationality of one traveller to the next. It is for this reason that we recommend checking with your local embassy or consulate website for the latest up to date information.</p>
 
          <p>My embassy has said I need a Letter of Invitation, can you help?</p>
          <p>If you need a letter of invitation in order to obtain a visa please read the trip notes sent to you at time of booking for the process.  If this is not available please get in touch via the messages section of your booking conversation page and we can assist you with next steps.</p>
 
          <h2>Safety</h2>

          <p>What is travel insurance?</p>
          <p>Insurance can cover the cost of your airfare, as well as your tour in the case of unforeseen cancellation. It ensures that you do not have to worry about any unplanned changes to your travel plans, such as having to rush home to an ill family member or having a tour changed or cancelled because of a natural disaster. It can even help with smaller things - like lost luggage or stolen cameras. When considering which policy is best for you, you may also want to consider what coverage they provide if you have to cancel your booking or if anything happens whilst on tour. We recommend World Nomads travel insurance as just one of many travel insurance companies you can use - click on the following link for more details: https://www.BookMyTrek.com/travel-insurance</p>

            </div>
            <Footer />
            </div>

        )
    }
}
export default Help;