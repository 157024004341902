import React, { Component, useState,useEffect } from 'react';
import NavBar from '../Components/NavBar';
import SearchBox from '../Components/SearchBox';
import SearchEvent from '../Components/SearchEvent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DestinationCard from '../Components/DestinationCard';
import Destinations from '../Components/Destinations';
import AdventureCard from '../Components/AdventureCard';
import  CustomerReviews from '../Components/CustomerReviews';
import Filters from '../Components/Filters';
import Footer from '../Components/Footer';
import Api from '../Service/Api';
import SimilarAdventures from '../Components/SimilarAdventures';
import WhyUs from '../Components/WhyUs';
import SuggestTrip from '../Components/SuggestTrip';

import logo from '../../static/Images/logo.png';
import event from '../../static/Images/event.jpg';

import ArticleCard from '../Components/ArticleCard';
import { Carousel } from '3d-react-carousal';
import { Form ,DropdownButton,Dropdown,Col, Row} from 'react-bootstrap';
import WrtiteReview from '../Components/WriteReview';

const  CustomerReviewss = (props) => {
  const [list, setList] = useState("");
  const [eventid, seteventid] = useState(null);
  const [id, setid] = useState(null);
  useEffect(() => {
      geteventlist()
    }, [ ]);
  const geteventlist = () => {
       let accesstoken = localStorage.getItem("accesstoken");
      const token = `Bearer ${accesstoken}`;
      Api.get(`/events?where={ "status": "active"}&relations=["eventImages"]&limit=10&skip=0`, {
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
          
          setList(
          res.data
          );
          
        })
        .catch((err) => {
           
        });
    };
   
    return (
      <div>
        <NavBar />
        
        
        
        <WrtiteReview />
      
       
       
       
       

        <Footer/>
        </div>
    );
  }


export default CustomerReviewss;
