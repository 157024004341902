import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Badge,
  Carousel,
  Accordion,
  Card,
  Button,
  Image,
  Modal,
  Tabs,
  Tab,
  Container,
} from "react-bootstrap";
import { Label, Input } from "reactstrap";
import BeautyStars from "beauty-stars";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  FaCheck,
  FaCalendar,
  FaMapMarkerAlt,
  FaUsers,
  FaMapMarkedAlt,
  FaUserAlt,
  FaCircle,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { GiFoodTruck } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { FaOpera } from "react-icons/fa";
import { BsGeoAlt } from "react-icons/bs";
import { MdLocalActivity } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import Api from "../Service/Api";
import moment from "moment";
import ViewEvent from "./ViewEvent";
import ButtonCommon from "./ButtonCommon";
import Loader from "./Loader";
import SimilarAdventures from "../Components/SimilarAdventures";

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: "",
      endDate: "",
      startDate: "",
      limit: 10,
      skip: 0,
      eventid: "",
      value: 0,
      eventdatelist: [],
      id: "",
      rating: [],
      faq: [],
      fav: false,
      prevfav: true,
      validated: false,
      validated1: false,
      modalShow: false,
      modalShow2: false,
      question: "",
      avgrate: "",
      email: "",
      phone: "",
      eventDateId:
        this.props &&
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.id,
      up: true,
      down: false,
      up1: false,
      down1: true,
      up2: false,
      down2: true,
      up3: false,
      down3: true,
      up4: false,
      down4: true,
      up5: false,
      down5: true,
      up6: false,
      down6: true,
      up7: false,
      down7: true,
      name: "",
      emailFrom: "",
      emailTo: "",
      eventId: "",
    };
  }

  componentDidMount() {
    // this.getAllEventDate();
    // this.getratinglist();
    // this.getAvgRating();
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== this.state.eventId) {
      window.location.reload();

      this.setData();
    }
  }

  setData = () => {
    let props = this.props;
    this.setState({
      eventId: props && props.id,
    });

    this.getAllEventDate();
    this.getratinglist();
    this.getAvgRating();
  };

  getratinglist = () => {
    Api.get(`/events/${this.props.id}/reviews`)
      .then((res) => {
        this.setState({ rating: res.data });
      })
      .catch((err) => {});
  };

  getAvgRating = () => {
    Api.get(`/events/${this.props.id}/reviews/average`)
      .then((res) => {
        this.setState({ avgrate: res.data });
      })
      .catch((err) => {});
  };

  getAllEventDate = () => {
    Api.get(
      `/events/${this.props.id}/event-dates?relations=["bmtEvent","availability"]`
    )
      .then((res) => {
        this.setState({ eventdatelist: res.data });
      })
      .catch((err) => {});
  };

  emailthistour = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader: true });
      let data = {
        eventId: this.props.id ? parseInt(this.props.id, 10) : null,
        email: this.state.email,
      };

      Api.post(`/misc/email-bmt-event`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ modalShow2: false, loader: false });
        })
        .catch((err) => {
          //  console.log(err.response)
        });
    }
  };

  askQue = (event) => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader2: true });
      let data = {
        question: this.state.question,
        name: this.state.name,
        phone: this.state.phone ? parseInt(this.state.phone, 10) : null,
        email: this.state.email,
      };
      Api.post(`/events/${this.props.id}/user-queries`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ modalShow: false, loader2: false });
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  sendEmail = () => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    this.setState({ modalShow2: false });
    let data = {
      emailFrom: this.state.emailFrom,
      emailTo: this.state.emailTo,
      name: this.state.name,
      eventId: this.props.id ? parseInt(this.props.id, 10) : null,
    };

    Api.post(`/misc/email-friend-bmt-event`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // this.setState({ modalShow: false, loader2: false });
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  daydiff = () => {
    const { list } = this.props;

    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    return a.diff(b, "days");
  };

  render() {
    const { image, place } = this.props;
    let {
      banner,
      endDate,
      startDate,
      eventdatelist,
      rating,
      fav,
      prevfav,
      modalShow,
      modalShow2,
      question,
      avgrate,
      email,
      phone,
      name,
      loader,
      eventDateId,
      up,
      validated1,
      down,
      validated,
      up1,
      down1,
      up2,
      down2,
      up3,
      down3,
      up4,
      down4,
      up5,
      down5,
      up6,
      down6,
      up7,
      down7,
      loader2,
      emailFrom,
      emailTo,
    } = this.state;
    let { list } = this.props;

    return (
      <div>
        <section>
          <ViewEvent list={list} />
        </section>

        <section style={{ paddingTop: "30px" }}>
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <Carousel controls={false} className="carousel-height">
                  {list.eventImages &&
                    list.eventImages.map((item) => (
                      <Carousel.Item>
                        <Image
                          src={item.md}
                          className="image_border carousel-height "
                          style={{ width: "100%" }}
                        />
                      </Carousel.Item>
                    ))}
                </Carousel>
                <div className="favorite">
                  {prevfav && (
                    <FavoriteIcon
                      style={{ color: "grey" }}
                      onClick={() => {
                        this.setState({ fav: true });
                        this.setState({ prevfav: false });
                      }}
                    />
                  )}
                  {fav && (
                    <FavoriteIcon
                      style={{ color: "red" }}
                      onClick={() => {
                        this.setState({ fav: false });
                        this.setState({ prevfav: true });
                      }}
                    />
                  )}
                </div>

                {/* <Col lg={20}>
                <Card className="Card-style Card-style2" >
                  <Card.Body>
                  <Row className="Margin-20">
                      <h2>Overview</h2>
                      <p>{list.description}</p>
                    </Row> 
                </Card.Body>
              </Card>
            </Col> */}
              </Col>

              <Col lg={6} md={12}>
                <Card className="Card-style card-details_mobile">
                  <Card.Body>
                    <Row>
                      <h1> {list.title}</h1>
                    </Row>

                    <Row className="Margin-20">
                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaCalendar className="normaltext icon-style" />
                              <h5 className="normaltext_title">Duration</h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3 className="normaltext">{this.daydiff()} Days</h3>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaMapMarkerAlt className="normaltext icon-style" />
                              <h5 className="normaltext_title">Location</h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3 className="normaltext">{list.location}</h3>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaUsers className="normaltext icon-style" />
                              <h5 className="normaltext_title">Group Size</h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3 className="normaltext">
                            Up to {list.maxGroupSize} people
                          </h3>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <svg
                                // className=""
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-bezier normaltext icon-style"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
                                />
                                <path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z" />
                              </svg>
                              <h5 className="normaltext_title">Difficulty</h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3
                            className="normaltext"
                            style={{ textTransform: "Capitalize" }}
                          >
                            {list.difficulty}
                          </h3>
                        </div>
                      </Col>
                    </Row>

                    <Row className="Margin-20">
                      {/* <Col>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaMapMarkedAlt className="normaltext icon-style" />
                              <h5 className="normaltext_title">
                                Meeting Location
                              </h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3 className="normaltext">{list.meetingLocation}</h3>
                        </div>
                      </Col> */}

                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaUserAlt className="normaltext icon-style" />
                              <h5 className="normaltext_title">Age Range</h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3 className="normaltext">
                            {list.ageMin} to {list.ageMax}
                          </h3>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaCircle className="normaltext icon-style" />
                              <h5 className="normaltext_title">Tour ID</h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3 className="normaltext">{list.tourRef}</h3>
                        </div>
                      </Col>

                      <Col lg={3}>
                        <Row>
                          <Col>
                            <span className="row">
                              <FaOpera className="normaltext icon-style" />
                              <h5 className="normaltext_title">
                                Tour Operator
                              </h5>
                            </span>
                          </Col>
                        </Row>
                        <div className="h3_sub-title">
                          <h3
                            className="normaltext"
                            style={{ textTransform: "Capitalize" }}
                          >
                            {list.tourRef}
                          </h3>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <BeautyStars
                          className="star"
                          value={avgrate && avgrate.rating}
                          size="12px"
                          inactiveColor="grey"
                          activeColor="#ffcc00"
                          // value={item && item.rating}
                        />
                      </Col>
                    </Row>

                    <Row className="Margin-20">
                      {list &&
                        list.tags &&
                        list.tags.map((item, index) => (
                          <Row
                            style={{
                              paddingBottom: "20px",
                              marginLeft: "10px",
                            }}
                          >
                            <Badge className="tags">{item && item.name}</Badge>
                          </Row>
                        ))}
                    </Row>

                    {/* <Row > */}
                    {/* <Col>
                        <BeautyStars
                          value={avgrate && avgrate.rating}
                          size="16px"
                          inactiveColor="grey"
                          activeColor="#ffcc00"
                          // value={item && item.rating}
                        />
                      </Col> */}
                    {/* <Col>
                        <h1>₹{list.amount}</h1>
                      </Col> */}
                    {/* </Row> */}

                    <Row>
                      <Card style={{ marginTop: "10px" }}>
                        <Card.Body>
                          <Row>
                            <Col lg={5}>
                              <p>Select Departure Date</p>
                              <Input
                                type="select"
                                value={this.state.eventDateId}
                                onChange={(event) => {
                                  this.setState({
                                    eventDateId: event.target.value,
                                  });
                                }}
                              >
                                {eventdatelist &&
                                  eventdatelist.map((item) => (
                                    <option value={item && item.id}>
                                      {" "}
                                      {moment(item && item.startDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </option>
                                  ))}
                              </Input>

                              {/* <h3>
                                {moment(item && item.startDate).format("LL")}
                                
                              </h3> */}
                            </Col>
                            <Col lg={3}>
                              <p>Amount</p>
                              <h2 className="text-event-details">
                                ₹{list.amount}
                              </h2>
                            </Col>
                            <Col lg={2} style={{ marginTop: "15px" }}>
                              <Link
                                to={{
                                  pathname: `/bookevent/${this.props.id}`,
                                  state: {
                                    list: list,
                                    eventdatelist: eventdatelist,
                                  },
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                {/* <a className=""> */}
                                <ButtonCommon
                                  className="btncommon book_btn"
                                  label="Book"
                                />
                                {/* </a> */}
                              </Link>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>

                    <Row className="Margin-20">
                      <Col className="viewbtn">
                        <div className="mx-auto">
                          <div style={{ display: "initial" }}>
                            <ButtonCommon
                              className="btncommon btnrounded2"
                              label=" Email This Tour"
                              handleClick={() => {
                                this.setState({ modalShow2: true });
                                this.setState({ modalShow: false });
                              }}
                            />
                          </div>

                          <div
                            style={{ display: "initial", marginLeft: "15px" }}
                          >
                            <ButtonCommon
                              className="btncommon btnrounded2"
                              label="Ask a Question"
                              handleClick={() => {
                                this.setState({ modalShow: true });
                                this.setState({ modalShow2: false });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {modalShow2 && (
                      <Modal
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={modalShow2}
                        onHide={() => this.setState({ modalShow2: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            Email this Tour
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                          >
                            <Tab eventKey="home" title="To Yourself">
                              {loader && <Loader />}
                              {!loader && (
                                <Form
                                  noValidate
                                  validated={validated1}
                                  onSubmit={this.emailthistour}
                                  style={{ marginTop: "20px" }}
                                >
                                  <Form.Row>
                                    <Form.Group
                                      as={Col}
                                      md="12"
                                      controlId="validationCustom03"
                                    >
                                      <Form.Control
                                        type="email"
                                        required
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(event) =>
                                          this.setState({
                                            email: event.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </Form.Row>
                                  <Button
                                    type="submit"
                                    className="btncommon btnrounded"
                                  >
                                    Send Email
                                  </Button>
                                </Form>
                              )}
                            </Tab>
                            <Tab eventKey="friend" title="To a Friend">
                              <Form style={{ marginTop: "20px" }}>
                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom03"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Your Name"
                                      value={name}
                                      onChange={(event) =>
                                        this.setState({
                                          name: event.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom03"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Your Email"
                                      value={emailFrom}
                                      onChange={(event) =>
                                        this.setState({
                                          emailFrom: event.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom03"
                                  >
                                    <Form.Control
                                      type="text"
                                      placeholder="Your Friends Email"
                                      value={emailTo}
                                      onChange={(event) =>
                                        this.setState({
                                          emailTo: event.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </Form.Row>
                                <Button
                                  type="submit"
                                  className="btncommon btnrounded"
                                  onClick={() => this.sendEmail()}
                                >
                                  Send Email
                                </Button>
                              </Form>
                            </Tab>
                          </Tabs>
                        </Modal.Body>
                      </Modal>
                    )}

                    {modalShow && (
                      <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            Ask a Question
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <h4>
                            {" "}
                            Feel free to ask us anything about this tour. A
                            Travel Expert will then get back to you as soon as
                            possible.
                          </h4>
                          {!loader2 && (
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={this.askQue}
                            >
                              <Row>
                                <Col lg="6">
                                  <Form.Group controlId="validationCustom01">
                                    <Label for="exampleEmail">Name</Label>
                                    <Form.Control
                                      required
                                      type="text"
                                      placeholder="Name"
                                      className="inputField"
                                      value={name}
                                      onChange={(e) =>
                                        this.setState({ name: e.target.value })
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group controlId="validationCustom02">
                                    <Label for="exampleEmail">Email</Label>
                                    <Form.Control
                                      required
                                      type="email"
                                      placeholder="Email"
                                      className="inputField"
                                      value={email}
                                      onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group controlId="validationCustom03">
                                    <Label for="exampleEmail">Mobile</Label>
                                    <Form.Control
                                      required
                                      type="number"
                                      placeholder="Mobile"
                                      className="inputField"
                                      value={phone}
                                      onChange={(e) =>
                                        this.setState({ phone: e.target.value })
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Form.Row>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom03"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    required
                                    rows={3}
                                    placeholder="Enter your Question"
                                    value={question}
                                    onChange={(event) =>
                                      this.setState({
                                        question: event.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </Form.Row>
                              <Button
                                type="submit"
                                style={{ width: "100%" }}
                                className="btncommon btnrounded"
                              >
                                Send Question
                              </Button>
                            </Form>
                          )}
                          {loader2 && <Loader />}
                        </Modal.Body>
                      </Modal>
                    )}
                  </Card.Body>
                </Card>

                <Row></Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ paddingTop: "30px" }}>
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="Card-style Card-style2">
                  <Card.Body>
                    <Row className="Margin-20">
                      <h2>Overview</h2>
                      <p>{list.description}</p>
                    </Row>

                    <Row>
                      <Col>
                        <span className="row">
                          <FaMapMarkedAlt className="normaltext icon-style" />
                          <h5 className="normaltext_title">Meeting Location</h5>
                        </span>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <div style={{ paddingTop: "30px" }}>
              <Row>
                <Col lg={12}>
                  <Accordion
                    defaultActiveKey="0"
                    className="h2-text-event-details"
                  >
                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="0"
                      >
                        <Row>
                          <Col>
                            <h2 className="text-event-details">
                              Whats Included
                            </h2>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                {list &&
                                  list.includes &&
                                  list.includes.map((item) => {
                                    return (
                                      <div>
                                        <h3
                                          style={{
                                            textTransform: "capitalize",
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                          }}
                                        >
                                          {item.included === true && (
                                            <FaCheck
                                              style={{
                                                marginRight: "10px",
                                                // color: "green",
                                              }}
                                            />
                                          )}
                                          {item.included === false && (
                                            <FaTimes
                                              style={{
                                                marginRight: "10px",
                                                // color: "red",
                                              }}
                                            />
                                          )}
                                          {item.title}
                                        </h3>
                                        <p>{item.description}</p>
                                      </div>
                                    );
                                  })}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Col>
                          <Col>
                            {down && (
                              <FaChevronDown
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up: false });
                                  this.setState({ down: true });
                                }}
                              />
                            )}
                            {up && (
                              <FaChevronUp
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up: false });
                                  this.setState({ down: true });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card>

                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="1"
                      >
                        <Row>
                          <Col>
                            <h2 className="text-event-details">Itinerary</h2>

                            {list.itineraries &&
                              list.itineraries.map((item) => {
                                return (
                                  <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                      <h1>
                                        Day {""}
                                        {item.day}
                                      </h1>
                                      <h2>{item.title}</h2>
                                      <p>{item.description}</p>

                                      <div>
                                        {item.food != "" && (
                                          <h4>
                                            <GiFoodTruck
                                              style={
                                                {
                                                  // fontSize: "18px",
                                                  //  color: "green"
                                                }
                                              }
                                            />{" "}
                                            {""}
                                            {item.food}
                                          </h4>
                                        )}
                                        {item.foodImage != null && (
                                          <img
                                            src={item.foodImage}
                                            style={{
                                              marginBottom: "20px",
                                              width: "400px",
                                              height: "400px",
                                            }}
                                          />
                                        )}
                                      </div>

                                      <div>
                                        {item.accommodation != "" && (
                                          <h4>
                                            <FaHome
                                              style={
                                                {
                                                  // fontSize: "18px",
                                                  //  color: "green"
                                                }
                                              }
                                            />{" "}
                                            {""}
                                            {item.accommodation}
                                          </h4>
                                        )}
                                        {item.accImage != null && (
                                          <img
                                            src={item.accImage}
                                            style={{
                                              marginBottom: "20px",
                                              width: "400px",
                                              height: "400px",
                                            }}
                                          />
                                        )}
                                      </div>

                                      {item.location !== "" && (
                                        <div>
                                          <h4 className="event-font">
                                            <BsGeoAlt
                                              style={{
                                                fontSize: "18px",
                                                // color: "green"
                                              }}
                                            />{" "}
                                            {""}
                                            {item.location}
                                          </h4>
                                        </div>
                                      )}

                                      {item.activities != null && (
                                        <div>
                                          <h4>
                                            <MdLocalActivity
                                              style={{
                                                fontSize: "18px",
                                                // color: "green"
                                              }}
                                            />
                                            {""} {item.activities}
                                          </h4>
                                        </div>
                                      )}

                                      <h4>
                                        <BiTime />
                                        {""} {item.timeHours}
                                      </h4>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                );
                              })}
                          </Col>
                          <Col className="">
                            {down2 && (
                              <FaChevronDown
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up2: true });
                                  this.setState({ down2: false });
                                }}
                              />
                            )}
                            {up2 && (
                              <FaChevronUp
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up2: false });
                                  this.setState({ down2: true });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card>

                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="2"
                      >
                        <Row>
                          <Col>
                            <h2 className="text-event-details">Kit</h2>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                {list &&
                                  list.kitlist &&
                                  list.kitlist.map((item) => (
                                    <div>
                                      {item.included === true && (
                                        <div>
                                          <h2 className="text-event-details">
                                            Included
                                          </h2>
                                          <p>{item.description}</p>
                                        </div>
                                      )}
                                      {item.included === false && (
                                        <div>
                                          <h2 className="text-event-details">
                                            Excluded
                                          </h2>
                                          <p>{item.description}</p>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Col>
                          <Col className="">
                            {down3 && (
                              <FaChevronDown
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up3: true });
                                  this.setState({ down3: false });
                                }}
                              />
                            )}
                            {up3 && (
                              <FaChevronUp
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up3: false });
                                  this.setState({ down3: true });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card>

                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="3"
                      >
                        <Row>
                          <Col>
                            <h2 className="text-event-details">
                              Recommendation
                            </h2>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>
                                <div className="event-font-size">
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.couple === true && (
                                      <div>
                                        <h4 className="event-font-size">
                                          Couple
                                        </h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.family === true && (
                                      <div>
                                        <h4 className="event-font-size">
                                          Family
                                        </h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.friends === true && (
                                      <div>
                                        <h4>Friends</h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.kids === true && (
                                      <div>
                                        <h4>Kids</h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.lgbtq === true && (
                                      <div>
                                        <h4>LGBTQ</h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.parents === true && (
                                      <div>
                                        <h4>Parents</h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.senior === true && (
                                      <div>
                                        <h4>Senior</h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.solo === true && (
                                      <div>
                                        <h4>Solo</h4>
                                      </div>
                                    )}
                                  {list &&
                                    list.recommendation &&
                                    list.recommendation.youth === true && (
                                      <div>
                                        <h4>Youth</h4>
                                      </div>
                                    )}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Col>
                          <Col className="">
                            {down4 && (
                              <FaChevronDown
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up4: true });
                                  this.setState({ down4: false });
                                }}
                              />
                            )}
                            {up4 && (
                              <FaChevronUp
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up4: false });
                                  this.setState({ down4: true });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card>

                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="4"
                      >
                        <Row>
                          <Col>
                            <h2 className="text-event-details">Good To Know</h2>
                            {list &&
                              list.additionals &&
                              list.additionals.map((item) => (
                                <Accordion.Collapse eventKey="4">
                                  <Card.Body>
                                    <h2 className="text-event-details">
                                      {item.title}
                                    </h2>
                                    <p style={{ marginBottom: "0px" }}>
                                      {item.description}
                                    </p>
                                  </Card.Body>
                                </Accordion.Collapse>
                              ))}
                          </Col>
                          <Col className="">
                            {down5 && (
                              <FaChevronDown
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up5: true });
                                  this.setState({ down5: false });
                                }}
                              />
                            )}
                            {up5 && (
                              <FaChevronUp
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up5: false });
                                  this.setState({ down5: true });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card>
                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="5"
                      >
                        <Row>
                          <Col lg={11}>
                            <h2 className="text-event-details">
                              Customer Review
                            </h2>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body>
                                <Link
                                  to={{
                                    pathname: "/allreviews",
                                    ratinglist: rating,
                                    // ...item,
                                    // index: index,
                                  }}
                                  style={{ textDecoration: "none" }}
                                >
                                  <div className="customer-review-btn">
                                    <ButtonCommon
                                      className="btncommon btnrounded"
                                      label=" See All"
                                    />
                                  </div>
                                </Link>
                                {rating && rating.length == 0 && (
                                  <label>No Reviews!</label>
                                )}
                                {rating.map((item, index) => (
                                  <>
                                    <h3>{item.name}</h3>
                                    <p style={{ marginBottom: "0px" }}>
                                      {item.description}
                                    </p>

                                    <BeautyStars
                                      // value={item.rating}
                                      size="16px"
                                      inactiveColor="grey"
                                      activeColor="#2579f7"
                                      value={item && item.rating}
                                    />
                                  </>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Col>
                          <Col className="">
                            {down6 && (
                              <FaChevronDown
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up6: true });
                                  this.setState({ down6: false });
                                }}
                              />
                            )}
                            {up6 && (
                              <FaChevronUp
                                className="chevron"
                                onClick={() => {
                                  this.setState({ up6: false });
                                  this.setState({ down6: true });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card>

                    <Card className="toogle-card">
                      <Accordion.Toggle
                        className="Card-style card-headers"
                        as={Card.Header}
                        eventKey="6"
                      >
                        <Row>
                          <Col lg={11}>
                            <h2 className="text-event-details">FAQ</h2>
                            {list &&
                              list.faqs &&
                              list.faqs.map((item, index) => (
                                <Accordion.Collapse eventKey="6">
                                  <Card.Body>
                                    <h2 className="text-event-details">
                                      {item && item.question}
                                    </h2>
                                    <p style={{ marginBottom: "0px" }}>
                                      {item && item.answer}
                                    </p>
                                  </Card.Body>
                                </Accordion.Collapse>
                              ))}
                          </Col>
                          {/* <Row> */}
                          <Col lg={1} className="">
                            {down7 && (
                              <FaChevronDown
                                className="chevron faq"
                                onClick={() => {
                                  this.setState({ up7: true });
                                  this.setState({ down7: false });
                                }}
                              />
                            )}
                            {up7 && (
                              <FaChevronUp
                                className="chevron faq"
                                onClick={() => {
                                  this.setState({ up7: false });
                                  this.setState({ down7: true });
                                }}
                              />
                            )}
                          </Col>
                          {/* </Row> */}
                        </Row>
                      </Accordion.Toggle>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: "20px" }} id="upcoming">
              <Col lg={12} className="Card-style card-headers">

                <h2 className="text-event-details">Upcoming departures</h2> */}

              {list && list.category && (
                <SimilarAdventures
                  history={this.props && this.props.history}
                  category={list.category}
                />
              )}
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

export default EventDetails;
