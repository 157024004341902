
import React, { Component } from 'react';
import { Button,Row,Col,Card,Image } from 'react-bootstrap';
import Api from '../Service/Api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import moment from "moment";
import BeautyStars from "beauty-stars";
import AdventureImage from '../../static/Images/adventure.jpg';
import AdventureImage1 from '../../static/Images/bg2.png';
import { FaAdn } from "react-icons/fa";




class CustomerReviewss extends Component {
  state={
    list:[],
    banner:'',
    endDate:'',
    startDate:'',
     limit: 10,
    skip: 0,
    id:'',
  
  }
 
  render() {
    const { image, place } = this.props;
    let {banner,endDate,startDate} =this.state
    let {list}=this.props;
  


    return (
      <div>
         <section className=''
        style={{
            // backgroundImage: `url(${AdventureImage1})`,
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // objectFit:'contain',
            // height:'80vh'
        
          }}
        
        >

      <div className='container'>
      
               <Card className="text-center" style={{marginBottom:"10px"}}>
                <h1 style={{textAlign:'center'}}>Customer Reviews</h1>
                <Card.Body>
                    <Card.Text>
                   <Row>
                     <Col xs={6} md={3}>
                     < Row style={{margin:'auto'}}>
                     <FaAdn style={{fontSize:'50px',margin:'auto'}}/>
                     </Row>
                     <span>Anonymous</span>

                        {/* <Image src={AdventureImage} roundedCircle style={{width:'30px', height:'30px'}} /> */}
                    </Col>
                    <Col xs={6} md={9}>
                    <Row>
                        <Col lg={8} style={{paddingLeft:'0px'}}>
                        <h3 className="event-font" style={{textAlign:'left'}}>Divya Shirodkar</h3>
                        </Col>
                        <Col lg={4}>
                        <div className="event-font-size"><span>written on 8 June, 2020 </span> </div>
                        </Col>
                    </Row>
                    <Row>
                    <BeautyStars
                          // value={item.rating}
                          size="16px"
                          inactiveColor="grey"
                          activeColor="#2579f7"
                         // value={item && item.rating}
                        />  
                        <span style={{paddingLeft:'10px'}}>5</span>
                    </Row>
                    <Row>
                        <p className="event-font-size" style={{textAlign:'left'}}>dksjfsj dadjsdf comment  dksjfsj dadjsdf comment dksjfsj dadjsdf comment dksjfsj dadjsdf comment
                        dksjfsj dadjsdf commentdksjfsj dadjsdf commentdksjfsj dadjsdf commentdksjfsj dadjsdf comment
                        </p>
                    </Row>
                    
                    </Col>

                   </Row>
                    </Card.Text>    
                </Card.Body>
                
                </Card>
      </div>
      </section>
      </div>
     
    );
  }
}
export default CustomerReviewss;