import React, { useState, Component , useEffect} from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {
  Row,
  Col,
  Container,
  Card,
  Button,
  Form,
  Modal,
  Dropdown, Table
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { Label, Input } from "reactstrap";
import Api from "../Service/Api";
import profile from '../../static/Images/profile.png';
import TextField from "@material-ui/core/TextField";

import ButtonCommon from '../Components/ButtonCommon';
import UserTours from '../Components/UserTours';
import NavbarUser from '../Components/NavbarUser';

import { FaUserEdit} from "react-icons/fa";

const UserTour = (props) => {
    const [list, setList] = useState("");
    const [statslist, setstatslist ] = useState("");
    const [featuredlist, setfeaturedlist]= useState("");
    const [destinationslist, setdestinationslist ] = useState("");
    const [adventureslist, setadventureslist]= useState(""); 
    const [operatorslist, setoperatorslist]= useState("");
    useEffect(() => {
        geteventlist();
        geteventstats();
         geteventFeatured();
         geteventDestination();
         geteventOperator();
      }, [ ]);
    const geteventlist = () => {
         let accesstoken = localStorage.getItem("accesstoken");
        const token = `Bearer ${accesstoken}`;
      
        Api.get(`/events?where={ "status": "active"}&relations=["eventImages"]&limit=12&skip=0`, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
             setList(
            res.data
            );
            
          })
          .catch((err) => {
             
          });
      };


      const geteventstats = () => {
         let accesstoken = localStorage.getItem("accesstoken");
        const token = `Bearer ${accesstoken}`;
      
        Api.get(`/events/stats/get-event-stats`, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
               setstatslist(res.data);
            setadventureslist(res.data&&res.data.adventures);
           // setdestinationslist(res.data&&res.data.destinations);
           // setfeaturedlist(res.data&&res.data.featured);
          //  setoperatorslist(res.data&&res.data.operators);
          })
          .catch((err) => {
             
          });
      };

      const geteventFeatured = () => {
         let accesstoken = localStorage.getItem("accesstoken");
        const token = `Bearer ${accesstoken}`;
      
        Api.get(`/events/stats/get-featured?skip=0&limit=8`, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
             setfeaturedlist(res.data);
         
          })
          .catch((err) => {
             
          });
      };

      const geteventDestination = () => {
         let accesstoken = localStorage.getItem("accesstoken");
        const token = `Bearer ${accesstoken}`;
      
        Api.get(`/events/stats/top-destinations?skip=0&limit=10`, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
               setdestinationslist(res.data);
         
          })
          .catch((err) => {
             
          });
      };

      const geteventOperator = () => {
         let accesstoken = localStorage.getItem("accesstoken");
        const token = `Bearer ${accesstoken}`;
      
        Api.get(`/events/stats/top-operators?skip=0&limit=8`, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
               setoperatorslist(res.data);
         
          })
          .catch((err) => {
             
          });
      };

     

  return (
    <div>
    
    <NavbarUser/>
       
<section className='container common-margin' > 
  <UserTours history={props.history} list={list} />

</section>

     
    </div>
  );
};

export default UserTour;
