import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class Cookie extends Component{
    render(){
        return(
            <div>
                <NavBar />
                <div className='container footer-con'>
                <h1>Cookie Policy</h1>
                <p>
                BookMyTrek uses cookies and similar technologies such as pixels or local storage to personalize content, to provide social media features and to
                analyze web traffic. We also share information about your use of our site with our social media platforms, advertising and analytics partners. They
                may combine it with other information that you’ve provided to them or that has been collected from your use of their services. You consent to our
                cookies if you continue to use this website.
                                </p>

                <h2>What are cookies, pixels and local storage?</h2>
                <p>
                        Cookies are small files that a website places on your computer or mobile device as you browse the web. Like many websites, BookMyTrek uses
                    cookies to discover how people are using our services and to help make them work better.
                 </p>
                 <p>
                 A pixel is a small amount of code located on a web page or through an email notification. As many services do, we use pixels to learn whether
                    you’ve interacted with certain web or email content. This helps us measure and improve our services and personalize your experience on
                    BookMyTrek.
                 </p>
                 <p>
                    Local storage is an industry-standard technology that allows a website or application to store information locally on your computer or mobile
                    device. We use local storage to customize what we show you based on your past interactions with BookMyTrek.   
                 </p>

                <h2>Why does BookMyTrek use these technologies?</h2>
                <p>
                    BookMyTrek uses these technologies to deliver, measure, and improve our services in various ways. These uses generally fall into one of the
                    following categories: strictly necessary and functional cookies and advertising cookies.
                </p>
                <h2>How can I change my BookMyTrek cookie settings?</h2>
                <p>
                You can turn Advertising Cookies on and off right here. Strictly Necessary Cookies cannot be turned off.
                </p>
                <h2>Strictly Necessary and Functional Cookies</h2>
                <p>
                    Strictly Necessary and Functional Cookies cannot be turned off. These cookies are essential in order to enable you to move around the website
                    and use the many features and inclusions, such as accessing any secure areas of the website that are in place, as well as improving your overall
                    onsite experience.
                </p>
                <h3>Advertising Cookies</h3>
                <p>
                    We use Advertising Cookies to help us deliver ads, measure their performance, and deliver more relevant content to you based on criteria such
                    as your activity on BookMyTrek and visits to our ad partners' websites. <strong>Opt-in and Opt-out on Browsers</strong>.
                </p>
                <p>
                    In addition, when you use BookMyTrek via a browser, you can change your web browser's settings to reflect your cookie preferences for cookies
directly from the site you are on or for third-party cookies. Each browser is a little different, but usually, these settings are under the "options" or
"preferences" menu. The links below provide information about cookie settings for the browsers supported by BookMyTrek ::
                </p>
                <li className="alinks" href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Microsoft Edge</li>
                <li className="alinks" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</li>
                <li className="alinks" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Firefox</li>
                <li className="alinks" href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</li>
                <li className="alinks" href="https://support.apple.com/kb/ph21411?locale=en_US">Safari</li>

            </div>
            <Footer />
            </div>

        )
    }
}

export default Cookie;