
import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class Contactus extends Component{
    render(){
        return(
            <div>
                <NavBar />
                <div className='container footer-con'>
                <h1>Contact us</h1>

               <h2>
                What is BookMyTrek?
               </h2>
               <p>
                  BookMyTrek, an Online Tourism Event Marketplace Company, makes it easy to find and book something you'll love to do. With access to highquality
                  adventure experiences, there's always something new to discover, both near and far from home. We list the top adventure operators, so
                  you don't have to open multiple tabs on Google, or spend hours doing your research. We're also totally transparent about the operators you're
                  traveling with, so you can check out their profiles, see how much experience they have, and decide whom you want to travel with.               
                  </p>
                  <p>
                    Our awesome search filters help you plan your trip, whether you know exactly what you want to do, or if you're just browsing. You can choose an
                    activity, destination, date, or a combination of any of the above, and find your awesome adventure.
                  </p>
                  <p>
                    It's never too late to make any day extraordinary. Plan ahead, or decide that morning. Change your dates. Or change your mind entirely!
                  </p>

                  <p>
                    We also know that your time off is precious. So we ruthlessly focus on quality, and we specialize in experiences so incredible, you'll want to tell
                    your friends. Spend less time searching, and more time making memories. Discover story-worthy adventure experiences, anytime, anywhere.
                  </p>
                {/*
                  <p>
                    If you're an adventure operator, <a className="alinks">click here</a> to see why you should list with us.
                  </p>
                  <p>
                    If you want to work with us, <a className="alinks">click here</a> to see all the available jobs.
                  </p>*/}

               <h2>Why choose BookMyTrek?</h2>
               <p>We’re here to make it easy for travellers to research and book their adventure.</p>

                <ul>
                  <li>Research</li>
                      <ul>
                        <li>Browse through a wide range of trips and experience levels, and talk directly to operators.</li>
                      </ul>
                </ul>

                <ul>
                  <li>Decide</li>
                        <ul>
                          <li>Access complete information about the trip, destination, and operator.</li>
                        </ul>
                </ul>

                <ul>
                  <li>Choose</li>
                        <ul>
                          <li>Make decisions based on the activity you want OR the dates you want to travel.</li>
                        </ul>
                </ul>

                <ul>
                  <li>Pay</li>
                        <ul>
                          <li>Make online payments safely and in real time.</li>
                        </ul>
                </ul>

                <h2>Are you a tour operator?</h2>
                 <ul>
                  <li>List</li>
                        <ul>
                          <li>Sign up with us for free</li>
                        </ul>
                </ul>

                 <ul>
                  <li>Sell</li>
                        <ul>
                          <li>Get real time online sales for your trips.</li>
                        </ul>
                </ul>

                  <ul>
                  <li>Manage</li>
                        <ul>
                          <li>Access efficient dashboards, calendars and booking systems to make your life easier.</li>
                        </ul>
                </ul>
                <ul>
                  <li>Reach out</li>
                        <ul>
                          <li>Get unlimited customer leads, for free. Talk to a wider audience and showcase your trips.</li>
                        </ul>
                </ul>

                <p>Button to list with us Take user to the Signup as Operator Screen</p>

                <h2>Connect with Us!</h2>
                <p>Want to know more? Join us on <a className="alinks" href="https://www.facebook.com/BookMyTrekApp">Facebook</a> or follow us on <a className="alinks" href="https://www.instagram.com/bookmytrek/">Instagram</a> and <a className="alinks" href="https://www.linkedin.com/company/bookmytrek/">LinkedIn!</a></p>
                <p>Press inquiries contact <a className="alinks">hello@bookmytrek.com</a></p>


           </div>
           <Footer />
           </div>


        )
    }
    }
    export default Contactus;