import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Homepage from "./App/Pages/Homepage";
import ViewAllEvents from "./App/Pages/ViewAllEvents";
import ViewSingleEvent from "./App/Pages/ViewSingleEvent";
import Login from "./App/Pages/Login";
import Register from "./App/Pages/Register";
import ForgotPassword from "./App/Pages/ForgotPassword";
import OperatorRegister from "./App/Pages/OperatorRegister";
import Profile from "./App/Pages/Profile";
import Verification from "./App/Pages/Verification";
import BookEvent from "./App/Pages/BookEvent";
import BookingRedirect from "./App/Pages/BookingRedirect";
import Settings from "./App/Pages/Settings";
import BookingHistory from "./App/Pages/BookingHistory";
import UserBookingDetails from "./App/Pages/UserBookingDetails";
import CustomerReviews from "./App/Pages/CustomerReviews";
import WriteReview from "./App/Pages/WriteReview";
import OperatorList from "./App/Pages/OperatorList";
import OperatorDetails from "./App/Pages/OperatorDetail";
import ScrollToTop from "./App/Components/ScrollToTop";
import UserDashboard from "./App/Pages/UserDashboard";
import UserBookings from "./App/Pages/UserBookings";
import UserTour from "./App/Pages/UserTour";
import WishList from "./App/Pages/WishList";
import Help from "./App/Components/FooterContent/Help";
import Cookie from "./App/Components/FooterContent/Cookie";
import TermsAndCondition from "./App/Components/FooterContent/TermsAndCondition";
import Copyright from "./App/Components/FooterContent/Copyright";
import Contactus from "./App/Components/FooterContent/Contactus";
import Privacy from "./App/Components/FooterContent/Privacy";
import Aboutus from "./App/Components/FooterContent/Aboutus";
import OperatorHelp from "./App/Components/FooterContent/OperatorHelp";
import ListYourTour from "./App/Components/FooterContent/ListYourTour";
import IAmAGuide from "./App/Components/FooterContent/IAmAGuide";
import WhyBookMyTrek from "./App/Components/FooterContent/WhyBookMyTrek";
import WriteYourReview from "./App/Components/FooterContent/WriteYourReview";
import WinATour from "./App/Components/FooterContent/WinATour";
import DestinationDetails from "./App/Pages/DestinationDetail";
import EventsByCategory from "./App/Pages/EventsByCategory";
import DestinationPage from "./App/Pages/DestinationPage";
import BookingDetails from "./App/Pages/BookingDetails";
import BookingDetail from "./App/Components/BookingDetail";
import ChangePassword from "./App/Pages/ChangePassword";
import AllOperators from "./App/Pages/AllOperators";
import CovidPolicyContent from "./App/Components/FooterContent/CovidPolicyContent";
import Maintenance from "./App/Pages/Maintenance";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import UnderMaintenance from "./App/Pages/UnderMaintenance";
import ComingSoon from "./App/Pages/ComingSoon";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/allEvents" component={ViewAllEvents} />
            <Route exact path="/events/:id" component={ViewSingleEvent} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Register} />
            <Route exact path="/Profile" component={Profile} />
            <Route exact path="/user-verification" component={Verification} />
            <Route exact path="/bookevent/:id" component={BookEvent} />
            <Route exact path="/redirect/:id" component={BookingRedirect} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/maintenance" component={Maintenance} />
            {/* <Route exact path="/bookevent" component={BookEvent} /> */}
            <Route exact path="/comingSoon" component={ComingSoon} />
            <Route
              exact
              path="/underMaintenance"
              component={UnderMaintenance}
            />

            <Route exact path="/settings" component={Settings} />
            <Route exact path="/bookinghistory" component={BookingHistory} />
            <Route
              exact
              path="/bookingdetails/:id"
              component={UserBookingDetails}
            />
            <Route exact path="/allreviews" component={CustomerReviews} />
            <Route exact path="/writereview" component={WriteReview} />
            <Route exact path="/operator" component={OperatorList} />
            <Route exact path="/alloperators" component={AllOperators} />
            <Route
              exact
              path="/operator-details/:id"
              component={OperatorDetails}
            />
            <Route
              exact
              path="/destination-details/:destination"
              component={DestinationDetails}
            />
            <Route
              exact
              path="/operator-register"
              component={OperatorRegister}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/user-account" component={UserDashboard} />
            <Route exact path="/user-bookings" component={UserBookings} />
            <Route exact path="/tours" component={UserTour} />
            <Route exact path="/wishlist" component={WishList} />
            <Route exact path="/footer-Help" component={Help} />
            <Route exact path="/contact-us" component={Contactus} />
            <Route exact path="/covid-policy" component={CovidPolicyContent} />
            <Route exact path="/footer-Cookie" component={Cookie} />
            <Route exact path="/footer-Copyright" component={Copyright} />
            <Route
              exact
              path="/footer-Terms-and-condition"
              component={TermsAndCondition}
            />
            <Route exact path="/footer-Privacy" component={Privacy} />
            <Route exact path="/Aboutus" component={Aboutus} />
            <Route
              exact
              path="/footer-operator-help"
              component={OperatorHelp}
            />
            <Route
              exact
              path="/footer-List-your-tour"
              component={ListYourTour}
            />
            <Route exact path="/footer-i-am-a-guide" component={IAmAGuide} />
            <Route
              exact
              path="/footer-Why-Book-my-Trek"
              component={WhyBookMyTrek}
            />
            <Route
              exact
              path="/footer-write-your-review"
              component={WriteYourReview}
            />
            <Route exact path="/footer-win-a-tour" component={WinATour} />
            <Route exact path="/destinations" component={DestinationPage} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route
              exact
              path="/categoryevents/:name/:id"
              component={EventsByCategory}
            />
            {/* <Route exact path='/BookingDetail' component={BookingDetail}/> */}
            {/* <Route exact path='//footer-Copyright' component={Copyright}/> */}
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
