import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class IAmAGuide extends Component{
    render(){
        return(
            <div>
                <NavBar />
                <div className='container footer-con'>
                <h2>HOW IT WORKS</h2>
                <p>We’re building a place for tour guides to network and learn from each other, while showcasing their experience and best travel tips.</p>

                <li><h2>Use your profile as a real-time CV</h2></li>
                <p>With more up to date and relevant information, including the social proof of your travellers satisfaction, your BookMyTrek profile is the perfect place to direct potential employers. With hundreds of Tour Operators already using the BookMyTrekplatform it’s the ideal way to showcase your industry experience.</p>

                <li><h2>Participate in the BookMyTrek Guide of the Year Awards</h2></li>
                <p>Our annual Guide Awards recognise excellence in the guide industry, based on travellers reviews. Collect reviews and check the leaderboard for your ranking.</p>

                <li><h2>Join the conversation on the BookMyTrek blog</h2></li>
                <p>We’re creating Guide specific posts and videos for you to enjoy. Join the conversation by commenting on blog posts to interact with fellow Guides</p>

                <h2>Refer and Earn Travel Credit</h2>
                <p>BookMyTrek  has created a reward program for Guides to earn travel credit and cash. Anytime travellers you have referred to the site book a tour through us, you will earn 5% of the price of the tour that traveller books. The 5% credit is collected in your Travel Fund which can then be redeemed either for a tour booking or cash.
 
                   All you need to do is share your Guide Profile link with your travellers and ensure they submit a review to your profile page to have the 5% attributed to your travel fund.</p>

                <li>Learn from other Guides in the industry</li>   
                <p>By connecting with your peers you can develop your knowledge base and enhance your skills. BookMyTrek is building a platform for Guides to connect and learn from each other in a supportive environment while growing your professional network.</p>

                <li>Connect with other Guides</li>
                <p>Check out other guides profiles and connect via social media. We’ll be building in more tools to keep the conversation rolling on BookMyTrek soon!</p>
                
                <li>invite your Crew</li>
                <p>Invite other Guides to join the BookMyTrek Community. The more invites you send to guides who successfully join, the closer you get to becoming a TourRadar Guru.</p>

                <p>Why join the BookMyTrek Community?</p>
                <p>We're building a space for tour guides to connect globally, to share their passions, advice and build a professional network within the tour industry. With public profiles of your guide experience, quality controlled traveller reviews and a robust platform for discussions, the BookMyTrek community is designed to further tour guides' careers and foster a supportive, fun and useful space online for tour guides.</p>



                </div>
                <Footer/>
                </div>

        )}}
        export default IAmAGuide;