import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
  Col,
  Card,
  Dropdown,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";
import Api from "../Service/Api";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { Container } from "react-bootstrap";

class BookingHistory extends Component {
  state = {
    list: [],
    count: [],
    count: "",
    active: false,
    limit: 10,
    skip: 20,
    status:"",
  };

  nextPage = async () => {
    await this.setState({
      skip: this.state.skip + this.state.limit,
    });
    await this.getproductlist();

 
  };

  previousPage = async () => {
    if (this.state.skip > 0) {
      await this.setState({
        skip: this.state.skip - this.state.limit,
      });
      await this.getproductlist();
    
    }
  };
  componentDidMount() {
    this.getAllBookinglist();
  }

  getAllBookinglist = () => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    // this.setState({ active: true });
    Api.get(
      `/operators/bookings?relations=["user","bmtEvent"]&order={"updatedOn":"DESC"}&filter={"where":{"userId":"1"}}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IndpbGxzb25mZWRzQGdtYWlsLmNvbSIsImlhdCI6MTYwMzUyMzIxOSwiZXhwIjoxNjA1MjUxMjE5fQ.3MvHVE9e4QfwhoAfQPjH9XC6qXiWXOvRqHLcxAn25LQ`,
        },
      }
    )
      .then((res) => {
        
        this.setState({ list: res.data });

   
      })
      .catch((err) => {
        // this.setState({
        //   active: false,
        // });
      });
  };
  getBookinglist = (status) => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    // this.setState({ active: true });
    Api.get(
      `/operators/bookings?relations=["user","bmtEvent"]&order={"updatedOn":"DESC"}&filter={"where":{"bookingStatus":"${status}","userId":"1"}}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IndpbGxzb25mZWRzQGdtYWlsLmNvbSIsImlhdCI6MTYwMzUyMzIxOSwiZXhwIjoxNjA1MjUxMjE5fQ.3MvHVE9e4QfwhoAfQPjH9XC6qXiWXOvRqHLcxAn25LQ`,
        },
      }
    )
      .then((res) => {
         
        this.setState({ list: res.data });

        
      })
      .catch((err) => {
        // this.setState({
        //   active: false,
        // });
      });
  };

  render() {
 
    const { onchange, nextPage, count,list } = this.state;
    return (
      <div>
        <NavBar />
        <Container>
          <h3 className="big-heading">Booking History</h3>
          <Table hover className="mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>UserName</th>
                <th>Event</th>
                <th>Event Name</th>
                <th>Date</th>
                <th>Amount</th>
                {/* <th>Status</th> */}
                <th>
                  <div>
                    <Row>
                      <Label for="exampleSelect">Status</Label>
                      <Input
                        type="select"
                        // value={this.state.sortBy}
                        onChange={(e) => {
                        
                          this.setState({
                            status: e.target.value,
                          });
                          this.getBookinglist(e.target.value)
                        }}
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="successful">Successful</option>
                        <option value="canceled">Canceled</option>
                        <option value="failed">Failed</option>
                      </Input>
                    </Row>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.user && item.user.name}</td>
                    <td>{item.bmtEventId}</td>
                    <td>{item.bmtEvent && item.bmtEvent.title}</td>
                    <td>
                      {moment(item.createdOn).format("DD-MM-YYYY hh:mm a")}
                    </td>
                    <td>{item.totalAmount}</td>
                    <td>{item.bookingStatus}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Container>
        {/* <Row>
          <div
            className="horizantal-align"
            style={{ textAlign: "center", margin: "auto" }}
          >
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {this.state.skip != 0 ? (
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={(e) => this.previousPage(e)}
                      disable={this.state.skip == 0}
                    >
                      Previous
                    </a>
                  </li>
                ) : (
                  <li></li>
                )}

                {this.state.skip < count / 10 ? (
                  <li className="page-item">
                    <a className="page-link" onClick={(e) => this.nextPage(e)}>
                      Next
                    </a>
                  </li>
                ) : (
                  <li></li>
                )}
              </ul>
            </nav>
          </div>
        </Row> */}
        <Footer />
      </div>
    );
  }
}

export default BookingHistory;
