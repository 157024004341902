import React, { Component } from "react";
import NavBar from "./NavBar";
import Api from "../Service/Api";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      eventid: "",
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  getEvents(eventid) {
    Api.get(`/events/${this.state.eventid}?&relations=["eventImages"]`).then(
      (res) => {
        if (res.status === 200) {
          this.setState({ list: res.data });
        }
      }
    );
  }

  render() {
    const { image, place } = this.props;

    let { list } = this.props;

    return (
      <div>
        <div>
          {
            <div className="layer2">
              <section
                style={{
                  backgroundImage: `url(${list.banner})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  objectFit: "contain",
                  height: "75vh",
                }}
              >
              
                <NavBar />
               
                <div className="hero-text-wrapper">
                  <div className="hero-text view-event-title title-sweep-to-right">
                  <div className="eventopacity" style={{ backgroundImage: 'none',
                      backgroundColor: 'black',
                      height: '100%',
                      opacity: '0.5',
                        borderRadius:'0px'
                    }}> </div>
                    <h1 className="banner_color">{list.title}</h1>
                 
                </div>
                </div>
              </section>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Homepage;
