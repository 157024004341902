import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Background from "../../static/Images/register.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Category extends Component {
  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,

        // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 747 },
        items: 2,
        // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 748, min: 0 },
        items: 1,
        // optional, default to 1.
      },
    };
    let { list } = this.props;
    return (
      <div className=" review row-margin-home ">
        <Row>
          {list &&
            list.map((item, index) => (
              <Col lg={2} md={3} xs={6} style={{ padding: "10px" }} key={index}>
                <Link
                  to={{
                    pathname: `/categoryevents/${item && item.title}/${
                      item && item.id
                    }`,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <div className="destdiv">
                    <h2
                      style={{
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {item && item.title}
                    </h2>
                    <div
                      className="Destinations"
                      style={{
                        backgroundImage:
                          item && item.thumbnail
                            ? `url(${item && item.thumbnail})`
                            : `url(${Background})`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "180px",
                        position: "relative",
                      }}
                    ></div>
                    <div
                      className="eventopacity"
                      style={{
                        backgroundImage: "none",
                        backgroundColor: "black",
                        height: "100%",
                        opacity: "0.4",
                      }}
                    ></div>
                  </div>{" "}
                </Link>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}
export default Category;
