import React, { Component } from 'react';
import Footer from "../Footer";
import NavBar from "../../Components/NavBar";


class WriteYourReview extends Component{
    render(){
        return(
   <div>
       <NavBar />
       <div className='container footer-con'>
           <h2>WriteYourReview</h2>
           <p>When did you travel on tour?</p>
           <p>Which tour operator did you travel with?</p>
       </div>
       <Footer />
   </div>


        )}}
        export default WriteYourReview;