import React, { useState, useEffect, Component } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  Form,
  Row,
  Col,
  Badge,
  Container,
  Button,
  Label,
  Image,
} from "react-bootstrap";
import Api from "../Service/Api";
import NavBar from "../Components/NavBar";
import NavbarUser from "../Components/NavbarUser";

import Footer from "../Components/Footer";
import Profile from "./Profile";

//

const UserDashboard = (props) => {
  return (
    <div>
      <NavbarUser />
      <section className="container">
        <Profile />
      </section>
      <Footer />
    </div>
  );
};

export default UserDashboard;
