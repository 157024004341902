import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import arrow from '../../static/Images/right-arrow.svg';

class ArticleCard extends Component {
  render() {
    const { image } = this.props;

    return (
      <div className='article-card'>
        <div
          className='article-image'
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
        <div className='article-card-content'>
          <div className='article-card-text'>
            <h2>Top 10 Things to Do at Lake Del Valle</h2>
            <h3>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla at
              sed inventore ullam earum adipisci vitae eum ipsa possimus
              voluptates. Repellat necessitatibus laudantium corrupti quas?
              Itaque accusamus ullam consequuntur inventore?
            </h3>
          </div>
          <div className='more'>
            <h2>Learn More</h2>
            <img src={arrow} width='30px' />
          </div>
        </div>
      </div>
    );
  }
}
export default ArticleCard;
