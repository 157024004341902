import React, { Component, useState,useEffect } from 'react';
import NavBar from '../Components/NavBar';
import { Form,Row,Col,Badge , Container,
    Carousel,Accordion,Card,Button,Label,
   Image} from 'react-bootstrap';
import SearchBox from '../Components/SearchBox';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import SimilarAdventures from '../Components/SimilarAdventures';
import Footer from '../Components/Footer';
import ViewEvent from '../Components/ViewEvent';
import Bookevent from '../Components/Bookevent';

import ArticleCard from '../Components/ArticleCard';

import Api from '../Service/Api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {IoIosCheckmarkCircle} from "react-icons/io";
import {RiCloseCircleFill} from "react-icons/ri";
import moment from 'moment';
// import Loader from '../Components/Loader'
import Loaders from "../Components/FullScreenLoader";
const BookingRedirect = (props) => {
  const [list, setList] = useState("");
  const [eventid, seteventid] = useState(null);
  const [id, setid] = useState(null);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
       setTimeout(() =>geteventlist(),3000)
    }, [ ]);
  const geteventlist = () => {
      
      let accesstoken = localStorage.getItem("accesstoken");
      const token = `Bearer ${accesstoken}`;
      Api.get(`/users/bookings/${props.match.params.id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => {
           setList(
          res.data
          );
          setLoader(false)
        })
        .catch((err) => {
         });
    };

 
// render() {
  
       
    return (
      <div>
        <NavBar />
       
              <div className=" footer-con">
      
      
              {loader&&<Loaders/>}
     {!loader&&   <section className="container">
         
        {(list.payment.status=="successful"||list.payment.status=="pending")&&  <div style={{textAlign:'center', marginTop:'40px'}}>
          <div class="success-checkmark">
        <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
        </div>
        </div>
          <h1>
              {/* <IoIosCheckmarkCircle style={{color:'green'}} /> */}
              Thank you for Booking!
              </h1>
         
            
          <div className='container' style={{textAlign:'center'}}>
    <p style={{textAlign:'center'}}>Your Booking Ref No {list.payment.transactionId}</p>
                <p style={{fontWeight:'bold'}}>Amount Received:- Rs {list.payment.totalAmount} </p>
                <p>Event Date {moment(list.eventDate.startDate).format("DD-MMM-YYYY")} to  {moment(list.eventDate.endDate).format("DD-MMM-YYYY")} </p>
    <p className="h5" >Payment Status:-  <span  style={{textTransform:'capitalize'}}  className={list.payment.status == "successful" ? "payment_status_sucess": "payment_status_pending"}>{list.payment.status}</span></p>
                <p>
                <Link to={{  pathname: "/"}} style={{textDecoration:'none'}}>

                    <a className='' href='/'>
                      <div className=''>Go to Homepage</div>
                    </a>
                </Link>
                </p>

            </div>
            </div>}

            {list.payment.status=="failed"&& <div className='container' style={{textAlign:'center', marginTop:'40px'}}>
          <h1><RiCloseCircleFill style={{color:'red'}} />Failed Payment</h1>
            
             <div className='' style={{textAlign:'center'}}>
             <p style={{textAlign:'center'}}>Your Booking Ref No {list.payment.transactionId}</p>
                <p>Unfortunately we were unable to process your payment. Please try one of the following:</p>
                <p>1. Double check your credit card number, expiry date and CVV to make sure they are correct.</p>
                <p>2. Try using a another credit card.</p>
                <p>3. Contact your credit card company or bank to make sure there are enough funds on your account and that you are able to make payments.</p>


            </div>
      
          
          </div>}
          {/* <div className='destinations-cards-wrapper1'>
              <SimilarAdventures/>
          </div> */}
        </section>}

        
       </div>
     
       
       
       

        <Footer/>
        </div>
    );
  }


export default BookingRedirect;
