import React, { useState, Component } from "react";
import moment from "moment";
import {
  Form,
  Row,
  Col,
  Badge,
  Container,
  Carousel,
  Accordion,
  Card,
  Button,
  Label,
  Image,
} from "react-bootstrap";

class PaymentAndTravellerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    list:[],
   
  }

  
}

  render() {
    const{list}=this.props;
    return (
      <div>
          <section className="Card-style user_cancellation" style={{marginTop:'30px'}}>
          


         <Row>
          <Col lg={6} style={{marginLeft:'15px'}}>
          
              <Row>
                <Col style={{paddingLeft:'0px', paddingRight:'0px'}}>
              <h2>Payement Details</h2>
              </Col>
              </Row>
         
            <Row className="event-h5" style={{ paddingBottom: "20px" }}>
              <Col>
                <Row>
                  
                      <h5 className="display-day">Booking ID</h5>
                   
                </Row>
                <span className="row">
                  <h3 className="list-display2">{list&&list.payment&&list.payment.bookingId}</h3>
                </span>
              </Col>
              <Col>
                <Row>
                  
                      <h5 className="display-day">Created On</h5>
                   
                </Row>
                <h3 className="list-display row">
                {/* {moment(list&&list.payment&&list.payment.createdOn).format("LL")} */}
                {moment(list&&list.payment&&list.payment.createdOn).format("DD-MM-YYYY hh:mm a")}
                </h3>
              </Col>
            </Row>

           

            <Row className="event-h5" style={{ paddingBottom: "20px" }}>
              <Col>
                <Row>
                 
                      <h5 className="display-day">Status</h5>
                   
                </Row>
                <span className="row">
                  <h3 className="list-display2">{list&&list.payment&&list.payment.status}</h3>
                </span>
              </Col>
              <Col>
                <Row>
                  
                      <h5 className="display-day">Total Amount</h5>
                   
                </Row>
                <h3 className="list-display row">₹{list&&list.payment&&list.payment.totalAmount}</h3>
              </Col>
            </Row>

            <Row className="event-h5" style={{ paddingBottom: "20px" }}>
              <Col >
                <Row>
                  
                      <h5 className="display-day">TransactionID</h5>
                   
                </Row>
                <span className="row">
                  <h3 style={{wordBreak:'break-all'}}>{list&&list.payment&&list.payment.transactionId}</h3>
                  </span>
               
              </Col>
              
              
            </Row>
            {/* </section> */}
          </Col>
        </Row>
        </section>


<section className="Card-style user_cancellation" style={{marginTop:'30px'}}>
            <Row>
                <Col lg={12} >
              <h1>Traveller Details</h1>
              </Col>
              </Row>

        

        <Row>
        
          {list&&list.travellers&&list.travellers.map((item, index)=>(

         
          <Col lg={6} style={{borderRight:'1px solid #ced4da'}}>
            <Row>
              <Col>
          <h4>Traveller {index+1}</h4>
          </Col>
            </Row>
            <Row className="event-h5" style={{ paddingBottom: "20px", paddingLeft:'15px' }}>
              <Col>
                <Row>
                  <Col>
                    <span className="row">
                     <h5 className="display-day"> Name </h5>
                    </span>
                  </Col>
                </Row>
                <span className="row">
                  <h3 className="list-display2"> {item.name} </h3>
                </span>
              </Col>

              <Col>
                <Row>
                  <Col>
                    <span className="main-span">
                      <h5 className="display-day">Gender</h5>
                    </span>
                  </Col>
                </Row>
                <h3 className="list-display"> {item.gender}</h3>
              </Col>
            </Row>

            <Row className="event-h5" style={{ paddingBottom: "20px", paddingLeft:'15px' }}>
              <Col>
                <Row>
                  <Col>
                    <span className="row">
                      <h5 className="display-day">Mobile</h5>
                    </span>
                  </Col>
                </Row>
                <span className="row">
                  <h3 className="list-display2"> {item.mobile}</h3>
                </span>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span className="main-span">
                      <h5 className="display-day">Date Of Birth</h5>
                    </span>
                  </Col>
                </Row>
                <h3 className="list-display"> {moment(item.dob).format("LL")}</h3>
              </Col>
            </Row>

            <Row className="event-h5" style={{ paddingBottom: "20px",paddingLeft:'15px' }}>
              <Col>
                <Row>
                  <Col>
                    <span className="row">
                      <h5 className="display-day">State</h5>
                    </span>
                  </Col>
                </Row>
                <span className="row">
                  <h3 className="list-display2"> {item.state}</h3>
                </span>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span className="main-span">
                      <h5 className="display-day">Country</h5>
                    </span>
                  </Col>
                
                </Row>
                <h3 className="list-display"> {item.country}</h3>
              </Col>
            </Row>
            {/* </section> */}
            <hr style={{marginBottom:'0px'}}/>
          </Col>
           ))}
        </Row>
       
        </section>
      </div>
    );
  }
}
export default PaymentAndTravellerDetails;
