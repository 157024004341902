import React, {Fragment, Component,useState } from 'react';
import { useHistory } from "react-router-dom";


import {
    Container,
    Row,
    Col,
    Image,
    Card,
    Form,
    TextArea,
    Button,
    Modal,
  
  } from "react-bootstrap";
  import {
    CardBody,
    CardTitle,  Label,Input
} from 'reactstrap';

import Api from '../Service/Api';
import NavbarUser from '../Components/NavbarUser';
import ButtonCommon from '../Components/ButtonCommon';

import Loaders from "../Components/FullScreenLoader";

const  ChangePassword = (props) => {
  let accesstoken = localStorage.getItem("token");
  const token = `Bearer ${accesstoken}`;
    const [email, setEmail] = useState("");
    const [currentpass, setCurrentpassword] = useState("");
    const [password, setpassword] = useState("");
    const [Cpassword, setCpassword] = useState("");
    const [err, seterr] = useState("");
    const [message, setMessage] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [active, setactive] = useState(false);
    const [loaders, setLoader] = useState(false);
    let history= useHistory()
    const onSendmsg = event => {
      seterr("")

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        setactive(true);
        setLoader(true);
        let data = {
          currentpass:currentpass,
          password: password,
         // serviceType:props.type
        };
         event.preventDefault();
         let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
if(password==Cpassword){
  Api.post("/auth/change-password", data,
  {
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
  }
  )
  .then(res => {
    
    if (res.status === 201) {
      setLoader(false)
       setMessage("Password Changed Successfully")
      alert("Password Changed Successfully");
      history.push("/user-account");
     
      
   
      setShow(true);
      //  window.location.reload()
    }
  })
  .catch((err) => {
   setactive(false);
   setLoader(false);
 seterr("Current password did not match, Please enter try again")
  });
} else{
  let b="New and confirm  passwords do not match!"
  seterr(b);
  setLoader(false);
}
        


      }
    };
  
    return (
      <>
      <NavbarUser/>
      <div className="container footer-con">
                 <Row>
               
            {loaders&&<Loaders/>}
                <Col lg="12">
                <div className="Card-style " style={{position: "relative",maxWidth: '750px',margin:'auto'}}>
                            <h2 className="text-center">Change Password</h2>
                           
                <div>
     
                  <Row>
                    <Form
                     style={{ 
                       width: "80%" ,
                       margin:'auto'
                      }}
                      className="form2"
                      noValidate
                      validated={validated}
                      onSubmit={onSendmsg}
                    >
                    <Form.Group controlId="formGroup" as={Col} lg={12}>
                        <Form.Control
                          type="text"
                          className="formcontrolE"
                          placeholder="Current Password*"
                          required
                          value={currentpass}
                          onChange={event => setCurrentpassword(event.target.value)}
                        />
                         <Form.Control.Feedback type="invalid">
                         Please Enter Current Password
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formGroup" as={Col} lg={12}>
                        <Form.Control
                          type="text"
                          className="formcontrolE"
                          placeholder="New Password*"
                          required
                          value={password}
                          onChange={event => setpassword(event.target.value)}
                        />
                         <Form.Control.Feedback type="invalid">
                          Password must be longer than or equal to 8 characters!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formGroup" as={Col} lg={12}>
                        <Form.Control
                          className="formcontrolE"
                          placeholder="Confirm Password*"
                          required
                          type="password"
                          value={Cpassword}
                          onChange={event => setCpassword(event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Re-Enter Valid password
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row style={{
                        margin: 'auto',
                        textAlign: 'center'
                      }} >
                        <Col lg="12">
                        {/* <Button className="send Normaltext style-btn" type="submit">
                       Submit
                      </Button> */}
                       <ButtonCommon
                  //  disabled={disable}
                  label="Submit"
                  className="all-events-btn text-center"
                  // handleClick={onSendmsg}
                  type="submit"
                >
                  
                </ButtonCommon>
                      </Col>
                      <Col lg="12">
                      {err && (
                      <lable style={{color:'red'}}>
                     {err}
                      </lable>
                      )}
                      {message && (
                     <label style={{ color: 'green'}}>{message}</label>
                     )}
                      </Col>
                      </Row>
    
                      
                    </Form>
                  </Row>
                </div>
                
              
              </div>
                  </Col>
                
                   </Row>
                   </div>
                   </>
             
             
    );
  };
  
  export default ChangePassword;
  