import React, { Component } from 'react';
import Footer from "../Footer";
import NavBar from "../../Components/NavBar";



class WhyBookMyTrek extends Component{
    render(){
        return(

            <div>
                <NavBar />
                <div className='container footer-con'>

                    <h2>Best Selection</h2>
                    <p>2500+ Carefully Selected Operators</p>
                    <p>Our strict screening process means that you’re only seeing the best quality of tours possible. We have an official partnership with each operator and constantly monitor our travellers’ satisfaction with operators' service through reviews.</p>

                    <p>150,000+ Genuine Traveller Reviews</p>
                    <p>Quickly compare thousands of tours by reading genuine reviews from real travellers. Never worry if you’re booking the right tour again.
                       Check BookMyTrek’s Travellers’ Choice</p>

                    <p>160+ Countries and 40,000+ Tours</p>  
                    <p>With more than 1 million departures, you can explore over 160 countries and experience the destinations at a pace that fits your unique travel style</p> 

                    <h2>Best prices</h2>
                    <li>No booking or credit card fees</li>
                    <p>When you book with a travel agent, you can be charged unexpected fees.BookMyTrek  will never charge you hidden booking or credit card fees.</p>
                    
                    <li>Best Price Guarantee</li>
                    <p>If you find the same tour with the same departure date for a lower price elsewhere, we'll match the price.</p>

                    <li>Earn Unlimited Travel Credits</li>
                    <p>When you book a tour or refer a friend to BookMyTrek , you earn travel credits that can be applied on any future trip you book through us.</p>
 
                    <li>Secure Payment Platform</li>
                    <p>We use Braintree, a subsidiary of PayPal, to make payments convenient, safe and secure.</p>
                    
                    <li>Flexible Payment Options</li>
                    <p>You can pay with a credit card or Paypal account to ensure that your bookings are always easy</p>

                    <li>Trusted by Thousands of Travellers</li>
                    <p>Travellers around the world book and pay via our secure payment platform every day. You can rest assured knowing that your payments are always protected.</p>

                    <h2>Pay for your trip with our 0% interest payment plan. There’s no catch and no hidden fees. Choose the frequency and we’ll automatically process payments using your designated payment method. Payment plans are available for selected departures. For select countries and departures, we also offer financing via one of our trusted partners.</h2>

                    <h2>Tour Experts at your service</h2>
                    <p>Our travel experts offer exceptional service, while providing an unbiased comparison of tours based on both personal and past customer experiences. We're available to answer any questions or concerns via online chat, phone or email.</p>

                    <h2>Local support for worldwide touring</h2>
                    <p>Working from 3 different continents, our Travel Experts are committed to ensuring you receive excellent and timely support from tour operators, no matter where they are located.</p>

                    <p>Sign up for our newsletter</p>
                    <p>Save up to 50% on tours! Get exclusive access to members only deals by email.</p>



                </div>
                <Footer />
            </div>
        )}}
        export default WhyBookMyTrek;