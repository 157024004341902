import React , { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
  
import { Form ,DropdownButton,Dropdown} from 'react-bootstrap';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Price from './Price';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Filter = () => {
    const [startDate, handleStartDateChange] = useState(new Date());
    const [endDate, handleEndDateChange] = useState(new Date());
    const [value, setValue] = React.useState([20, 37]);

    const defaultMaterialTheme = createMuiTheme({
      palette: {
        primary: {
          main: '#002a33',
          light: '#feffff',
          dark: '#002a33',
        },
      },
    });


   

  

  

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
     

  return (
      <>
        <Form.Group>
            <MuiPickersUtilsProvider utils={DateFnsUtils} className='form-date'>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DatePicker
                  label='Select Departure Date'
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Form.Group>

    <Autocomplete 
      multiple
      id="checkboxes-tags-demo"
      options={top100Films}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </React.Fragment>
      )}
      style={{ width: 150, marginBottom:20 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Age Range" placeholder="Age Range" />
      )}
    />

    <Autocomplete 
    multiple
    id="checkboxes-tags-demo2"
    options={difficulty}
    disableCloseOnSelect
    getOptionLabel={(option) => option.title}
    renderOption={(option, { selected }) => (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.title}
      </React.Fragment>
    )}
    style={{ width: 150, marginBottom:20 }}
    renderInput={(params) => (
      <TextField {...params} variant="outlined" label="Difficulty" placeholder="Difficulty Level" />
    )}
  />



  <Autocomplete 
    multiple
    id="checkboxes-tags-demo3"
    options={operator}
    disableCloseOnSelect
    getOptionLabel={(option) => option.title}
    renderOption={(option, { selected }) => (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.title}
      </React.Fragment>
    )}
    style={{ width: 150, marginBottom:20 }}
    renderInput={(params) => (
      <TextField {...params} variant="outlined" label="Operator" placeholder="Operator" />
    )}
  />

   <Typography id="range-slider" gutterBottom >
        Price
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
       // getAriaValueText={valuetext}
      />

      <Price/>
        



  </>
  );
};
export default Filter;
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: '10+', year: 1994 },
  { title: '18+', year: 1972 },
  { title: '30+', year: 1974 },
  { title: '40+', year: 2008 },
  { title: '60+', year: 1957 },
  { title: "70+", year: 1993 },
  

];

const difficulty = [
    { title: 'Easy' },
    { title: 'Moderate' },
    { title: 'Hard' },
    { title: 'Expert'},
];

const operator = [
    { title: 'ABC' },
    { title: 'bbbbb' },
    { title: 'ccccccc' },
    { title: 'DDDD'},
];

const priceInput=[ 
{  0: "1,000"},
 { 1: "1,250"},
  {2: "1,500"},
  {3: "2,000"},
  {4: "2,500"},
  {5: "3,500"},


];