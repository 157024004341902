import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class OperatorHelp extends Component{
    render(){
        return(
            <div>
               <NavBar />
               <div className='container footer-con'>
                  <h2>Credit For Future Tours</h2>
                  <p>What is Credit for Future Tours (previously named Credit on File)</p>
                  <p>BookMyTrek has developed a system which enables Operators to offer passengers a Credit for Future Tours (previously named Credit on File) for payments they have already made so they can re-book their travel at a later date instead of immediately cancelling their plans. (Note: The amount credited to the passengers will be no more than the funds they have already paid and passenger can only use the funds to re-book with your brand).</p>
                  <p>Please see ‘AUTOMATION FEATURES’ at the bottom of this post for ways our team can help with bulk postponements affecting 15 bookings or more.</p>

                  <p>How it works</p>
                  <li>In the Booking Conversation Page (BCP) when a passenger requests to cancel their booking with you or if you have cancelled a departure, if you choose to do so, you can offer them the option of taking their ’Credit for Future Tours' to travel at a later date instead of paying a cancellation fee or offering a refund right away</li>
                  <li>The more flexible you are, the more likely a customer will not want to cancel. In order to apply a Credit for Future Tour to a booking, you should decide in which cases you will provide credit on file to the customer - any or all of them can apply for your brand:
                      1.If customer has already made full payment and wants to cancel a tour you are still operating
                      2.If the customer has only made deposit payment and wants to cancel a tour you are still operating
                      3.If you as the operator are postponing the trip and the customer has only paid the deposit
                      4.If you as the operator are postponing the trip and the customer has already made full payment</li>
                   <li>As the Operator, in the BCP you will need to confirm you are able to offer the Credit for Future Tour and the terms and conditions. We have drafted some standard terms and conditions which you can review and adapt to your own purposes or you can provide your own terms and conditions - it all depends on what you want to offer your customers</li>
                   <li>Once you have confirmed these details the passenger will have to agree to these terms and conditions and once they agree the booking will automatically be transferred to Credit for Future Tour. We will place all of the funds paid on to our Credit for Future Tour feature which holds the funds secure for the customer to use with your brand in the future</li>
                   <li>Once the passenger has decided on a new travel date, the customer will request the booking through the same BCP they had for their original booking so you will not lose any previous communication with the passenger. Our Operator Support Team can assist you and your teams in updating the BCP to show the new tour and departure dates the customer is requesting and then the system will work as per normal bookings</li>
                   <p>This is an ‘opt-in’ feature. You do not have to use it as we understand that it won’t suit all Tour Operators and their specific contracting arrangements but is a proactive measure we’ve taken to encourage customers to postpone their travels with operators instead of canceling them.
                      AUTOMATION FEATURES: If you need to postpone tours due to Covid19 and you have more than 15 bookings through BookMyTrek  which are affected, please contact your Business Development Manager or email us - we have a process which can automatically transfer the affected BCP’s from ‘Confirmed Booking’ to ‘Credit for Future Tour’ status and we an add a message from you regarding the updated terms and conditions. This streamlines the process and will help reduce the volume of calls and messages you receive.</p>

                   <p>Opt into Credit for Future Tours</p>      
                   <p>If you would like to opt into the Credit for Future Tours feature please email the Business Development Team with your answers to the below questions.</p>

                   <p>1. Would you like to apply ‘Credit for Future Tours’ to both existing and future bookings you receive on BookMyTrek ?</p>

                   <p>2. For which departure dates can 'Credit for Future Tours' be applied to for exisiting and future bookings?</p>

                   <p>3. When does the traveler need to rebook and travel by? (For example; Rebook by 31.12.2021 and Travel by 31.12.2022)</p>

                   <p>4. Are there any costs or amendment fees applicable for passengers who choose to use ‘Credit for Future Tours’?</p>

                   <p>5. Do you have any specific terms and conditions the passenger should be aware of?</p>

                   <p>Operators who opted in to Credit for Future Travel for future bookings, will be featured on a dedicated landing page that will display Operators who have the ability to offer the Credit on File option longer term. </p>

                   <p>This is to show potential passengers the flexibility available in hopes this will inspire them to continue booking and support the Tourism Industry at this time. If you are able to offer this, please contact us and we will add you to the landing page</p>

                   <p>Payout & Payment Terms for Credit for Future Tours</p>
                   <p>If you wish to offer Credit for Future Tours (CFFT) to a booking, BookMyTrek will not take any upcoming final payments from the passenger, instead we will suspend the current booking and offer credit based on the money paid to date. For example, if only the deposit has been collected, only this amount will be added to CFFT.
                      These funds will be retained in BookMyTrek ’s separate trust account and processed per your payment terms, according to the new chosen date the passenger re-books for.
                      Any balance payments required will be taken from the passenger once they rebook</p>

                   <h2>Get started on BookMyTrek </h2>   
                   <p>BookMyTrek Application</p>
                   <p>Thank you for your interest in working with BookMyTrek .</p>
                   <p>We are not currently processing new applications as the Business Development Team is focusing on working with our current 2,500 partners to maximise their sales opportunities.
                      Once our team is ready to focus their attention on new operators, we will once again open the application process for new operators that are offering products for our in-demand destinations.
                      To register your interest in joining BookMyTrek please fill out the Waitlist form. 
                      Thank you for your understanding.</p>

                   <p>BookMyTrek  Main Contacts</p>  
                   <p>If your application has not yet been approved and you need assistance or to flag a technical bug during the application process, please email the Application Team.
                      If your application has been approved and you are unclear on the next steps, please email the Business Development Team.
                      If you are looking for assistance in regards to individual bookings, cancellations or refunds, or support on the booking conversation page, please email the Operator Support Team.
                      If you've already been working with us for a long time, you should contact your account manager that has been assigned to you for any assistance. If you are not sure who your account manager is, contact the Business Development Team for further assistance</p>

                  <h2>Operator Dashboard</h2>  
                  <h2>Manage Profile</h2>
                  <p>Update Password</p>
                  <p>In order to update your Password log in to yourBookMyTrek  Operator Dashboard. On the top right-hand corner click on your company name to access the drop-down menu where you will select Profile - General</p>
                  <li>Type your new password into the 'Password' section</li>
                  <li>Confirm the new password by retyping into the 'Repeat Password' section</li>
                  <li>Click ‘Save Operator’ on the right-hand side</li>

                  <p>Contact Information</p>
                  <p>In order to update your 'Contact Information' login to yourBookMyTrek  Operator Dashboard. On the top right-hand corner click on your company name to access the drop-down menu where you will select 'Profile' - 'Contact'.</p>
                  <li>Fill in the text box with the applicable Contact Information and Extended Contact information (Reservations Team) </li>
                  <li>Click the 'Save Contact Data' on the right-hand side of the page.</li>

                  <p>Operator Page</p>
                  <p>In order to update anything on the 'Operator Page' log in to your BookMyTrek  Operator Dashboard. On the top right-hand corner click on your company name to access the drop-down menu where you will select 'Profile' - 'Operator Page'. </p>

                  <p> Add/Edit Company Logo</p>
                  <li>Next to your logo select the button 'Change logo'</li>
                  <li>Upload your logo and take care that the image has a good resolution (400px*400px minimum)</li>
                  <li>Save the changes by clicking the 'Save Operator Page Data'</li>

                  <p> Add/Edit Company Highlights</p>
                  <li>Under the 'Highlights' section add your companies highlight in the text box provided</li>
                  <li>Please take note of the prompts on the right-hand side </li>
                  <li>Save the changes by clicking the 'Save Operator Page Data'</li>

                  <p>Add/Edit your Business Description</p>
                  <li>Under the 'Business Description' section add your company description in the text box provided</li>
                  <li>Please take note of the prompts on the right-hand side</li>
                  <li>Save the changes by clicking the 'Save Operator Page Data'</li>

                  <p>Allowed Age Group & Group Size </p>
                  <li>Enter your Minimum, Maximum & Average Age Group and Group Size </li>
                  <li>Please take note of the prompts on the right-hand side</li>
                  <li>Save the changes by clicking the 'Save Operator Page Data'</li>

                  <p>Additional Information </p>
                  <li>Please advise if you provide Trip Voucher, Travel Documents or whether Travel Insurance is required for confirmed passengers</li>
                  <li>Please advise if you can offer Airport Transfers and Pre/Post Accommodation services</li>
                  <li>Please take note of the prompts on the right-hand side</li>
                  <li>Save the changes by clicking the 'Save Operator Page Data</li>

                  <p>Email Notifications</p>
                  <p>In order to add/update 'Email Notification' log in to your BookMyTrek  Operator Dashboard. On the top right-hand corner click on your company name to access the drop-down menu where you will select 'Profile' - 'Email Notifications'</p>

                  <p>New Tour Enquiry Email </p>
                  <li>Input up to 3 email recipients that you wish to receive a 'New Tour Enquiry Emails'</li>

                  <p>Review Notification Email </p>
                  <li>Input up to 3 email recipients that you wish to receive a 'New Review Notification Email'</li>

                  <p>Invoice Email </p>
                  <li>Input 1 email recipient that you wish to receive all 'BookMyTrek  Invoice Emails</li>

                  <p>Bookings & Finance</p>
                  <p>In order to update the 'Bookings & Finance' section, log in to your BookMyTrek  Operator Dashboard. On the top right-hand corner click on your company name to access the drop-down menu where you will select 'Profile' - 'Bookings & Finance'</p>

                  <p>Instant Book</p>
                  
                  <li>Select whether you are able to offer your tours on an 'Instant Book' basis </li>
                  <li>Enter 'Minimum Days Before Departure' in the text box provided</li>
                  <li>Enter 'Minimum Available Seats On Departures' in the text box provided</li>
                  <li>Please take note of the prompts on the right-hand side</li>
                  <li>Save the changes by clicking the 'Save Operator'</li>

                  <p>Terms & Conditions</p>

                  <li>Enter the URL link to your company's Booking Terms & Conditions on your website</li>
                  <li>Copy the terms and conditions text and paste into Terms & Conditions text box</li>
                  <li>Please take note of the prompts on the right-hand side</li>
                  <li>Save the changes by clicking the 'Save Operato</li>

                  <p>Enable BookMyTrek  Booking Notifications</p>
                  <p>To help you stay on top of all booking requests we have a feature that will create notifications through your web browser every time a new enquiry is received.
                     By clicking on the notification you will be immediately redirected to your 'Operator Dashboard' - 'Enquiries & Bookings' section to answer any pending requests. Fast responses lead to more bookings, higher conversions and better ranking on BookMyTrek pages.
                     To activate this new feature simply log in to your Operator Dashboard and click 'Allow' notifications on the pop-up screen.
                     If you do wish to change the setting in the future, there is a specific link for each browser which allows you to amend your notification settings. Simply click the link below for your specific browser:</p>
                   <li>Chrome</li>
                   <li>Safari</li>
                   <li>Opera</li>
                   <li>Firefox</li>
                   <p>Once you have clicked on the link, find BookMyTrek  in the list and make the changes required. </p>
                   
                   <p>Updating Operator Profile when labeled admin</p>
                   <p>In order to add/update fields in the operator profile labeled admin, please get in touch with your BDM or email </p>



                </div>
                <Footer />
            </div>

        )
    }
}
 export default OperatorHelp;