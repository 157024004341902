
import React, { Component } from 'react';



class Maintenance extends Component{
    render(){
        return(
            <div>
               

           <div id="notfound" className="maintence">
              <div class="notfound">
               
                <div class="notfound-404">

                  <h1>Oops!</h1>
                  <h2>404 - The Page can't be found</h2>
                </div>
                
              </div>
            </div>
         
           </div>


        )
    }
    }
    export default Maintenance;