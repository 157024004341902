import React, { useState, Component } from "react";
import {
  Form,
  Row,
  Col,
  Badge,
  Container,
  Carousel,
  Accordion,
  Card,
  Button,
  Image,
  Modal,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import ButtonCommon from "./ButtonCommon";
import NavBar from "./NavBar";
import SearchBox from "../Components/SearchBox";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateTimePicker from "react-datetime-picker";
import { Label, Input } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FaMinusCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
import PaymentCancellationTerms from "./PaymentCancellationTerms";
import PaymentTermsAndCondition from "./PaymentTermsAndCondition";
import Api from "../Service/Api";
import Loaders from "./FullScreenLoader";

class Bookevent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      banner: "",
      endDate: "",
      startDate: "",
      limit: 10,
      skip: 0,
      eventid: "",
      counter: 0,
      loader: true,
      FormCount: 0,
      daypicker: false,
      ch: false,
      email: "",
      name: "",
      doberr: true,
      mobile: "",
      gender: "",
      dob: "",
      days: "",
      country: "",
      modalShow: false,
      modalShow1: false,
      modalShow2: false,
      modalShow3: false,
      dateid: "",
      err: "",
      ids: 0,
      validated: false,
      countrylist: ["India"],
      newlist: ["India"],
      travellers: [],
      eventdatelist: [
        {
          id: 14,
          startDate: "2020-11-28T06:30:00.000Z",
          endDate: "2020-11-28T06:30:00.000Z",
        },
      ],
      zipcode: "",
      loader: false,
    };
  }

  componentDidMount() {
    this.setState({ loader: true });
    Api.get(`/events/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ list: res.data, loader: false });
      })
      .catch((err) => {});

    Api.get(
      `/events/${this.props.match.params.id}/event-dates?relations=["bmtEvent","availability"]`
    )
      .then((res) => {
        this.setState({ eventdatelist: res.data });

        var i = 0;
        res.data &&
          res.data.map((item, index) => {
            if (moment().diff(moment(item && item.startDate), "days") <= 0) {
              if (i == 0) {
                this.setState({ ids: index, eventDateId: item.id });
                i++;
              }
            }
          });
      })
      .catch((err) => {});
  }

  setid = (id) => {
    localStorage.setItem("eventid", id);
  };

  handleChanges = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  daydiff = () => {
    const { state } = this.props.history.location;
    let { list, eventdatelist } = state || {};
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    var c = a.diff(b, "days") + 1;
    return c;
  };

  handleSelect = (evt, i) => {
    this.setState({ [`country${i}`]: this.state.countrylist[evt] });
    this.setState({ newlist: [""] });
  };

  getCountrylist = (word) => {
    this.setState({ country: word });
    this.setState({ newlist: this.state.countrylist });

    const lowercasedValue = word.toLowerCase();

    const filteredData = this.state.countrylist.filter((el) =>
      el.toLowerCase().includes(lowercasedValue)
    );

    this.setState({ newlist: filteredData });
  };

  onSubmit = () => {
    const {
      travellers,
      eventDateId,
      name1,
      email1,
      dob1,
      mobile1,
      gender1,
      address1,
      list,
      state1,
      country1,
      zipcode1,
      err,
    } = this.state;
    let { ageMin, ageMax } = list || {};
    let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    // const form = event.currentTarget;

    const token = `Bearer ${accesstoken}`;
    this.setState({ validated: true });
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();

    // }
    if (
      this.state.counter != 0 &&
      name1 &&
      email1 &&
      dob1 &&
      mobile1 &&
      gender1 &&
      address1 &&
      state1 &&
      country1 &&
      this.state.ch &&
      zipcode1
    ) {
      if (
        moment().diff(moment(dob1), "years") > ageMin &&
        moment().diff(moment(dob1), "years") < ageMax
      ) {
        this.setState({ loader: true, validated: false });
        var data = [];
        for (let j = 1; j <= this.state.counter; j++) {
          if (j == 1) {
            data.push({
              name: eval(`this.state.name${j}`),
              email: eval(`this.state.email${j}`),
              dob: moment(eval(`this.state.dob${j}`)).toISOString(), //eval(moment(`this.state.dob${j}`).toISOString()),
              mobile: eval(`this.state.mobile${j}`),
              gender: eval(`this.state.gender${j}`),
              address: eval(`this.state.address${j}`),
              state: eval(`this.state.state${j}`),
              zipcode: eval(`this.state.zipcode${j}`)
                ? parseInt(eval(`this.state.zipcode${j}`), 10)
                : null,
              country: eval(`this.state.country${j}`),
              healthdetails: eval(`this.state.healthdetails${j}`),
            });
          } else {
            data.push({
              name: eval(`this.state.name${j}`),
              dob: moment(eval(`this.state.dob${j}`)).toISOString(),
              gender: eval(`this.state.gender${j}`),
              country: eval(`this.state.country${j}`),
              healthdetails: eval(`this.state.healthdetails${j}`),
            });
          }
        }

        let data1 = {
          travellers: data,
          eventDateId: eventDateId ? parseInt(eventDateId, 10) : null,
        };

        Api.post(`/bookings`, data1, {
          headers: {
            "content-type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
            if (res.status == 201) {
              this.setState({ loader: false });
              const options = {
                key: process.env.REACT_APP_RAZOR,
                name: "Book My trek",
                description: "Unique journey",
                order_id: res.data.razorPayOrderId,
                amount: res.data.totalAmount * 100,
                handler: async (response) => {
                  try {
                    this.props.history.push(`/redirect/${res.data.id}`, {
                      status: "Success",
                    });
                    //alert(`Payment Success. Payment Id ${paymentId}`);
                  } catch (err) {
                    this.props.history.push(`/redirect/${res.data.id}`, {
                      status: "Failure",
                    });
                    // alert(err);
                  }
                },
                prefill: {
                  //   name: user.displayName,
                  email: this.state.email1,
                  contact: this.state.mobile1,
                },
                theme: {
                  color: "#FDD72A",
                },
              };
              const rzp1 = new window.Razorpay(options);
              rzp1.open();
            }
          })
          .catch((err) => {
            this.setState({ err: "Enter travellers details" });
            //alert("Please enter traveller details")

            this.setState({ loader: false });
          });
      } else {
        alert(`Age should be between ${ageMin} and ${ageMax}`);
      }
    } else {
      this.setState({ modalShow3: true });
      // alert("Enter traveller details");
      //  alert(`${err.response.data.message}`)
    }
  };

  percen = (list) => {
    var number = list && list.amount;
    var percentToGet = list && list.discount; //Calculate the percent.
    var percent = (percentToGet / 100) * number;
    return percent;
  };

  getTravellerForm = (FormCount) => {
    const {
      err,
      validated,
      name1,
      country1,
      gender1,
      dob1,
      name2,
      country2,
      gender2,
      dob2,
      name3,
      country3,
      gender3,
      dob3,
      name4,
      country4,
      gender4,
      dob4,
      email1,
      mobile1,
      address1,
      state1,
      zipcode1,
      name5,
      country5,
      gender5,
      dob5,
      name6,
      country6,
      gender6,
      dob6,
      name7,
      country7,
      gender7,
      dob7,
      name8,
      country8,
      gender8,
      dob8,
      name9,
      country9,
      gender9,
      dob9,
      name10,
      country10,
      gender10,
      dob10,
      name11,
      country11,
      gender11,
      dob11,
      name12,
      country12,
      gender12,
      dob12,
      name13,
      country13,
      gender13,
      dob13,
      name14,
      country14,
      gender14,
      dob14,
      name15,
      country15,
      gender15,
      dob15,
      name16,
      country16,
      gender16,
      dob16,
      name17,
      country17,
      gender17,
      dob17,
      name18,
      country18,
      gender18,
      dob18,
      name19,
      country19,
      gender19,
      dob19,
      name20,
      country20,
      gender20,
      dob20,
      name21,
      country21,
      gender21,
      dob21,
      name22,
      country22,
      gender22,
      dob22,
      name23,
      country23,
      gender23,
      dob23,
      name24,
      country24,
      gender24,
      dob24,
      name25,
      country25,
      gender25,
      dob25,
      name26,
      country26,
      gender26,
      dob26,
      name27,
      country27,
      gender27,
      dob27,
      name28,
      country28,
      gender28,
      dob28,
      name29,
      country29,
      gender29,
      dob29,
      name30,
      country30,
      gender30,
      dob30,
      name31,
      country31,
      gender31,
      dob31,
      name32,
      country32,
      gender32,
      dob32,
      healthdetails1,
      healthdetails2,
      healthdetails3,
      healthdetails4,
      healthdetails5,
      healthdetails6,
      healthdetails7,
      healthdetails8,
      healthdetails9,
      healthdetails10,
      healthdetails11,
      healthdetails12,
      healthdetails13,
      healthdetails14,
      healthdetails15,
      healthdetails16,
      healthdetails17,
      healthdetails18,
      healthdetails19,
      healthdetails20,
      healthdetails21,
      healthdetails22,
      healthdetails23,
      healthdetails24,
      healthdetails25,
      healthdetails26,
      healthdetails27,
      healthdetails28,
      healthdetails29,
      healthdetails30,
      healthdetails31,
      healthdetails32,
      loader,
    } = this.state;
    var travellers = [];
    for (let j = 1; j <= this.state.counter; j++) travellers.push(j);

    return (
      <div>
        {loader && <Loaders />}
        {travellers.map((i) => (
          <div key={i}>
            {i == 1 && (
              <div>
                <h2 className="event-row-pd">Traveller-{i}</h2>

                <Form
                  noValidate
                  validated={validated}
                  //onSubmit={this.onSubmit}
                >
                  <Row>
                    <Col>
                      <Form.Group controlId="validationCustom01">
                        <Label for="exampleEmail">Name</Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Name"
                          className="inputField"
                          name={`name${i}`}
                          value={eval(`name${i}`)}
                          onChange={this.handleChanges}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter full name
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustom02">
                        <Label for="exampleEmail">Email</Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="email"
                          className="inputField"
                          name={`email${i}`}
                          value={eval(`email${i}`)}
                          onChange={this.handleChanges}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your email
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustom03">
                        <Label for="exampleEmail">Mobile</Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="mobile"
                          className="inputField"
                          name={`mobile${i}`}
                          value={eval(`mobile${i}`)}
                          onChange={this.handleChanges}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter mobile number
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustom04">
                        <Label for="exampleEmail">Gender</Label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          name={`gender${i}`}
                          value={eval(`gender${i}`)}
                          onChange={this.handleChanges}
                        >
                          <option value="">--Select--</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Input>
                        <Form.Control.Feedback type="invalid">
                          Please select gender
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustom05">
                        <Label for="exampleEmail">Date of Birth</Label>
                        <Form.Control
                          id="date"
                          label="Date of Birth"
                          required
                          type="date"
                          defaultValue="YYYY-MM-DD"
                          name={`dob${i}`}
                          value={eval(`dob${i}`)}
                          onChange={this.handleChanges}
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please select date of birth
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="validationCustom03">
                        <Label for="exampleEmail">Address</Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          required
                          type="text"
                          placeholder="Enter Address"
                          className="inputField"
                          name={`address${i}`}
                          value={eval(`address${i}`)}
                          onChange={this.handleChanges}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter address
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Row>
                        <Col>
                          <Form.Group controlId="validationCustom03">
                            <Label for="exampleEmail">State</Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter State"
                              className="inputField"
                              name={`state${i}`}
                              value={eval(`state${i}`)}
                              onChange={this.handleChanges}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter state
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="validationCustom03">
                            <Label for="exampleEmail">Pin Code</Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Enter Zipcode"
                              className="inputField"
                              name={`zipcode${i}`}
                              value={eval(` zipcode${i}`)}
                              onChange={this.handleChanges}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter zipcode
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group controlId="validationCustom08">
                        <Label for="exampleEmail">Country</Label>

                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          name={`country${i}`}
                          value={eval(`country${i}`)}
                          onChange={this.handleChanges}
                        >
                          <option value="">--Select--</option>
                          {this.state.newlist &&
                            this.state.newlist.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                        </Input>
                        <Form.Control.Feedback type="invalid">
                          Please select country
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="validationCustom03">
                        <Label for="exampleEmail">Other details</Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder="Enter details of health or any medical issues about health"
                          className="inputField"
                          name={`healthdetails${i}`}
                          value={eval(`healthdetails${i}`)}
                          onChange={this.handleChanges}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}

            {i != 1 && (
              <div>
                <hr></hr>
                <h2 className="event-row-pd">Traveller-{i}</h2>
                {err && <label style={{ color: "red" }}>{err}</label>}

                <Form
                  noValidate
                  validated={validated}
                  //  onSubmit={onSendmsg}
                >
                  <Row>
                    <Col>
                      <Form.Group controlId="validationCustom01">
                        <Label for="exampleEmail">Name</Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Name"
                          className="inputField"
                          name={`name${i}`}
                          value={eval(`name${i}`)}
                          onChange={this.handleChanges}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter full name
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="validationCustom04">
                        <Label for="exampleEmail">Gender</Label>
                        <Input
                          required
                          type="select"
                          name="select"
                          id="exampleSelect"
                          name={`gender${i}`}
                          value={eval(`gender${i}`)}
                          onChange={this.handleChanges}
                        >
                          <option value="">--Select--</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Input>
                        <Form.Control.Feedback type="invalid">
                          Please select gender
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustom05">
                        <Label for="exampleEmail">Date of Birth</Label>
                        <Form.Control
                          id="date"
                          label="Date of Birth"
                          type="date"
                          defaultValue="YYYY-MM-DD"
                          name={`dob${i}`}
                          value={eval(`dob${i}`)}
                          onChange={this.handleChanges}
                          // className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          Please enter date of birth
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="validationCustom08">
                        <Label for="exampleEmail">Country</Label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          name={`country${i}`}
                          value={eval(`country${i}`)}
                          onChange={this.handleChanges}
                        >
                          <option value="">--Select--</option>
                          {this.state.newlist &&
                            this.state.newlist.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                        </Input>
                        <Form.Control.Feedback type="invalid">
                          Please select country
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="validationCustom03">
                        <Label for="exampleEmail">Other details</Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder="Enter details of health or any medical issues about health"
                          className="inputField"
                          name={`healthdetails${i}`}
                          value={eval(`healthdetails${i}`)}
                          onChange={this.handleChanges}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { image, place } = this.props;
    let {
      banner,
      //  endDate,
      //  startDate,

      FormCount,
      daypicker,
      sdate,
      name,
      mobile,
      gender,
      dob,
      email,
      modalShow,
      modalShow1,
      modalShow2,
      modalShow3,
      err,
    } = this.state;
    let { list, eventdatelist, loader } = this.state || {};
    let { startDate, endDate } = list;
    let Auth = localStorage.getItem("Auth");

    return (
      <div>
        <div
          style={{
            backgroundImage: `url(${list.banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            objectFit: "contain",
            opacity: "0.1",
            width: "100%",
            position: "absolute",
            height: "100%",
            zIndex: "1",
            minHeight: "100vh",
          }}
        ></div>
        {loader && <Loaders />}

        <div>
          <section
            className="container"
            style={{
              paddingBottom: "50px",
              paddingTop: "50px",
              position: "relative",
              zIndex: 20,
            }}
          >
            <div className="">
              <Row style={{ marginTop: "50px" }}>
                {/* <Col lg={8}>
                <h1 style={{ textTransform: "Capitalize", fontSize: "30px" }}>
                  {list && list.title} {this.daydiff()} Days
                </h1>
              </Col> */}
                {/* <Col lg={4}>
                <Button 
                  onClick={() => {
                    this.setState({ modalShow: true });
                  }}
                >
                  Email this Tour
                </Button>
              </Col> */}
              </Row>

              <Row>
                <Col lg={7}>
                  <Card
                    className="booking-card Card-style"
                    style={{ marginBottom: "50px" }}
                  >
                    <Card.Body>
                      <Row>
                        <Col lg={12}>
                          <h2 style={{ textTransform: "Capitalize" }}>
                            {list && list.title} {this.daydiff()} Days
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          style={{ textAlign: "center", paddingRight: "30px" }}
                        >
                          {/* <Row>
                            <Col>
                              <p>
                                From{": "} {moment(startDate).format("LLLL")}{" "}
                                {"-"} To{": "}
                                {moment(endDate).format("LLLL")}
                              </p>
                            </Col>
                          </Row> */}
                          <Row>
                            <Col lg={12}>
                              {/* <h4>Change Date</h4> */}
                              <h4>Select date of travelling</h4>
                              <Input
                                className="change-date"
                                type="select"
                                value={this.state.eventDateId}
                                onChange={(event) => {
                                  let ids = eventdatelist.findIndex(
                                    (x) => x.id == event.target.value
                                  );
                                  this.setState({
                                    eventDateId: event.target.value,
                                    ids,
                                  });
                                }}
                              >
                                {eventdatelist &&
                                  eventdatelist.map((item, index) => (
                                    <>
                                      {moment().diff(
                                        moment(item && item.startDate),
                                        "hours"
                                      ) <= 0 && (
                                        <option
                                          value={item && item.id}
                                          className=""
                                        >
                                          {" "}
                                          {moment(
                                            item && item.startDate
                                          ).format("DD-MM-YYYY")}
                                        </option>
                                      )}
                                    </>
                                  ))}
                              </Input>
                            </Col>
                          </Row>
                          {daypicker && (
                            <Row>
                              <DayPicker />
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  {/* user details form */}

                  <Card
                    className="booking-card Card-style"
                    style={{ marginBottom: "50px" }}
                  >
                    <Card.Body>
                      <h2 className="book-price">Add traveller details</h2>
                      <Row>
                        <h4 className="padding-trip">
                          Number of travellers {""}
                          <FaMinusCircle
                            className="minus-btn"
                            onClick={() => {
                              if (this.state.counter != 0)
                                this.setState({
                                  counter: this.state.counter - 1,
                                });
                            }}
                          />
                          <Label>{this.state.counter}</Label>
                          <FaPlusCircle
                            className="minus-btn"
                            onClick={() => {
                              if (Auth) {
                                if (this.state.counter < list.maxGroupSize) {
                                  this.setState({
                                    counter: this.state.counter + 1,
                                    FormCount: this.state.FormCount + 1,
                                  });
                                }
                              } else {
                                this.props.history.push("/login");
                              }
                            }}
                          />
                        </h4>
                      </Row>
                      {/* {FormCount >= list && list.maxGroupSize && ( */}
                      {!this.state.counter < list.maxGroupSize && (
                        <label>
                          Max travellers can be: {""}{" "}
                          {list && list.maxGroupSize}
                        </label>
                      )}{" "}
                      {this.getTravellerForm(FormCount)}
                      <div>
                        <hr></hr>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={5}>
                  <Card
                    className="booking-card Card-style"
                    style={{ marginBottom: "50px" }}
                  >
                    <Card.Body>
                      <h2>My trip</h2>
                      <div className="padding-trip">
                        <Row className="booking-car-row">
                          <h5>
                            <span>{list && list.title}</span>
                            <br />({this.daydiff()} Days)
                          </h5>
                        </Row>
                        <Row className="booking-car-row">
                          <Col lg={12} style={{ paddingLeft: "0px" }}>
                            <h5>Start Date</h5>
                          </Col>
                          <Col lg={12} style={{ paddingLeft: "0px" }}>
                            <p>
                              {" "}
                              {moment(
                                eventdatelist[this.state.ids].startDate
                              ).format("dddd, MMMM DD, YYYY hh:mm A")}
                            </p>
                          </Col>
                        </Row>
                        <Row className="booking-car-row">
                          <Col lg={12} style={{ paddingLeft: "0px" }}>
                            <h5>End Date</h5>
                          </Col>
                          <Col lg={12} style={{ paddingLeft: "0px" }}>
                            <p>
                              {" "}
                              {moment(
                                eventdatelist[this.state.ids].endDate
                              ).format("dddd, MMMM DD, YYYY hh:mm A")}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <hr></hr>
                      <div className="">
                        <Row>
                          <Col>
                            <h2>Number of travellers : {this.state.counter}</h2>
                          </Col>
                        </Row>
                      </div>
                      <Row className="amount-due">
                        <Col>
                          <div style={{ paddingBottom: "12px" }}>
                            <h1>
                              Amount Due ₹
                              {this.state.counter *
                                (list.amount - this.percen(list))}
                            </h1>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form>
                            <Form.Group>
                              <Form.Check
                                className={`${this.state.ch ? "green" : "red"}`}
                                required
                                onChange={(r) =>
                                  this.setState({ ch: r.target.checked })
                                }
                                checked={this.state.ch}
                                label="Agree to terms and conditions"
                                feedback="You must agree before submitting."
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                      <div>
                        <ButtonCommon
                          //disabled={this.state.counter == 0 || !this.state.ch}

                          className={
                            this.state.counter == 0 || !this.state.ch
                              ? "width "
                              : " all-events-btn1 width"
                          }
                          label="Continue to Pay"
                          handleClick={() => {
                            // this.setState({modalShow3:true})
                            this.onSubmit();
                          }}
                        />
                        {/* <ButtonCommon
                          disabled={this.state.counter == 0 || !this.state.ch}
                          className={
                            this.state.counter == 0 || !this.state.ch
                              ? "width dis"
                              : " all-events-btn1 width"
                          }
                          label="Continue to Pay"
                          
                          handleClick={() => this.onSubmit()}
                        />*/}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card className="booking-card Card-style">
                    <Card.Body>
                      <h2> Terms & Conditions</h2>

                      <p>
                        {" "}
                        BookMyTrek is an authorised Agent of Travel Talk. Please
                        familiarise yourself with the Travel Talk
                        <a
                          className="payment-cancellation"
                          onClick={() => {
                            this.setState({ modalShow1: true });
                          }}
                        >
                          {" "}
                          payment, cancellation and refund policies
                        </a>{" "}
                        and BookMyTrek
                        <a
                          className="payment-cancellation"
                          onClick={() => {
                            this.setState({ modalShow2: true });
                          }}
                        >
                          Terms & Conditions
                        </a>
                        . BookMyTrek will charge you in the stated currency and
                        we do not charge any booking fees.
                      </p>
                    </Card.Body>
                  </Card>

                  {modalShow1 && (
                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={modalShow1}
                      onHide={() => this.setState({ modalShow1: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Travel Talk Terms & Conditions
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        style={{ height: "600px", overflowY: "scroll" }}
                      >
                        <PaymentCancellationTerms />
                      </Modal.Body>
                    </Modal>
                  )}

                  {modalShow2 && (
                    <Modal
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={modalShow2}
                      onHide={() => this.setState({ modalShow2: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Terms & Conditions
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        style={{ height: "600px", overflowY: "scroll" }}
                      >
                        <PaymentTermsAndCondition />
                      </Modal.Body>
                    </Modal>
                  )}

                  <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modalShow3}
                    onHide={() => this.setState({ modalShow3: false })}
                  >
                    <Modal.Body>
                      {this.state.counter == 0 && (
                        <h3 style={{ textAlign: "center" }}>
                          Enter travellers details and{" "}
                        </h3>
                      )}
                      {!this.state.ch && (
                        <h3 style={{ textAlign: "center" }}>
                          Agree terms and conditions to
                        </h3>
                      )}
                      <h3 style={{ textAlign: "center" }}> Continue</h3>
                      <Row style={{ margin: "auto", textAlign: "center" }}>
                        <Col>
                          <ButtonCommon
                            className=" btn_small"
                            label="Ok"
                            handleClick={() =>
                              this.setState({ modalShow3: false })
                            }
                          />
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Bookevent;
