import React, { useState, useEffect, Component } from "react";

import {

  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
    Form,
    Row,
    Col,
    Badge,
    Container,
    Button,
    Label,
    Image,
  } from "react-bootstrap";
import Api from "../Service/Api";
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import OperatorList from '../Components/OperatorList';



const Operator = (props) => {
    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [password, setPassword] = useState("");

    const [error, seterror] = useState("");
    const [disable, setdisable] = useState(false);
    const [modalOpen, setmodalOpen] = useState(false);
    
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [active, setactive] = useState(false);
    const [edit, setEdit] = useState(false);
    const onSendmsg = (event) => {
       const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      let accesstoken = localStorage.getItem("accesstoken");
      const token = `Bearer ${accesstoken}`;
      if (form.checkValidity() === true) {
        setactive(true);
        setEdit(false)
        let data = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
        
        };
        event.preventDefault();
        Api.patch("/users/profile", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
          .then((res) => {
             if (res.status === 200) {
              setShow(true);
            }
          })
          .catch((err) => {
             setactive(false);
          });
      }
    };
    useEffect(() => {
      const Auth = localStorage.getItem("Auth");
      if(Auth=="true"){
      const res = localStorage.getItem("profile");
        
        setEmail(res.email);
        setFirstname(res.firstname);
        setLastname(res.lastname);
         
      }
 
    }, []);

  return (
    <div>
      <NavBar/>
      <section className='banner-section'>
          <div className='hero-text-wrapper'>
            <div className='hero-text'>
              <h4>Relax & Enjoy</h4>
              <h2>
                It's time to <br /> Adventure
              </h2>
            </div>
          </div>
        </section>
        <OperatorList/>
      <Footer/>
    </div>
  );
};

export default Operator;
