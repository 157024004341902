import React, { useState, useEffect } from "react";
import Bookevent from "../Components/Bookevent";
import NavBar from "../Components/NavBar";
import ArticleCard from "../Components/ArticleCard";

const BookEvent = (props) => {
  const [list, setList] = useState("");
  const [eventid, seteventid] = useState(null);
  const [id, setid] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  
    setList(props.location.list);
  }, []);

  return (
    <div>
      <NavBar />
      <div className="bookevent" >
        <section>
        <div className="">
        <Bookevent history={props.history} list={list} match={props.match}/>
        </div>
        </section>
      </div>

      {/* <Footer/> */}
    </div>
  );
};

export default BookEvent;
