import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Badge,
  Carousel,
  Accordion,
  Card,
  Button,
  Image,
  Modal,
  Tabs,
  Tab,
  Container,
} from "react-bootstrap";
import { Label, Input } from "reactstrap";
import BeautyStars from "beauty-stars";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  FaCheck,
  FaCalendar,
  FaMapMarkerAlt,
  FaUsers,
  FaMapMarkedAlt,
  FaUserAlt,
  FaCircle,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { GiFoodTruck } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { FaOpera } from "react-icons/fa";
import { BsGeoAlt } from "react-icons/bs";
import { MdLocalActivity } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import Api from "../Service/Api";
import moment from "moment";
import ViewEvent from "./ViewEvent";
import ButtonCommon from "./ButtonCommon";
import Loader from "./Loader";
import SimilarAdventures from "../Components/SimilarAdventures";

class CarouselEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: "",
      endDate: "",
      startDate: "",
      limit: 10,
      skip: 0,
      eventid: "",
      value: 0,
      eventdatelist: [],
      id: "",
      rating: [],
      faq: [],
      fav: false,
      prevfav: true,
      validated: false,
      validated1: false,
      modalShow: false,
      modalShow2: false,
      question: "",
      avgrate: "",
      email: "",
      phone: "",
      eventDateId:
        this.props &&
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.id,
      up: true,
      down: false,
      up1: false,
      down1: true,
      up2: false,
      down2: true,
      up3: false,
      down3: true,
      up4: false,
      down4: true,
      up5: false,
      down5: true,
      up6: false,
      down6: true,
      up7: false,
      down7: true,
      name: "",
      emailFrom: "",
      emailTo: "",
      eventId: "",
    };
  }

  componentDidMount() {
    // this.getAllEventDate();
    // this.getratinglist();
    // this.getAvgRating();
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== this.state.eventId) {
      window.location.reload();
      this.setData();
    }
  }

  setData = () => {
    let props = this.props;
    this.setState({
      eventId: props && props.id,
    });

    this.getAllEventDate();
    this.getratinglist();
    this.getAvgRating();
  };

  getratinglist = () => {
    Api.get(`/events/${this.props.id}/reviews`)
      .then((res) => {
        this.setState({ rating: res.data });
      })
      .catch((err) => {});
  };

  getAvgRating = () => {
    Api.get(`/events/${this.props.id}/reviews/average`)
      .then((res) => {
        this.setState({ avgrate: res.data });
      })
      .catch((err) => {});
  };

  getAllEventDate = () => {
    Api.get(
      `/events/${this.props.id}/event-dates?relations=["bmtEvent","availability"]`
    )
      .then((res) => {
        this.setState({ eventdatelist: res.data });
      })
      .catch((err) => {});
  };

  emailthistour = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader: true });
      let data = {
        eventId: this.props.id ? parseInt(this.props.id, 10) : null,
        email: this.state.email,
      };

      Api.post(`/misc/email-bmt-event`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ modalShow2: false, loader: false });
        })
        .catch((err) => {
         });
    }
  };

  askQue = (event) => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader2: true });
      let data = {
        question: this.state.question,
        name: this.state.name,
        phone: this.state.phone ? parseInt(this.state.phone, 10) : null,
        email: this.state.email,
      };
      Api.post(`/events/${this.props.id}/user-queries`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({ modalShow: false, loader2: false });
        })
        .catch((err) => {
         });
    }
  };

  sendEmail = () => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    this.setState({ modalShow2: false });
    let data = {
      emailFrom: this.state.emailFrom,
      emailTo: this.state.emailTo,
      name: this.state.name,
      eventId: this.props.id ? parseInt(this.props.id, 10) : null,
    };

    Api.post(`/misc/email-friend-bmt-event`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
         // this.setState({ modalShow: false, loader2: false });
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  daydiff = () => {
    const { list } = this.props;

    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    return a.diff(b, "days");
  };

  render() {
    const { image, place } = this.props;
    let {
      banner,
      endDate,
      startDate,
      eventdatelist,
      rating,
      fav,
      prevfav,
      modalShow,
      modalShow2,
      question,
      avgrate,
      email,
      phone,
      name,
      loader,
      eventDateId,
      up,
      validated1,
      down,
      validated,
      up1,
      down1,
      up2,
      down2,
      up3,
      down3,
      up4,
      down4,
      up5,
      down5,
      up6,
      down6,
      up7,
      down7,
      loader2,
      emailFrom,
      emailTo,
    } = this.state;
    let { list } = this.props;
 
    return (
      <div>
        <section className="mobile_sections">
          <Carousel controls={false} className="image_border">
            {list.eventImages &&
              list.eventImages.map((item) => (
                <Carousel.Item className="image_border">
                  <Image
                    src={item.md}
                    className="image_border carousel-height1"
                    style={{ width: "100%" }}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
        </section>
      </div>
    );
  }
}

export default CarouselEvent;
