import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class Covidpolicy extends Component{
    render(){
        return(
            <div>
                <NavBar />
             <div className='container footer-con'>
              <h1>BOOK RISK-FREE</h1>
<p>A
s well as curating amazing adventures with brilliant local hosts, we’ve made booking them as flexible, 
safe and simple as possible:
</p>

<h2>Total Flexibility </h2>

<p>
Need to change your plans? There are free date changes and cancellations up to 31 days before departure on 
most trips - see trip for details.
</p>

<h2>COVID Refund Gaurantee</h2>
<p>
If your trip is cancelled due to Covid choose from free date changes, account credit, or a
 full immediate refund.
</p>
<p>
As reported by The Guardian and Which? Travel, plus our very many 5* Trustpilot reviews, we are doing right 
by all customers who have had trips affected by COVID-19, unlike many other travel companies.
</p>

<h2>New: Zero Deposits</h2>
<p>
You can now give yourself something to look forward to with no upfront cost. You can book now and pay no deposit 
until 1st June 2021 (or pay in full 31 days before you depart, whichever is sooner). A 25% deposit is payable on
 1st June 2021. If you cancel up to 1st June 2021 there is nothing to pay.
 </p>

<h2>Your Money is Safe</h2>
<p>
Rest assured, there has been and always will be full financial protection on your booking.
</p>

<h2>Make Good Things Happen </h2>
<p>
When you hit the book button, you're also helping to support communities in destinations that need our 
help now more than ever. We contribute 5% from every booking to our foundation, which is currently 
working to help local hosts and their families who have been badly impacted by the pandemic, as well as 
important reforestation and rewilding projects.
</p>

               
           </div>
           <Footer />
           </div>
           
           

        )
    }
    }
    export default Covidpolicy;
