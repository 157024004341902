import { createStore, applyMiddleware, compose } from "redux";
import{ rootReducer }  from "./reducers";
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'
import {persistStore,  persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'authType',
  storage: storage,
  whitelist: ['homeReducer',] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

 const store = createStore(
  pReducer,
  {},
  compose(
    applyMiddleware(thunk),
  )
);
let persistor = persistStore(store)
export { store, persistor };