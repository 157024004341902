import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import BeautyStars from "beauty-stars";
import moment from "moment";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class ReviewCard extends Component {
  // daydiff = (list) => {
  //   var a = moment(list && list.endDate);
  //   var b = moment(list && list.startDate);
  //   return a.diff(b, "days");
  // };

  render() {
    let { reviewlist } = this.props;
   
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        
         // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 747 },
        items: 2,
      // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 748, min: 0 },
        items: 1,
         // optional, default to 1.
      }
    };
    return (
      <div className=" review row-margin-home" >
     
        <Carousel
  swipeable={true}
  draggable={false}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  containerClass="carousel-container"
  deviceType={this.props.deviceType}
  itemClass="carousel-item-padding-40-px"
  arrows
  swipeable
 
>
          {reviewlist &&
            reviewlist.map((item, index) => (
              <div key={item&&item.id}>
                <div
                  className="featured-card"
                  style={{
                    height: "300px",
                    borderRadius: "12px",
                 
                   marginBottom:'15px',
                    border: "none",
                  }}
                >
                  <img src={item && item.banner}></img>
                  {/* <div
                    onClick={() => this.props.history.push(`events/${item.id}`)}
                  ></div>
                  <div
                    onClick={() => this.props.history.push(`events/${item.id}`)}
                  > */}
                 
                    {/* <Card.Text> */}
{/* <span className="reviewstars"> */}
                   
                      {/* </span> */}
                    {/* </Card.Text> */}
               
                  {/* </div> */}
                 
                    {" "}
                    <div style={{position:"absolute",top:0,left:0,height:"100%",width:"100%"}}>
                    <div className="reviewer">
                 
                    <div className="reviewstars">
                        <BeautyStars
                          value={item && item.rating}
                          size="16px"
                          inactiveColor="#e0e0d1"
                          activeColor="#f4cc18"
                          width="200px"
                          // value={item && item.rating}
                        /></div>
                      <h4 className="Title-all"> <i className="fa fa-quote-left"></i>{item && item.riview}<i className="fa fa-quote-right"></i>
</h4>

                         <h4 style={{textTransform:'uppercase',marginTop:"10px"}}>{item && item.name}</h4>
       
                      </div> 
                      
                   </div>
                                        

                  </div>
                 
                  </div>
                 

           
            ))}

            </Carousel>
     
     
      </div>
    );
  }
}
export default ReviewCard;
