import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class WinATour extends Component{
    render(){
        return(
            <div>
                <NavBar />
                <div className='container footer-con'>
          <h2>Win A Tour for 2</h2>
          <p>Enter your email for a chance to win the grand prize</p>
                </div>
                <Footer />
            </div>
        )}}
export default WinATour;
