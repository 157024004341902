import React, { Component } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import Api from "../Service/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonCommon from "./ButtonCommon";

import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import moment from "moment";
import BeautyStars from "beauty-stars";
import FavoriteIcon from "@material-ui/icons/Favorite";

class Wishlist extends Component {
  state = {
    list: [],
    banner: "",
    endDate: "",
    startDate: "",
    limit: 8,
    skip: 0,
    id: "",
    fav: false,
    prevfav: true,
  };

  componentDidMount() {
    // this.getEvents();
    //this.daydiff();
  }

  //   "limit":"${this.state.limit}","skip":"${this.state.skip}"

  daydiff = (list) => {
    //const { list } = this.props;
 
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    return a.diff(b, "days");
  
  };
  render() {
    const { image, place } = this.props;
    let { banner, endDate, startDate, prevfav, fav } = this.state;
    let { list } = this.props;

    return (
      <div className="container footer-con">
        <div>
          <h2>WishList</h2>
          <Row>
            {list &&
              list.map((item) => (
                <Col lg={3} className="margin-btm">
                  <Card
                    className="card2"
                    style={{ height: "300px", borderRadius: "10px" }}
                  >
                    <div
                      onClick={() =>
                        this.props.history.push(`events/${item.bmtEvent.id}`)
                      }
                    >
                      <Card.Img
                        variant="top"
                        src={item.bmtEvent.banner}
                        style={{
                          height: "200px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      />
                    </div>
                    <div className="fea-fav">
                      <div className="inner-fav-div2">
                        {prevfav && (
                          <FavoriteIcon
                            className="fav-unclick"
                            onClick={() => {
                              this.setState({ fav: true });
                              this.setState({ prevfav: false });
                            }}
                          />
                        )}
                        {fav && (
                          <FavoriteIcon
                            className="fav-click"
                            onClick={() => {
                              this.setState({ fav: false });
                              this.setState({ prevfav: true });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        this.props.history.push(`events/${item.bmtEvent.id}`)
                      }
                    >
                      <Card.Body className="card_body">
                        <Card.Title className="Title-all font-style" style={{}}>
                          {" "}
                          {item.bmtEvent.title}
                        </Card.Title>
                        <Card.Text>
                          <Row className="star-wishlist">
                            <BeautyStars
                              // value={item.rating}
                              maxStars="1"
                              size="12px"
                              inactiveColor="#f4cc18"
                              activeColor="#f4cc18"
                              value={item && item.bmtEvent.rating}
                            />

                            {/* <i class="fas fa-fw fa-star text-accent text-xs"></i> */}

                            <span
                              style={{ fontSize: "12px" }}
                              className="sub-title-all"
                            >
                              100 REVIEWS
                            </span>
                          </Row>
                          <h5 className="price">₹{item.bmtEvent.amount}</h5>
                          <p className="sub-title-all">
                            {this.daydiff(item.bmtEvent)} days
                          </p>
                        </Card.Text>
                      </Card.Body>
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
          <div className="adventure-show-more">
            <Link
              to={{ pathname: "/viewEvents" }}
              style={{ textDecoration: "none" }}
            >
              <ButtonCommon
                className="all-events-btn"
                label="All Events"
                handleClick=""
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default Wishlist;
