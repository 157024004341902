import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonCommon from "./ButtonCommon";
import _ from "lodash";
class BlogCards extends Component {
  render() {
    let { list } = this.props;

    return (
      <div>
        <div>
          <Row className="row-margin-home">
            {_.isArray(list) &&
              list.map((item, index) => (
                <Col lg={4} md={6} className="margin-btm" key={index}>
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://blog.bookmytrek.com/${item.link.substring(
                      item.link.indexOf(".com") + 4
                    )}`}
                  >
                    <Card
                      //onClick={() => this.props.history.push()}
                      className="card blogcard"
                      style={{
                        height: "300px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      <div>
                        <Card.Img
                          variant="top"
                          src={item && item.jetpack_featured_media_url}
                          style={{
                            height: "150px",
                            borderRadius: "10px 10px 0px 0px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div>
                        <Card.Body>
                          <Card.Title
                            className="Title-all font-style"
                            style={{ color: "black" }}
                          >
                            {item.title && item.title.rendered.length && (
                              <div className="Textnooverflow">
                                {item.title.rendered}
                              </div>
                            )}
                          </Card.Title>

                          <span
                            style={{ fontSize: "12px", color: "grey" }}
                            className="sub-title-all multi"
                          >
                            {item &&
                              item.excerpt.rendered
                                .replace(/<p[^>]*>/g, "")
                                .replace(/<\/p>/g, "")}
                          </span>
                        </Card.Body>
                      </div>
                    </Card>
                  </a>
                </Col>
              ))}
          </Row>
          <div className="adventure-show-more">
            <a href="https://blog.bookmytrek.com/">
              <ButtonCommon className=" all-events-btn" label="View All" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default BlogCards;
