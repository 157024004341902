import React, { Component } from 'react';
import { Button ,Row, Col, Card
} from 'react-bootstrap';

import {
  DropdownToggle, DropdownMenu,
  Nav,  NavItem, NavLink,
  UncontrolledTooltip, UncontrolledButtonDropdown

} from 'reactstrap';
import logo from '../../static/Images/logo.png';
import { FaUserAlt} from "react-icons/fa";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Oplogo  from '../../static/Images/mojo.png';

import DestinationImage from '../../static/Images/destination1.jpg';
import BeautyStars from "beauty-stars";
import FD1 from '../../static/Images/FD1.jpg';
import FD2 from '../../static/Images/FD2.jpg';
import FD3 from '../../static/Images/FD3.jpg';

import FD4 from '../../static/Images/FD4.jpg';
import ButtonCommon from './ButtonCommon';




class Awards extends Component {
  render() {
    return (
      <div>
        <div>
            
          <Row style={{marginTop:'30px', marginLeft:'10px'}}>
            <h2>Awards and Recognitions</h2>
            </Row>
            <Row style={{paddingTop:'20px'}}>
                <Col lg={3}>
                        <Link to={{  pathname: "/operator-details"}} style={{textDecoration:'none'}}>
                        <Card >
                                <img 
                            src={FD2} 
                        
                            alt='' width='100%' height='250px' />
                        <Card.Body>
                        <Card.Text>
                           
                              <h3> Title</h3>
                               <p> Description....</p>
                            </Card.Text>
                            </Card.Body>

                        </Card>
                        </Link>
                </Col>
                <Col lg={3}>
                        <Link to={{  pathname: "/operator-details"}} style={{textDecoration:'none'}}>
                        <Card >
                                <img 
                            src={FD2} 
                        
                            alt='' width='100%' height='250px' />
                        <Card.Body>
                        <Card.Text>
                           
                              <h3> On The Go Tours</h3>
                               <p> Description....</p>
                            </Card.Text>
                            </Card.Body>

                        </Card>
                        </Link>
                </Col>
                </Row>


                
        </div>
      </div>
    );
  }
}

export default Awards;
