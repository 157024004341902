import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Navbar,
  Nav,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap";
import { FaUserAlt, FaUserCircle } from "react-icons/fa";
import Api from "../Service/Api";
import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  DropdownToggle,
  DropdownMenu,
  // Nav,
  // NavItem,
  // NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import logo from "../../static/Images/logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";

const NavBar = (props) => {
  let history = useHistory();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [validated, setValidated] = useState(false);
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState(new Date());
  const [error, seterror] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [country, setcountry] = useState("");
  const [about, setaboutus] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [profileImage, setProfile] = useState("");
  const [address, setaddress] = useState("");
  const [countrylist, setcountrylist] = useState(["India", "America"]);
  const [listnew, setnewlist] = useState([""]);
  const [edit, setEdit] = useState(false);
  const [loader, setloader] = useState(true);
  const [imagechange, setimagechange] = useState(false);
  const [banner, setbanner] = useState({ imagePreviewUrl: "", raw: "" });
  const [message, setmessage] = useState("");
  const [verified, setVerified] = useState("");
  const token = localStorage.getItem("accesstoken");
  useEffect(() => {
    // Api.get(`/users/my-profile`, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // }).then((res) => {
    //   setloader(false)
    //   if (res.status === 200) {
    const Auth = localStorage.getItem("Auth");
    if (Auth == "true") {
      const res = props.profile;
      setemail(res.email);
      setname(res.name);
      setmobile(res.mobile);
      setaboutus(res.about);
      setcity(res.city);
      setcountry(res.country);
      setstate(res.state);
      setProfile(res.profileImage);
      setaddress(res.address);
      setdob(moment(res.dob).format("YYYY-MM-DD"));
      setgender(res.gender);
      setzipcode(res.zipcode);
      setVerified(res.emailVerified);
    }
    //   }

    // });
  }, []);
  const Save = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      setloader(true);
      let data = {
        about,
        address,
        email,
        name,
        mobile,
        gender,
        dob: moment(dob).toISOString(),
        zipcode: Number(zipcode),
        country,
        state,
        city,
      };

      Api.patch("/users/profile", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setloader(false);
          setEdit(false);

          if (res.status === 200) {
            const formData = new FormData();
            if (banner.raw) {
              formData.append("image", banner.raw);

              Api.post(`users/profile-image`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((res) => {})
                .catch((err) => {});
            }
          } else {
            seterror("Try after Sometime");
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  const handleSelect = (evt) => {
    setcountry(countrylist[evt]);
    // what am I suppose to write in there to get the value?
    setnewlist([""]);
  };
  const fileChange = (e) => {
    if (e.target.files.length) {
      setimagechange(true);
      setbanner({
        imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const getUserlist = (word) => {
    setcountry(word);

    setnewlist(countrylist);
    // console.log("word", word);
    const lowercasedValue = word.toLowerCase();

    const filteredData = countrylist.filter((el) =>
      el.toLowerCase().includes(lowercasedValue)
    );
    //   console.log("filteredData", filteredData);
    setnewlist(filteredData);
  };

  const joinAsOperator = () => {
    // console.log("in join")
    const token = localStorage.getItem("accesstoken");
    Api.post(
      `users/operator/join`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setmessage("Your request has been sent to admin for approval!");
        seterror("");
      })
      .catch((err) => {
        setmessage("");
        seterror("Approval on your current request is already pending.");
      });
  };
  const auth = localStorage.getItem("Auth");
  return (
    <div>
      <>
        <Navbar className="" expand="lg">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link className="login-btn" href="/">
                Home
              </Nav.Link>

              <Nav.Link className="login-btn" href="/allEvents">
                Adventures
              </Nav.Link>
              <Nav.Link className="login-btn" href="/destinations">
                Destinations
              </Nav.Link>
              <Nav.Link className="login-btn" href="/alloperators">
                Operators
              </Nav.Link>
              <Nav.Link
                className="login-btn"
                href="https://blog.bookmytrek.com"
              >
                Blog
              </Nav.Link>
              {/* <Nav.Link className="login-btn" href="#link">Gallery</Nav.Link> */}
              <Nav.Link className="login-btn" href="/Aboutus">
                About
              </Nav.Link>
              <Nav.Link className="login-btn" href="/contact-us">
                Contact
              </Nav.Link>
            </Nav>
            <Form
              inline
              className="navb-padding"
              style={{ justifyContent: "center" }}
            >
              {!auth && (
                <div className="bookBtn-wrapper1">
                  <Nav>
                    {/* <Nav.Link className="login-btn"
                to={{ pathname: "/login" }}
                style={{ textDecoration: "none" ,borderRadius:'12px'}}
              >
                <a>Login</a>
              </Nav.Link> */}
                    <Link
                      className="login-btn"
                      to={{ pathname: "/login" }}
                      style={{ textDecoration: "none", borderRadius: "12px" }}
                    >
                      <a>Login</a>
                    </Link>

                    <Link
                      className="login-btn"
                      to={{ pathname: "/signup" }}
                      style={{ textDecoration: "none", borderRadius: "12px" }}
                    >
                      <a>SignUp</a>
                    </Link>
                  </Nav>
                </div>
              )}

              {auth && verified == true && (
                <UncontrolledButtonDropdown>
                  <DropdownToggle
                    className="drp-btn"
                    color="link"
                    className="p-0"
                    style={{ color: "#000" }}
                  >
                    <h1>
                      {" "}
                      {/* <FaUserCircle /> */}
                      <img
                        className="img_border_hover"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "10px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={profileImage && profileImage.md}
                      />
                    </h1>
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    className=" dropdown-menu-lg drp-menu-item"
                  >
                    <Nav.Link eventKey={2} className="login-btn">
                      <Link
                        to={{ pathname: "/user-account" }}
                        style={{ textDecoration: "none" }}
                      >
                        Profile
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      eventKey={2}
                      className="login-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.clear();
                        history.push("/");
                        window.location.reload();
                      }}
                    >
                      logout
                    </Nav.Link>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}

              {auth && !verified && (
                <div className="bookBtn-wrapper1">
                  <Nav>
                    {/* <Nav.Link className="login-btn"
                to={{ pathname: "/login" }}
                style={{ textDecoration: "none" ,borderRadius:'12px'}}
              >
                <a>Login</a>
              </Nav.Link> */}
                    <Link
                      className="login-btn"
                      to={{ pathname: "/login" }}
                      style={{ textDecoration: "none", borderRadius: "12px" }}
                    >
                      <a>Login</a>
                    </Link>

                    <Link
                      className="login-btn"
                      to={{ pathname: "/signup" }}
                      style={{ textDecoration: "none", borderRadius: "12px" }}
                    >
                      <a>SignUp</a>
                    </Link>
                  </Nav>
                </div>
              )}
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.homeReducer.profile,
});

export default connect(mapStateToProps, null)(NavBar);
