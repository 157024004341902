import React, { Component } from 'react';
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";


class Privacy extends Component{
    render(){
        return(
            <div>
                <NavBar />
                <div className='container footer-con'>
                <h1>Privacy Policy</h1>

                <h2>Introduction</h2>
                <p>
                 BookMyTrek is concerned about your privacy. Please read the following to learn more about BookMyTrek’s privacy policy. This Privacy Policy is
                in accordance with the Indian Information Technology Act, 2000 and the Information Technology (Reasonable security and procedures and
                sensitive personal data or information) Rules, 2011 prescribed thereunder, (“Applicable Law”) that is intended to provide you with clear and
                complete information about your treatment of the personally identifiable data and information that BookMyTrek collects in connection with the
                services offered by BookMyTrek on BookMyTrek’s website and the way your personally identifiable information and data is stored and used
                byBookMyTrek.
                </p>
                <p>
                  Personally Identifiable Information means any information that relates to a natural person, which, either directly or indirectly, in combination with
other information available or likely to be available with BookMyTrek, is capable of identifying such person. Therefore information such as your
name, age, gender, address, telephone number, and any other personal information that we collect, in combination with any other information
about yourself, which is capable of identifying yourself, is known as personally identifiable information.
                </p>

                <h2>What this Privacy Policy Covers</h2>
                <ul className="disc">
                <li>
                  This Privacy Policy covers BookMyTrek’s treatment of your personally identifiable information that BookMyTrek collects when you book
adventure events on BookMyTrek’s website that BookMyTrek offers and when you use the services of BookMyTrek that are, or as and
when they are available in the future, on BookMyTrek’s website.
                </li>
                <li>
                  This policy also covers BookMyTrek’s treatment of your personally identifiable information that BookMyTrek’s business partners share
with BookMyTrek, which you may have disclosed to such business partners.
                </li>
                <li>
                  This policy does not apply to companies or entities that BookMyTrek does not own or control, or has no contractual relationship with or to
people that BookMyTrek does not employ, engage or manage.
                </li>
                <li>
                  If you choose not to provide the personal information that we seek from you, we may not be able to provide you with our services or
respond to any queries that you may have.
                </li>
                </ul>

                <h2>Information Collection and Use</h2>
                <p>
                  We reserve the right to withdraw or amend this Site, and any service or material we provide on the Site, in our sole discretion without notice. We
will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access
to some parts of the Site, or the entire Site, to users, including registered members.
                </p>
                <p>You are responsible for :</p>
                <ul className="disc">
                  <li>
                    BookMyTrek collects personally identifiable information when you register with BookMyTrek, through BookMyTrek’s website located at
URL <a className="alinks">http://www.bookmytrek.com</a>, and when you use certain BookMyTrek’s services that are available on BookMyTrek’s website, when
you visit the web pages of BookMyTrek’s website. BookMyTrek may also receive personally identifiable information about yourself
through its business partners.
                  </li>
                  <li>
                    When you register with BookMyTrek, BookMyTrek asks you for your full name, email address, mobile/telephone number, birth date,
location/address, passport number, travel preferences, gender, occupation, industry and any other information that BookMyTrek believes
is necessary to effectively provide its services to you. Once you register with BookMyTrek, you are not anonymous to BookMyTrek.
                  </li>
                  <li>
                    BookMyTrek also automatically receives and records information on BookMyTrek’s server logs from your browser including your IP
address, BookMyTrek’s Cookie information and the webpage you have requested to view.
                  </li>
                  <li>
                  In addition, when you use BookMyTrek’s services through BookMyTrek’s website, you may make some of your personal information
public. If you post personal information online that is accessible to the public, you may receive unsolicited messages from other parties in
return. You alone will be entirely responsible for any personally identifiable information that you voluntarily post on any public areas of
BookMyTrek’s web site. BookMyTrek absolutely disclaims any liability or responsibility for any disclosure by you or anyone on your
behalf of any of your personally identifiable information, which may be posted on any public areas of BookMyTrek’s website.
                  </li>
                  <li>
                    BookMyTrek uses your information to provide the services that you seek from BookMyTrek by booking adventure events and services
that are offered on BookMyTrek’s website, for the content you see, to fulfill your requests for certain services and other offers of
BookMyTrek, and to contact you about any special offers and new services, that BookMyTrek offers, or may offer at any time in the
future.
                  </li>
                  <li>
                    BookMyTrek may also use your personally identifiable information for internal purposes, such as auditing, data analysis and research to
improve our services and customer communications.
                  </li>
                </ul>
            </div>
            <Footer />
            </div>
        )
    }
}
export default Privacy;