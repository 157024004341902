import {
  GETSLIDER,
  GETTOPEVENT,
  GETFEATURED,
  GETTOPDESTINATION,
  GETTOPOPERATOR,
  GETTOPCATEGORY,
  GETEVENTREVIEW,
  Profile,
  GETALLOPERATORS,
  GETCOVIDSTATUS,
} from "./actionType";
import Api from "../../Service/Api";

export const getsliderimages = () => (dispatch) => {
  try {
    Api.get(`/misc/sliderimages/all`)
      .then((res) => {
        
        dispatch({
          type: GETSLIDER,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};

export const getProfile = (data) => ( dispatch) => {
  try {
    dispatch({
      type: Profile,
      payload: data,
    });
  } catch (err) {}
};
export const getevents = () => (dispatch) => {
  try {
    Api.get(`/events/stats/top-events?limit=12&skip=0`)
      .then((res) => {
        

        dispatch({
          type: GETTOPEVENT,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};
export const getFeatured = () => (dispatch) => {
  try {
    Api.get(`/events/stats/get-featured?skip=0&limit=8`)
      .then((res) => {
      

        dispatch({
          type: GETFEATURED,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};
export const getDestination = () => (dispatch) => {
  try {
    Api.get(`/events/stats/top-destinations?skip=0&limit=10`)
      .then((res) => {
         

        dispatch({
          type: GETTOPDESTINATION,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};
export const getOperators = () => (dispatch) => {
  try {
    Api.get(`/events/stats/top-operators?skip=0&limit=8`)
      .then((res) => {
       

        dispatch({
          type: GETTOPOPERATOR,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};
export const getReviews = () => (dispatch) => {
  try {
    Api.get(`/bmt-reviews/public/minimal?limit=6`)
      .then((res) => {
        

        dispatch({
          type: GETEVENTREVIEW,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};

export const getCategory = () => (dispatch) => {
  try {
    Api.get(`/admin/event-categories`)
      .then((res) => {
         

        dispatch({
          type: GETTOPCATEGORY,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};

export const getallOperators = () => (dispatch) => {
  try {
    Api.get(`/admin/operators/all?where={"approved":"1"}`)
      .then((res) => {
        

        dispatch({
          type: GETALLOPERATORS,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};

export const getCovidStatus = () => (dispatch) => {
  try {
    Api.get(`bmt-reviews/toggle/1`)
      .then((res) => {
console.log('reviews covid',res)
        dispatch({
          type: GETCOVIDSTATUS,
          payload: res.data,
        });
      })
      .catch((err) => {});
  } catch (err) {}
};


 