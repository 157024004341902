import React, { Component } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import logo from "../../static/Images/logo.png";
import { FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Oplogo from "../../static/Images/mojo.png";

import DestinationImage from "../../static/Images/destination1.jpg";
import BeautyStars from "beauty-stars";
import FD5 from "../../static/Images/FD5.jpg";
import FD2 from "../../static/Images/FD2.jpg";
import FD3 from "../../static/Images/FD3.jpg";

import FD4 from "../../static/Images/FD4.jpg";
import ButtonCommon from "./ButtonCommon";
import OperatorReviews from "./OperatorReviews";
import Awards from "./Awards";
import OperatorTours from "./OperatorTours";
import AskOperatorQuestions from "./AskOperatorQuestion";
import Api from "../Service/Api";
import FeaturedEvents from "./FeaturedEvents";
import NavBar from "./NavBar";
import Destinations from "./Destinations";
import Loaders from "./FullScreenLoader";

class DestinationDetails extends Component {
  constructor() {
    super();
    this.state = {
      operatorInfo: {},
      active: true,
      reviewslist: [],
      limit: 8,
      destinationlist: [],
      deventlist: [],
      topdestination: [],
      destination:"",
      loader:false,
    };
  }
  componentDidMount() {

    this.setData();
   
  }


  componentDidUpdate(prevProps) {
   
    if (this.props.props.match.params.destination !== this.state.destination) {
      window.location.reload();
      this.setData();
    }
  }

  setData = () => {
    let props = this.props;
    this.setState({
      destination: props.props.match.params.destination, loader:true
    });

    Api.get(
      `/events/destinations?order={ "createdOn": "DESC"}&destination=${this.props.props.match.params.destination}&limit=1`
    )
      .then((res) => {
         this.setState({ destinationlist: res.data, active: false, loader:false });
       
      })
      .catch((err) => {});
    Api.get(
      `/events/all?order={ "createdOn": "DESC"}&destination=${this.props.props.match.params.destination}&limit=4`
    )
      .then((res) => {
         this.setState({ deventlist: res.data });
      })
      .catch((err) => {});

    this.geteventDestination();


  };

  geteventDestination = () => {
     Api.get(
       `/events/stats/top-destination?limit=6`
     )
      .then((res) => {
         this.setState({ topdestination: res.data });
      })
      .catch((err) => {
      //  console.log(err);
      });
  };

  render() {
    const {
      operatorInfo,
      active,
      ratings,
      reviewscount,
      reviewslist,
      eventlist,
      destinationlist,
      deventlist,
      topdestination,
      loader
    } = this.state;
    return (
      <div className="">
         {loader && <Loaders />}
        {!active && (
          <div>
            <div
              className="banner"
              style={{
                backgroundImage: `url(${
                  destinationlist &&
                  destinationlist[0] &&
                  destinationlist[0].banner
                })`,
                width: "100%",
                backgroundSize: "cover",
                height: "75vh",
                position: "relative",
                backgroundPosition: "50% 50%",
                zIndex: 75,
              }}
            >
              <NavBar />

              <div className="hero-text-wrapper">
                  <div className="hero-text view-event-title image_border title-sweep-to-right">
                  <div className="eventopacity" style={{ backgroundImage: 'none',
                      backgroundColor: 'black',
                      height: '100%',
                      opacity: '0.5'}}> </div>
                    <h1 className="banner_color" style={{textTransform:'capitalize'}}>
                    {destinationlist &&
                  destinationlist[0] &&
                  destinationlist[0].destination}
                  </h1>
                 
                </div>
                </div>
              
                
           
              
            </div>

            <section className="container section-margin">
              {/* {deventlist && deventlist.length == 0 && (
                <label>No Result Found!!!</label>
              )} */}
              {deventlist && deventlist.length != 0 && (
                <div>
                  <h1>Adventures</h1>
                  <Row>
                    <Col lg={12}>
                      <FeaturedEvents
                        history={this.props.props.history}
                        featuredlist={deventlist}
                      />
                    </Col>
                  </Row>
                </div>
              )}

              <h1>You may also like</h1>

              <Destinations
                history={this.props.history}
                destinationslist={topdestination}
              />
            </section>
            {/* <section className="top-destinations section-margin">
       
      </section> */}
          </div>
        )}
      </div>
    );
  }
}

export default DestinationDetails;
