import React, { Component } from "react";
import { Row, Col, Card, Modal, Nav } from "react-bootstrap";
import Api from "../Service/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonCommon from "./ButtonCommon";
import moment from "moment";
import {
  FaCheck,
  FaCalendar,
  FaMapMarkerAlt,
  FaUsers,
  FaMapMarkedAlt,
  FaUserAlt,
  FaCircle,
  FaChevronDown,
  FaEllipsisV,
  FaEllipsisH,
} from "react-icons/fa";
import Review from "./Review";
import {
  DropdownToggle,
  DropdownMenu,
  NavItem,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";

class UserBooking extends Component {
  state = {
    list: [],
    complete: [],
    upcoming: [],
    banner: "",
    endDate: "",
    startDate: "",
    id: "",
    fav: false,
    prevfav: true,
    modalShow: false,
    modalShow2: false,
    limit: 12,
    skip: 0,
    loading: false,
    page: 6,
    statusB: "upcoming",
    prevY: 0,
  };

  componentDidMount() {
    this.getBooking(this.state.page);
    this.getCompleted(this.state.page);
    this.getUpcoming(this.state.page);
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  getUpcoming(value) {
    this.setState({ loading: true });
    let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
    // const token = localStorage.getItem("accesstoken");
    Api.get(
      `users/bookings/upcoming?where={"bookingStatus":"successful"}&relations=[ "bmtEvent" ]&order={ "updatedOn": "DESC"}&limit=${value}&skip=0`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => {
       

      if (res.status === 200) {
        this.setState({ upcoming: res.data });
        this.setState({ loading: false });
      }
    });
  }
  getCompleted(value) {
    this.setState({ loading: true });
    let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
    // const token = localStorage.getItem("accesstoken");
    Api.get(
      `users/bookings/completed?where={"bookingStatus":"successful"}&relations=[ "bmtEvent" ]&order={ "updatedOn": "DESC"}&limit=${value}&skip=0`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        this.setState({ complete: res.data });
        this.setState({ loading: false });
      }
    });
  }

  getBooking(value) {
    this.setState({ loading: true });
    let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
    // const token = localStorage.getItem("accesstoken");
    Api.get(
      `/users/bookings/failed?relations=[ "bmtEvent" ]&order={ "updatedOn": "DESC"}&limit=${value}&skip=0`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => {
   
      if (res.status === 200) {
        this.setState({ list: res.data });
        this.setState({ loading: false });
      }
    });
  }

  //pagination
  handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.getBooking(this.state.page + 6);
      this.getCompleted(this.state.page + 6);
      this.getUpcoming(this.state.page + 6);
      this.setState({ page: this.state.page + 6 });
    }
    this.setState({ prevY: y });
  };

  nextPage = async () => {
    await this.setState({
      skip: this.state.skip + this.state.limit,
    });
    await this.getEvents();
  };

  previousPage = async () => {
    if (this.state.skip > 0) {
      await this.setState({
        skip: this.state.skip - this.state.limit,
      });
      await this.getEvents();
    }
  };

  cancelbooking = () => {
    this.setState({ modalShow2: false, loading: true });
    let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
 
    Api.patch(`/bookings/${this.state.eventid}/cancelBooking`, {},{
      headers: {
        
        Authorization: token,
      },
    })
      .then((res) => {
        
    
        window.location.reload();
        
        this.getBooking(this.state.page);
      })
      .catch((err) => {
        console.log(err.response.data.message)
      });
  };

  daydiff = (list) => {
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    return a.diff(b, "days");
  };
  render() {
    const { image, place } = this.props;
    let {
      banner,
      endDate,
      statusB,
      startDate,
      prevfav,
      fav,
      modalShow,
      modalShow2,
      skip,
      limit,
      count,
    } = this.state;
    let { list, upcoming, complete } = this.state;

    const loadingCSS = {
      height: "100px",
      margin: "30px",
    };

    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="footer-con">
        <div className="">
        <Row>
          <Col>
          <h2 className="mb-2">Booking History</h2>
          </Col>
          </Row>
          <Row className="mt-3 mb-5 pr-3 pl-3">
            <Col
              onClick={() => this.setState({ statusB: "upcoming" })}
              className={statusB === "upcoming" ? "activebox" : "inactivebox"}
            >
             <h3> Upcoming Booking</h3>
            </Col>
            <Col
              onClick={() => this.setState({ statusB: "complete" })}
              className={statusB === "complete" ? "activebox" : "inactivebox"}
            >
             <h3> Completed Booking</h3>
            </Col>
            <Col
              onClick={() => this.setState({ statusB: "failed" })}
              className={statusB === "failed" ? "activebox" : "inactivebox"}
            >
             <h3> Failed Booking{" "}</h3>
            </Col>
          </Row>
          {statusB === "complete" && (
            <Row>
              {complete.map((item) => (
                <Col lg={6} md={6} className="margin-btm">
                  <Card
                    className="Card-style"
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      padding: "0px",
                    }}
                  >
                    {/* <Link
                  to={{ pathname: `/bookingdetails/${item.bmtEventId}`, bookinglist: item }}
                  style={{ textDecoration: "none" }}
                > */}
                    <Row>
                      <Col lg={4} md={4} sm={4} style={{ paddingRight: "0px" }}>
                        <Link
                          to={{
                            pathname: `/bookingdetails/${item.id}`,
                            bookinglist: item,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card.Img
                            variant="top"
                            src={item.bmtEvent.banner}
                            className="image_border1 img-responsive"
                            style={{ width: "100%", height: "180px" }}
                          />
                        </Link>
                      </Col>
                      <Col lg={6} md={8} sm={8}>
                        <Link
                          to={{
                            pathname: `/bookingdetails/${item.id}`,
                            bookinglist: item,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card.Body className="card_body mb-style">
                            <Row>
                              <Col>
                                <h4 className="ub-heading ub-font">
                                  {item.bmtEvent.title.length > 15 && (
                                    <div>
                                      {item.bmtEvent.title.substring(0, 15)} ...
                                    </div>
                                  )}
                                  {item.bmtEvent.title.length < 15 && (
                                    <div>{item.bmtEvent.title}</div>
                                  )}

                                  {/* {item.bmtEvent.title} */}
                                </h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="ub-font">Booking Id: {item.id}</p>
                              </Col>
                            </Row>
                            <Row className="ub_stars_row">
                              <Col>
                                <h3 className="normaltext2  ub-boooking ub-font">
                                  ₹{item.totalAmount}
                                </h3>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col>
                                <p className="normaltext2  ub-boooking ub-font">
                                  Booked on{" "}
                                  {moment(item.createdOn).format("DD-MM-YYYY")}
                                </p>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col>
                                <p className="normaltext2  ub-boooking ub-font">
                                  Event Date{" "}
                                  {moment(
                                    item &&
                                      item.eventDate &&
                                      item.eventDate.startDate
                                  ).format("DD-MM-YYYY")}
                                </p>
                              </Col>
                            </Row>
                            <Row style={{ marginLeft: "0px" }}>
                              <p
                                className="tags"
                                className={
                                  item.bookingStatus == "successful"
                                    ? "sucess-tags"
                                    : item.bookingStatus == "pending"
                                    ? "pending-tags"
                                    : "failed-tags"
                                }
                                style={{ textAlign: "center" }}
                              >
                                {item.bookingStatus}
                              </p>
                            </Row>
                          </Card.Body>
                        </Link>
                      </Col>
                    </Row>

                    {(item.bookingStatus == "successful" && moment(
                              item && item.eventDate && item.eventDate.endDate
                            ).diff(moment(), "days") <= 0) && (
                      <>
                        <FaEllipsisH className="EllipsisH" />
                        <UncontrolledButtonDropdown className="bbtn-ggroup">
                          <DropdownToggle
                            color="link"
                            className="p-0"
                            style={{ color: "#000", textAlign: "left" }}
                          ></DropdownToggle>
                          <DropdownMenu
                            left
                            className="rm-pointers dropdown-menu-lg"
                          >
                              <Nav.Link
                                onClick={() => {
                                  this.setState({
                                    modalShow: true,
                                    eventid: item.bmtEventId,
                                    operadtorid: item.operatorId,
                                    userId: item.userId,
                                  });
                                }}
                              >
                                <h4>Write Review</h4>
                              </Nav.Link>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </>
                    )}

                    {/* </Link> */}
                  </Card>
                </Col>
              ))}

              <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              >
                <div className="loader loader-1" style={loadingTextCSS}>
                  <div className="loader-outter" />
                  <div className="loader-inner" />
                </div>
              </div>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => this.setState({ modalShow: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Review This Tour
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Review
                    eventid={this.state.eventid}
                    operadtorid={this.state.operadtorid}
                    userId={this.state.userId}
                  />
                </Modal.Body>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow2}
                onHide={() => this.setState({ modalShow2: false })}
              >
                <Modal.Body>
                  <Row
                    className="mb-4"
                    style={{ margin: "auto", textAlign: "center" }}
                  >
                    <Col>
                      <h2>Are you sure you want to cancel the booking?</h2>
                    </Col>
                  </Row>
                  <Row style={{ margin: "auto", textAlign: "center" }}>
                    <Col>
                      <ButtonCommon
                        handleClick={() => this.cancelbooking()}
                        className=" btn_small margin-userbooking"
                        label="Yes"
                      />
                      {/* </Col>
                  <Col> */}
                      <ButtonCommon
                        className=" btn_small"
                        label="No"
                        handleClick={() => this.setState({ modalShow2: false })}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </Row>
          )}
          {statusB === "upcoming" && (
            <Row>
              {upcoming.map((item) => (
                <Col lg={6} md={6} className="margin-btm">
                  <Card
                    className="Card-style"
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      padding: "0px",
                    }}
                  >
                    {/* <Link
                  to={{ pathname: `/bookingdetails/${item.bmtEventId}`, bookinglist: item }}
                  style={{ textDecoration: "none" }}
                > */}
                    <Row>
                      <Col lg={4} md={4} sm={4} style={{ paddingRight: "0px" }}>
                        <Link
                          to={{
                            pathname: `/bookingdetails/${item.id}`,
                            bookinglist: item,
                            
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card.Img
                            variant="top"
                            src={item.bmtEvent.banner}
                            className="image_border1 img-responsive"
                            style={{ width: "100%", height: "180px" }}
                          />
                        </Link>
                      </Col>
                      <Col lg={6} md={8} sm={8}>
                        <Link
                          to={{
                            pathname: `/bookingdetails/${item.id}`,
                            bookinglist: item,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card.Body className="card_body mb-style">
                            <Row>
                              <Col>
                                <h4 className="ub-heading ub-font">
                                  {item.bmtEvent.title.length > 15 && (
                                    <div>
                                      {item.bmtEvent.title.substring(0, 15)} ...
                                    </div>
                                  )}
                                  {item.bmtEvent.title.length < 15 && (
                                    <div>{item.bmtEvent.title}</div>
                                  )}

                                  {/* {item.bmtEvent.title} */}
                                </h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="ub-font">Booking Id: {item.id}</p>
                              </Col>
                            </Row>
                            <Row className="ub_stars_row">
                              <Col>
                                <h3 className="normaltext2  ub-boooking ub-font">
                                  ₹{item.totalAmount}
                                </h3>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col>
                                <p className="normaltext2  ub-boooking ub-font">
                                  Booked on{" "}
                                  {moment(item.createdOn).format("DD-MM-YYYY")}
                                </p>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col>
                                <p className="normaltext2  ub-boooking ub-font">
                                  Event Date{" "}
                                  {moment(
                                    item &&
                                      item.eventDate &&
                                      item.eventDate.startDate
                                  ).format("DD-MM-YYYY")}
                                </p>
                              </Col>
                            </Row>
                            <Row style={{ marginLeft: "0px" }}>
                              <p
                                className="tags"
                                className={
                                  item.bookingStatus == "successful"
                                    ? "sucess-tags"
                                    : item.bookingStatus == "pending"
                                    ? "pending-tags"
                                    : "failed-tags"
                                }
                                style={{ textAlign: "center" }}
                              >
                                {item.bookingStatus}
                              </p>
                            </Row>
                          </Card.Body>
                        </Link>
                      </Col>
                    </Row>

                    {item.bookingStatus == "successful" && (
                      <>
                        <FaEllipsisH className="EllipsisH" />
                        <UncontrolledButtonDropdown className="bbtn-ggroup">
                          <DropdownToggle
                            color="link"
                            className="p-0"
                            style={{ color: "#000", textAlign: "left" }}
                          ></DropdownToggle>
                          <DropdownMenu
                            left
                            className="rm-pointers dropdown-menu-lg"
                          >
                            <Nav.Link
                              onClick={() => {
                                this.setState({
                                  modalShow2: true,
                                  eventid: item.id,
                                });
                              }}
                            >
                              <h4>Cancel</h4>
                            </Nav.Link>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </>
                    )}

                    {/* </Link> */}
                  </Card>
                </Col>
              ))}

              <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              >
                <div className="loader loader-1" style={loadingTextCSS}>
                  <div className="loader-outter" />
                  <div className="loader-inner" />
                </div>
              </div>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => this.setState({ modalShow: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Review This Tour
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Review
                    eventid={this.state.eventid}
                    operadtorid={this.state.operadtorid}
                    userId={this.state.userId}
                  />
                </Modal.Body>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow2}
                onHide={() => this.setState({ modalShow2: false })}
              >
                <Modal.Body>
                  <Row
                    className="mb-4"
                    style={{ margin: "auto", textAlign: "center" }}
                  >
                    <Col>
                      <h2>Are you sure you want to cancel the booking?</h2>
                    </Col>
                  </Row>
                  <Row style={{ margin: "auto", textAlign: "center" }}>
                    <Col>
                      <ButtonCommon
                        handleClick={() => this.cancelbooking()}
                        className=" btn_small margin-userbooking"
                        label="Yes"
                      />
                      {/* </Col>
                  <Col> */}
                      <ButtonCommon
                        className=" btn_small"
                        label="No"
                        handleClick={() => this.setState({ modalShow2: false })}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </Row>
          )}
          {statusB === "failed" && (
            <Row>
              {list.map((item) => (
                <Col lg={6} md={6} className="margin-btm">
                  <Card
                    className="Card-style"
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      padding: "0px",
                    }}
                  >
                    {/* <Link
                  to={{ pathname: `/bookingdetails/${item.bmtEventId}`, bookinglist: item }}
                  style={{ textDecoration: "none" }}
                > */}
                    <Row>
                      <Col lg={4} md={4} sm={4} style={{ paddingRight: "0px" }}>
                        <Link
                          to={{
                            pathname: `/bookingdetails/${item.id}`,
                            bookinglist: item,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card.Img
                            variant="top"
                            src={item.bmtEvent.banner}
                            className="image_border1 img-responsive"
                            style={{ width: "100%", height: "180px" }}
                          />
                        </Link>
                      </Col>
                      <Col lg={6} md={8} sm={8}>
                        <Link
                          to={{
                            pathname: `/bookingdetails/${item.id}`,
                            bookinglist: item,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card.Body className="card_body mb-style">
                            <Row>
                              <Col>
                                <h4 className="ub-heading ub-font">
                                  {item.bmtEvent.title.length > 15 && (
                                    <div>
                                      {item.bmtEvent.title.substring(0, 15)} ...
                                    </div>
                                  )}
                                  {item.bmtEvent.title.length < 15 && (
                                    <div>{item.bmtEvent.title}</div>
                                  )}

                                  {/* {item.bmtEvent.title} */}
                                </h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="ub-font">Booking Id: {item.id}</p>
                              </Col>
                            </Row>
                            <Row className="ub_stars_row">
                              <Col>
                                <h3 className="normaltext2  ub-boooking ub-font">
                                  ₹{item.totalAmount}
                                </h3>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col>
                                <p className="normaltext2  ub-boooking ub-font">
                                  Booked on{" "}
                                  {moment(item.createdOn).format("DD-MM-YYYY")}
                                </p>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col>
                                <p className="normaltext2  ub-boooking ub-font">
                                  Event Date{" "}
                                  {moment(
                                    item &&
                                      item.eventDate &&
                                      item.eventDate.startDate
                                  ).format("DD-MM-YYYY")}
                                </p>
                              </Col>
                            </Row>
                            <Row style={{ marginLeft: "0px" }}>
                              <p
                                className="tags"
                                className={
                                  item.bookingStatus == "successful"
                                    ? "sucess-tags"
                                    : item.bookingStatus == "pending"
                                    ? "pending-tags"
                                    : "failed-tags"
                                }
                                style={{ textAlign: "center" }}
                              >
                                {item.bookingStatus}
                              </p>
                            </Row>
                          </Card.Body>
                        </Link>
                      </Col>
                    </Row>

                    {/* {item.bookingStatus == "successful" && (
                      <>
                        <FaEllipsisH className="EllipsisH" />
                        <UncontrolledButtonDropdown className="bbtn-ggroup">
                          <DropdownToggle
                            color="link"
                            className="p-0"
                            style={{ color: "#000", textAlign: "left" }}
                          ></DropdownToggle>
                          <DropdownMenu
                            left
                            className="rm-pointers dropdown-menu-lg"
                          >
                            {moment(
                              item && item.eventDate && item.eventDate.endDate
                            ).diff(moment(), "days") < 0 && (
                              <Nav.Link
                                onClick={() => {
                                  this.setState({
                                    modalShow: true,
                                    eventid: item.bmtEventId,
                                    operadtorid: item.operatorId,
                                    userId: item.userId,
                                  });
                                }}
                              >
                                <h4>Write Review</h4>
                              </Nav.Link>
                            )}
                            <Nav.Link
                              onClick={() => {
                                this.setState({
                                  modalShow2: true,
                                  eventid: item.id,
                                });
                              }}
                            >
                              <h4>Cancel</h4>
                            </Nav.Link>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </>
                    )} */}

                    {/* </Link> */}
                  </Card>
                </Col>
              ))}

              <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              >
                <div className="loader loader-1" style={loadingTextCSS}>
                  <div className="loader-outter" />
                  <div className="loader-inner" />
                </div>
              </div>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => this.setState({ modalShow: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Review This Tour
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Review
                    eventid={this.state.eventid}
                    operadtorid={this.state.operadtorid}
                    userId={this.state.userId}
                  />
                </Modal.Body>
              </Modal>

              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow2}
                onHide={() => this.setState({ modalShow2: false })}
              >
                <Modal.Body>
                  <Row
                    className="mb-4"
                    style={{ margin: "auto", textAlign: "center" }}
                  >
                    <Col>
                      <h2>Are you sure you want to cancel the booking?</h2>
                    </Col>
                  </Row>
                  <Row style={{ margin: "auto", textAlign: "center" }}>
                    <Col>
                      <ButtonCommon
                        handleClick={() => this.cancelbooking()}
                        className=" btn_small margin-userbooking"
                        label="Yes"
                      />
                      {/* </Col>
                  <Col> */}
                      <ButtonCommon
                        className=" btn_small"
                        label="No"
                        handleClick={() => this.setState({ modalShow2: false })}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </Row>
          )}
        </div>
      </div>
    );
  }
}
export default UserBooking;
