import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col,Card} from "react-bootstrap";

import NavBar from "../Components/NavBar";
import SearchBox from "../Components/SearchBox";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DestinationCard from "../Components/DestinationCard";
import TopOperators from "../Components/TopOperators";
import AdventureCard from "../Components/AdventureCard";
import AdventureEvents from "../Components/AdventureEvents";
import FeaturedEvents from "../Components/FeaturedEvents";
import BeautyStars from "beauty-stars";
import Footer from "../Components/Footer";
import Api from "../Service/Api";
import ReviewCard from "../Components/Reviewcard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loaders from "../Components/FullScreenLoader";
import Background from "../../static/Images/destination.jpg"
class AllOperatorsPage extends Component {
   constructor(){
    super()
  this.state = {
    list: [],
   prevY:10,
      limit:10,
      skip:0,
    count:0,
    page:6,
    loader:false,

  }
}
 

componentDidMount() {
  
  this.getoperatorlist(8);
this.setState({loader:true})
  var options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  };
  
  this.observer = new IntersectionObserver(
    this.handleObserver.bind(this),
    options
  );
  this.observer.observe(this.loadingRef);
}
handleObserver=(entities, observer)=> {
  const y = entities[0].boundingClientRect.y;
   if (this.state.prevY > y) {
    this.getoperatorlist(this.state.page+6);
    this.setState({ page: this.state.page+6 });
  }
  this.setState({ prevY: y });
}


 

   getoperatorlist = (value) => {
      this.setState({ loading:true , loader:true});
    Api.get(
      `events/stats/alloperators?limit=${value}&skip=0`
    )
      .then((res) => {
         this.setState({list: res.data});
        this.setState({ loading: false , loader:false});
      })
      .catch((err) => {});
       this.setState({ loading: false, loader:false });
  };


  render(){
  let {loaders, value, loader} = this.state


  const {list} =this.state;
  const loadingTextCSS = { display: this.state.loading ? "block" : "none" };

  return (
    <div>
   
        <NavBar />
        
    
     
      <Container>
      
     
      <section className="top-destinations ">
  <Row className="footer-con">
    <Col>
        

        <h1>Operators</h1>
        
             <div className="topoperator" style={{ marginTop: "25px" }}>
             {loader && <Loaders />}
             {!loader && (
        <Row>
         
          {list &&
            list.map((item, index) => (
              <Col lg={3} md={6} key={index}>
                <Link
                  to={{ pathname: `/operator-details/${item.id}`, list: item }}
                  style={{ textDecoration: "none" }}
                >

                   
                      <Card
                    
                    className="featured-card"
                    style={{
                      height: "300px",
                      borderRadius: "10px",
                    backgroundImage: item && item.banner?`url(${item && item.banner})`:`url(${Background})`,
                     // background:'url(../../static/Images/register.jpg)',
                     //backgroundColor: 'url(../../static/Images/register.jpg)',
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Card.Body className="card_body">
                      <Card.Title
                        className="Title-all font-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        {item && item.name}
                      </Card.Title>

                     <div
                        className="avatar"
                        style={{
                          //border: "5px solid white",
                          width: "86px",
                          height: "86px",
                          background: "#fc9d2e",
                          borderRadius: "50%",
                          position: "absolute",
                          top: "-170px",
                          //left: "15x",
                          //backgroundSize: "100% 100%",
                         
                          //backgroundPosition: "center",
                        }}
                      >
                        <img 
                         style={{
                          
                          width: "86px",
                          height: "86px",
                          background: "#fc9d2e",
                          borderRadius: "50%",
                          objectFit:'cover',
                         
                         
                          //backgroundSize: "100% 100%",
                         
                          //backgroundPosition: "center",
                        }}
                            src={
                            item && item.mdProfileImage
                          }
                        />
                      </div>


                      <p>
                        <BeautyStars
                          value={item && item.rating}
                          size="16px"
                          inactiveColor="#e0e0d1"
                          activeColor="#f4cc18"
                          // value={item && item.rating}
                        />
                      </p>
                      
                      <span
                        style={{ fontSize: "12px", paddingBottom: "20px" }}
                        className="sub-title-all"
                      >
                        {item && item.totalRatings} Reviews
                      </span>
                    </Card.Body>

                    <div className="eventopacity"></div>
                  </Card>{" "}
                </Link>
              </Col>

            ))}
        </Row>
        )}
         <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
          style={loadingTextCSS}
        >
        <div className="loader loader-1" style={loadingTextCSS} >
        <div className="loader-outter" />
        <div className="loader-inner" />
      </div>
      </div>
      </div>

         

        </Col>
        </Row>




      </section>
     
    </Container>
      <Footer />
    </div>
  );
}
}

export default AllOperatorsPage;
