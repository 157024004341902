import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import DestinationImage from '../../static/Images/destination1.jpg';
import arrow from '../../static/Images/right-arrow.svg';
import Api from '../Service/Api';

import FD1 from '../../static/Images/FD1.jpg';


class DestinationCard extends Component {
  state={list:[]}
  componentDidMount(){

    Api.get(`/events?where={ "status": "active"}&relations=["eventImages"]`).then(res=>{
      if(res.status===200&& res.data.length>0){
      this.setState({list:res.data})
      
      }
    })
  }

  render()
  {
    let {list} =this.state
    return (
      <div>
        <div className='destination-card'>
          <img 
          src={FD1} 
          // src={banner}
          alt='' width='100%' />

          <div className='destination-card-content'>
            <div className='destination-card-text'>
              <h4>Tarifa - Spain</h4>
              <h1>
                Los Lances <br /> Beach
              </h1>
            </div>
            <div className='arrow-right'>
              <img src={arrow} width='30px' height='auto' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DestinationCard;
