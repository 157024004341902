import React, { Component } from 'react';
import { Button,Row,Col , Card} from 'react-bootstrap';
import Api from '../Service/Api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import moment from "moment";
import Guides from '../../static/Images/guides.png';
import Trust from '../../static/Images/Group 21.png';
import Fill from '../../static/Images/Fill 1.png';
import Map from '../../static/Images/mag.png';



class WhyUs extends Component {
  state={
    list:[],
    banner:'',
    endDate:'',
    startDate:'',
     limit: 15,
    skip: 0,
    count:'',
    count: [],
  
  }



  render() {
    const { image, place } = this.props;
    let {banner,endDate,startDate, skip, limit, count} =this.state
    let {list}=this.props;

    return (
      <div>
      <div className=''  className='' style={{marginTop:'20px'}} >
  
      <h2 style={{fontWeight:'bold', textAlign:'center'}}>Why Us?</h2>
      <p style={{textAlign:'center', marginBottom:'30px'}}>Here’s why we’re rated the India's ‘Best for Experiences’</p>

   
        <Row style={{paddingLeft:'10px'}}>
            <Col style={{paddingRight:'30px'}}>
            <Row>
            <img  className="why-us-img"
                src={Guides} 
                alt='' width='100%'
             />
            </Row>
            <Row className="whyusText">
                <h2 style={{textAlign:'center'}}>EXPERT LOCAL GUIDES</h2>
                <p>Go places you wouldn’t yourself with locals who know where to go and how to stay safe.</p>
            </Row>
            </Col>

            <Col style={{paddingRight:'30px'}}>
            <Row>
            <img className="why-us-img"
                src={Trust} 
                alt='' width='100%'
             />
            </Row>
            <Row className="whyusText">
                <h2 >YOUR MONEY IS SAFE</h2>
                <p>There has been and always will be full financial protection on your booking.</p>
            </Row>
            </Col>
            
            <Col style={{paddingRight:'30px'}}>
            <Row>
            <img className="why-us-img"
                src={Fill} 
                alt='' width='100%'
             />
            </Row>
            <Row className="whyusText">
                <h2 >TOTAL FLEXIBILITY</h2>
                <p>Free date changes and cancellations up to 31 days before departure on most trips.</p>
            </Row>
            </Col>
            <Col>
            <Row>
            <img className="why-us-img"
                src={Map} 
                alt='' width='100%'
             />
            </Row>
            <Row className="whyusText">
                <h2 >A COMPANY YOU CAN ACTUALLY TRUST</h2>
                <p>As reported by The Guardian and Which?, we’re doing right by our customers in light of COVID-19</p>
            </Row>
            </Col>

        </Row>
        

      </div>
      </div>
    );
}
}
export default WhyUs;