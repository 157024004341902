import React, { useState, Component } from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";


import Api from "../Service/Api";
import profile from '../../static/Images/profile.png';
import TextField from "@material-ui/core/TextField";

const Settings = () => {
    const [newpassword, setnewpassword] = useState("");
    const [password, setpassword] = useState("");
    const [modalOpen, setmodalOpen] = useState(false);
    return(
        <div>
            <NavBar />
            <Col
        lg="6"
        style={{ alignSelf: "center", margin: "auto", marginTop: 100 }}
      >
        <Card>
          <CardHeader>Change Password</CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
              <Label>Old Password</Label>
                <Input
                  type={"password"}
                //  value={password}
                  onChange={(event) => setpassword(event.target.value)}
                  name="password"
                  placeholder="Enter old password"
                />
              </FormGroup>
              <FormGroup>
                <Label>New Password</Label>
                <Input
                  type={"password"}
                 // value={newpassword}
                  onChange={(event) => setnewpassword(event.target.value)}
                  name="newpassword"
                  placeholder="Enter new password"
                />
              </FormGroup>
              <Row className="justify-content-md-center">
                {/* <label>{error}</label> */}
                <Button
                  //  disabled={disable}
                  className="text-center p-2 pl-4 pr-4"
               //   onClick={OnSubmit}
                >
                  Change
                </Button>
              </Row>
            </Form>
            <span className="d-inline-block mb-2 mr-2">
              <Modal
                open={modalOpen}
                style={{ marginTop: "5%" }}
                // onClose={this.handleClose}
              >
                <ModalBody></ModalBody>
                <ModalFooter>
                  <Button
                    color="green"
                    onClick={() => {
                      window.location.reload();
                      setmodalOpen(false);
                    }}
                    //inverted
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </span>
          </CardBody>
        </Card>
      </Col>
            <Footer />
        </div>
    );

}

export default Settings;