import React, { Component} from "react";
import NavBar from "../Components/NavBar";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Api from "../Service/Api";
import WhyUs from "../Components/WhyUs";
import SuggestTrip from "../Components/SuggestTrip";
import ViewAllAdventureEvents from "../Components/ViewAllAdventureEvents";
import Filters from "../Components/Filters";
import Footer from "../Components/Footer";
import { Form, DropdownButton, Dropdown, Col, Row } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ButtonCommon from "../Components/ButtonCommon";
import { Label, Input } from "reactstrap";
import Price from "../Components/Price";
import InputRange from "react-input-range";
import BannerCarousel from "../Components/BannerCarousel";
import {GrFormClose} from "react-icons/gr";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" htmlColor="black" />;
const checkedIcon = <CheckBoxIcon fontSize="small" htmlColor="black" />;

class ViewAllEvents extends Component {
  state = {
    list: [],
    banner: "",
    endDate: new Date(),
    startDate: new Date(),
    limit: 8,
    skip: 0,
    id: "",
    fav: false,
    diff:[],
    dTW:false,
    prevfav: true,
    value:{ min: 0, max: 100000 },
    operatorList:[],
  operator:[],
  destinationList:[],
  eventDestId:[],
  categorylist:"",
  category:"all",
  destination:"all",
  limit:10,
  skip:0,
  listnew:"",
min:1,
max:100000,
count:0,
location:"",
title:"",
age:"",
page:6,
ImageS:[]
  };
 

  // const defaultMaterialTheme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       main: "#002a33",
  //       light: "#feffff",
  //       dark: "#002a33",
  //     },
  //   },
  // });
 
componentDidMount() {
  this.onSearch(6);
  this.getoperatorlist();
  this.getdestinationlist();
  this.getCategory();
  this.getfs();
  this.getCount()
  var options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  };
  
  this.observer = new IntersectionObserver(
    this.handleObserver.bind(this),
    options
  );
  this.observer.observe(this.loadingRef);
}
handleObserver=(entities, observer)=> {
  const y = entities[0].boundingClientRect.y;
   if (this.state.prevY > y&&this.state.count>this.state.page) {
    this.onSearch(this.state.page+6);
    this.setState({ page: this.state.page+6 });
  }
  this.setState({ prevY: y });
}


 getfs = () => {
  Api.get(
    `/misc/sliderimages/all`
  )
    .then((res) => {
       this.setState({ImageS: res.data});
    })
    .catch((err) => {});
};


   geteventlist = (value) => {
     Api.get(
      `/events/all?where={ "status": "active"}&relations=["eventImages"]&limit=${value}&skip=0`
    )
      .then((res) => {
         this.setState({list: res.data});
        this.setState({ loading: false });
      })
      .catch((err) => {});
  };
  getCount = () => {
    Api.get(
      `/events/count?where={ "status": "active"}`
    )
      .then((res) => {
         this.setState({count: res.data.count});
        this.setState({ loading: false });
      })
      .catch((err) => {});
  };

  getCategory = () => {
    Api.get(`/admin/event-categories`).then((res) => {
      this.setState({categorylist:res.data});
    });
  };

    getoperatorlist = () => {
    Api.get(`/operators/operators-minimal`)
      .then((res) => {
         this.setState({ operatorList: res.data});
      })
      .catch((err) => {});
  };

   getdestinationlist = () => {
    Api.get(`/events/destinations`)
      .then((res) => {
         this.setState({ destinationList: res.data});
      })
      .catch((err) => {});
  };

   onSearch = (v) => {
     let {dTW, startDate,category,endDate,destination,location,title,age,value,operator,diff,limit,skip} = this.state
    this.setState({loading:true})
    let link = "";
    if (category != "all") link += `category=${category}&`;
    if (destination != "all") link += `destination=${destination}&`;
    if (location != "") link += `location=${location}&`;
      if (title != "") link += `title=${title}&`;
    
    
    if (link == "") {
      link = `/events/all?where={ "status": "active"}&title=${title}&relations=["eventImages"]&limit=${v}&skip=${skip}`;
    } else {
      link = `/events/all?${link}limit=${v}&skip=${skip}`;
    }

    Api.get(link)
      .then((res) => {
        this.setState({list: res.data,loading:false});
      })
      .catch((err) => {});
  };

   
 

  onSearch1 = (v) => {
     let {dTW, startDate,category,endDate,destination,location,title,age,value,operator,diff,limit,skip} = this.state
    this.setState({loading:true})
    let link = "";
  
    if (age != "") link += `age=${age}&`;
    if (value.min != 0) link += `minAmount=${value.min}&`;
    if (value.max != 100000) link += `maxAmount=${value.max}&`;
    if(dTW) link+=`departure=${new Date(endDate).toISOString()}&`;
    if (operator.length != 0) {
      let operators = [];
      operator.map((item) => operators.push(`"${item.id}"`));
      link += `operators=[${operators}]&`;
    }
     if (diff.length != 0) {
      let difficulty = [];
      diff.map((item) => difficulty.push(`"${item.title}"`));
      link += `difficulty=[${difficulty}]&`;
    }
    if (link == "") {
      link = `/events/all?where={ "status": "active"}&title=${title}&relations=["eventImages"]&limit=${v}&skip=${skip}`;
    } else {
      link = `/events/all?${link}limit=${v}&skip=${skip}`;
    }

    Api.get(link)
      .then((res) => {
        this.setState({list: res.data,loading:false});
      })
      .catch((err) => {});
  };


  render(){
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    let {max,min, ImageS,categorylist,destinationList,operatorList,list,category,endDate,destination,location,title,age,value,operator,diff,limit,skip} = this.state
  return (
    <div>
      <section>
        <div>
          {/* <div className="hero-text">
            <h4>Relax & Enjoy</h4>
            <h2>
              It's time to <br /> Adventure
            </h2>
          </div> */}
          <NavBar />
        </div>
        {/*<div>
          <BannerCarousel list={ImageS} />
        </div>*/}
      </section>
      <section className="search-section container footer-con">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="search-container">
            <Form className="searchform" autocomplete="off">
              <Row className="justify-content-center">
                <Col lg={9} xl={10} className="searchcols">
                  <Row>
                   <Col lg={3}>
                      <Form.Group controlId="validationCustom03">
                        <Label>Adventure Name</Label>
                        <Form.Control
                          
                          type="text"
                          placeholder="Adventure Name"
                          value={title}
                          onChange={(e) => this.setState({title:e.target.value})}
                          // className="form-place"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group
                        controlId="formBasicEmail"
                        className="mb-lg-0"
                      >
                        <Label>Category</Label>
                        <Input
                        
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={category}
                          onChange={(event) => this.setState({category:event.target.value})}
                        >
                          <option value="all" style={{textTransform:'capitalize'}}>All</option>
                          {categorylist &&
                            categorylist.map((item, index) => (
                              <option value={item && item.title}>
                                {item && item.title}
                              </option>
                            ))}
                        </Input>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group controlId="formBasicEmail">
                        <Label>Destination</Label>
                        <Input style={{textTransform:'capitalize'}}
                          type="select"
                          value={destination}
                          onChange={(event) => {
                            this.setState({destination:event.target.value});
                          }}
                        >
                          <option value="all" style={{textTransform:'capitalize'}}>All</option>
                          {destinationList &&
                            destinationList.map((item) => (
                              <option value={item && item.destination}>
                                {item && item.destination}
                              </option>
                            ))}
                        </Input>
                      </Form.Group>
                    </Col>

                    <Col lg={3}>
                      <Form.Group controlId="validationCustom05">
                        <Label>Location</Label>
                        <Form.Control
                          // style={{
                          //   borderTop: "0px",
                          //   borderLeft: "0px",
                          //   borderRight: "0px",
                          // }}
                          type="text"
                          placeholder="Location"
                          value={location}
                          onChange={(e) => this.setState({location:e.target.value})}
                          // className="form-place"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <ButtonCommon
                  className="btncommon btnrounded srch-btn"
                  label="Search"
                  handleClick={()=>this.onSearch(this.state.page)}
                />
              </Row>
            </Form>
          </div>
        </MuiPickersUtilsProvider>
      </section>

      <section className="adventure-events container">
        <Row >
          <Col lg={2}>
            <Row>
              <Col>
                <Form.Group>
                  <TextField
                    id="date"
                    label="Select Date"
                    type="date"
                    defaultValue="DD-MM-YYYY"
                     style={{paddingTop:10}}
                    value={endDate}
                    onChange={(e) => {
                      this.setState({endDate: e.target.value,dTW:true});
                      //handleStartDateChange(true);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                {this.state.dTW&&<span className="date_delete" onClick={()=>{
                  this.setState({endDate:new Date(),dTW:false})
                }}><GrFormClose style={{cursor:'pointer'}}/></span>}
                </Form.Group>
                
              </Col>
            </Row>

            <Row>
              <Col>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  options={top100Films}
                  onChange={(e, t) => this.setState({age: t && t.value})}
                  disableCloseOnSelect
                  // value={age}
                  getOptionLabel={(option) => option.title}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  className="filters-all"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Age Range"
                      placeholder="Age Range"
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo2"
                  options={difficulty}
                  disableCloseOnSelect
                  onChange={(e, t) => {
                    this.setState({diff: t});
                  }}
                  getOptionLabel={(option) => option.title}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  className="filters-all"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Difficulty"
                      placeholder="Difficulty Level"
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo3"
                  options={operatorList}
                  disableCloseOnSelect
                  onChange={(e, t) => {
                    this.setState({operator:t});
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  className="filters-all "
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Operator"
                      placeholder="Operator"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={7} className="price-input">
                <div style={{ marginTop: "20px", marginBottom: "30px" }}>
                  <InputRange
                    minValue={min}
                    maxValue={max}
                    //   step={step}
                    onChange={(value) => this.setState({value:value})}
                    value={value}
                  />
                </div>
              </Col>
            </Row>
            <Row>
            <Col>
             <ButtonCommon
                  className="btncommon_all btnrounded srch-btn"
                  label="Apply"
                  handleClick={()=>this.onSearch1(this.state.page)}
                />
              </Col>
                </Row>
          </Col>

          <Col lg={9}>
            <div className="row">
              {list.map(item=> <ViewAllAdventureEvents
              item={item}
               history={this.props.history}// list={list}
              />)}
 {list.length == 0 && <label style={{margin:'auto'}}>No Result Found!</label>}
              </div>
              <div className="row">

                        <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
        
        ><div className="loader loader-1"  style={loadingTextCSS}>
        <div className="loader-outter" />
        <div className="loader-inner" />
      </div>
          </div>
            </div>
          </Col>
        </Row>

        {/* <SuggestTrip /> */}

        {/* <WhyUs /> */}
      </section>

      <Footer />
    </div>
  )
}
}

export default ViewAllEvents;

const top100Films = [
  { title: "10+", value: "10" },
  { title: "18+", value: "18" },
  { title: "30+", value: "30" },
  { title: "40+", value: "40" },
  { title: "50+", value: "50" },
  { title: "60+", value: "60" },
  { title: "70+", value: "70" },
];

const difficulty = [
  { title: "Easy" },
  { title: "Moderate" },
  { title: "Hard" },
  { title: "Expert" },
];
