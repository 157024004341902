import React, { Component, useState,useEffect } from 'react';
import NavBar from '../Components/NavBar';
import SearchBox from '../Components/SearchBox';
import SearchEvent from '../Components/SearchEvent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DestinationCard from '../Components/DestinationCard';
import Destinations from '../Components/Destinations';
import AdventureCard from '../Components/AdventureCard';
import  CustomerReviews from '../Components/CustomerReviews';
import Filters from '../Components/Filters';
import Footer from '../Components/Footer';
import Api from '../Service/Api';
import BeautyStars from "beauty-stars";
import { Button,Row,Col,Card,Image } from 'react-bootstrap';
import { FaAdn } from "react-icons/fa";
import moment from "moment";


//const  CustomerReviewss = (props) => {
  class CustomerReviewss extends Component{
    constructor(props) {
      super(props);
     this.state={
      list:[],
     
    
    };
  }
  // useEffect(() => {
  //     geteventlist()
  //   }, [ ]);
  // const geteventlist = () => {
  //     console.log("res")
  //     let accesstoken = localStorage.getItem("token");
  //     const token = `Bearer ${accesstoken}`;
  //     Api.get(`/events?where={ "status": "active"}&relations=["eventImages"]&limit=10&skip=0`, {
  //       headers: {
  //         "content-type": "application/json",
  //         Authorization: token,
  //       },
  //     })
  //       .then((res) => {
  //           console.log(res)
  //         setList(
  //         res.data
  //         );
          
  //       })
  //       .catch((err) => {
           
  //       });
  //   };
   
  render(){
    const {ratinglist} = this.props&&this.props.history&&this.props.history.location;

    return (
    
        
      <div>
          <NavBar />
     
  
         <section style={{ marginBottom:'150px'}}>
       

      <div className='container' style={{paddingTop:'150px'}}>
       
        
        <Card className="text-center Card-style" style={{marginBottom:"10px"}}>
        <h1 style={{textAlign:'center', border:'none'}}>Customer Reviews</h1>
                 {!ratinglist||ratinglist&&ratinglist.length==0&&(
<label className="event-font-size">No Reviews!</label>
                 )
        } 
                {ratinglist&&ratinglist.map((item,index)=>(
                 
        
                <Card.Body>
                    <Card.Text>
                    <Row>
                     <Col lg={3} md={3} >
            <img src={item &&item.user&&item.user.profileImage && item.user.profileImage.original}  style={{width:'60px', height:'60px',margin:'auto', borderRadius:'50px'}} />

                     {/*<FaAdn style={{fontSize:'50px',margin:'auto'}}/>*/}
                    
                     {/* <span>Anonymous</span> */}

                        {/* <Image src={AdventureImage} roundedCircle style={{width:'30px', height:'30px'}} /> */}
                    </Col>
                    <Col lg={9}  md={9} style={{textAlign:'left'}}>
                    <h3 >{item&&item.name}
                    <div className="date_review_style"><span>written on {moment(item&&item.createdOn).format("ll")}</span> </div>
                    </h3>
                                        <p style={{ marginBottom: "15px" }}>
                                        {item&&item.description}
                                        </p>

                                        <BeautyStars
                                          // value={item.rating}
                                          size="16px"
                                          inactiveColor="grey"
                                          activeColor="#fdd72a"
                                          value={item && item.rating}
                                        />
                                        <hr/>
                    {/* <Row>
                        <Col lg={8} style={{paddingLeft:'0px'}}>
                        <h3 className="event-font" style={{textAlign:'left'}}>{item&&item.name}</h3>
                        </Col>
                        <Col lg={4}>
                        <div className="event-font-size"><span>written on {moment(item&&item.createdOn).format("ll")}</span> </div>
                        </Col>
                    </Row>
                    <Row>
                    <BeautyStars
                           value={item&&item.rating}
                          size="16px"
                          inactiveColor="grey"
                          activeColor="#fdd72a"
                        />  
                       
                    </Row>
                    <Row>
                        <p className="event-font-size" style={{textAlign:'left'}}>{item&&item.description}
                        </p>
                    </Row>
                     */}
                    </Col>

                   </Row>
                 
                    </Card.Text>    
                </Card.Body>
               
               ))} 
                </Card>
        </div>
        </section>
     

        <Footer/>
        </div>
    );
  }
    
  }


export default CustomerReviewss;
