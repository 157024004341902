import React from 'react';
import ReactDOM from 'react-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

class Price extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 2, max: 10 },
    };
  }

  render() {
      let {min, max, value}=this.state;
    return (
        <div style={{marginTop:'20px'}} >
      <InputRange
          minValue={min}
          maxValue={max}
        //   step={step}
        //   onChange={this.onChange}
          value={value}
        />
        </div>
    );
  }
}

export default Price;